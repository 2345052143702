import {
  setToken,
  //  getToken, deleteToken
} from "../../utils/token";

import { defaultProperties } from "../../utils/defaultDataMap";

// Action Types
export const actionTypes = {
  GET_PROPERTIES: "GET_PROPERTIES",
  GET_PROPERTIES_SUCCESS: "GET_PROPERTIES_SUCCESS",
  GET_PROPERTIES_FAILED: "GET_PROPERTIES_FAILED",

  UPDATE_PROPERTY_INFO: "UPDATE_PROPERTY_INFO",
  UPDATE_PROPERTY_INFO_SUCCESS: "UPDATE_PROPERTY_INFO_SUCCESS",
  UPDATE_PROPERTY_INFO_FAILED: "UPDATE_PROPERTY_INFO_FAILED",

  GET_BRANDS: "GET_BRANDS",
  GET_BRANDS_SUCCESS: "GET_BRANDS_SUCCESS",
  GET_BRANDS_FAILED: "GET_BRANDS_FAILED",

  GET_PROJECT_INFO_PROPERTIES: "GET_PROJECT_INFO_PROPERTIES",
  GET_PROJECT_INFO_PROPERTIES_SUCCESS: "GET_PROJECT_INFO_PROPERTIES_SUCCESS",
  GET_PROJECT_INFO_PROPERTIES_FAILED: "GET_PROJECT_INFO_PROPERTIES_FAILED",

  GET_MANAGE_CONTENT: "GET_MANAGE_CONTENT",
  GET_MANAGE_CONTENT_SUCCESS: "GET_MANAGE_CONTENT_SUCCESS",
  GET_MANAGE_CONTENT_FAILED: "GET_MANAGE_CONTENT_FAILED",

  UPDATE_MANAGE_CONTENT: "UPDATE_MANAGE_CONTENT",
  UPDATE_MANAGE_CONTENT_SUCCESS: "UPDATE_MANAGE_CONTENT_SUCCESS",
  UPDATE_MANAGE_CONTENT_FAILED: "UPDATE_MANAGE_CONTENT_FAILED",

  RESET_ACTION: "RESET_ACTION",
};

// Action creators
export const actionCreators = {
  updateManageContent: (params) => ({
    type: actionTypes.UPDATE_MANAGE_CONTENT,
    params,
  }),
  getManageContent: (params) => ({
    type: actionTypes.GET_MANAGE_CONTENT,
    params,
  }),
  getProperties: (params) => ({
    type: actionTypes.GET_PROPERTIES,
    params,
  }),
  updateProjectInfo: (params) => ({
    type: actionTypes.UPDATE_PROPERTY_INFO,
    params,
  }),
  getBrands: (params) => ({
    type: actionTypes.GET_BRANDS,
    params,
  }),
  getProjectInforProperties: (params) => ({
    type: actionTypes.GET_PROJECT_INFO_PROPERTIES,
    params,
  }),
  resetAction: () => ({
    type: actionTypes.RESET_ACTION,
  }),
};

// Reducer
const initialState = {
  manageContent: [],
  updatePaymentMethod: [],
  data: [],
  buildings: [],
  units: [],
  meta: null,
  isLoading: false,
  error: null,
  brands: [],
  isUpdating: false,
  action: "",
  // activeProperties: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_MANAGE_CONTENT:
      return Object.assign({}, state, {
        isUpdating: true,
        updatePaymentMethod: [],
        error: null,
      });

    case actionTypes.UPDATE_MANAGE_CONTENT_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        error: null,
        updatePaymentMethod: action.payload,
      });

    case actionTypes.UPDATE_MANAGE_CONTENT_FAILED:
      return Object.assign({}, state, {
        isUpdating: false,
        updatePaymentMethod: [],
        error: action.error,
      });

    case actionTypes.GET_MANAGE_CONTENT:
      return Object.assign({}, state, {
        isUpdating: true,
        manageContent: [],
        error: null,
      });

    case actionTypes.GET_MANAGE_CONTENT_SUCCESS:
      return Object.assign({}, state, {
        isUpdating: false,
        error: null,
        manageContent: action.payload,
      });

    case actionTypes.GET_MANAGE_CONTENT_FAILED:
      return Object.assign({}, state, {
        isUpdating: false,
        manageContent: [],
        error: action.error,
      });

    case actionTypes.GET_PROJECT_INFO_PROPERTIES:
      return Object.assign({}, state, {
        isLoading: true,
        error: null,
      });

    case actionTypes.GET_PROJECT_INFO_PROPERTIES_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        data: action.payload,
      });

    case actionTypes.GET_PROJECT_INFO_PROPERTIES_FAILED:
      return Object.assign({}, state, {
        isLoading: true,
        error: null,
      });

    case actionTypes.GET_PROPERTIES:
      return Object.assign({}, state, {
        isLoading: true,
        error: null,
      });

    case actionTypes.GET_PROPERTIES_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        data: action.payload,
      });

    case actionTypes.UPDATE_PROPERTY_INFO:
      return Object.assign({}, state, {
        isLoading: true,
        error: null,
      });

    case actionTypes.UPDATE_PROPERTY_INFO_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        data: state.data.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }

          return item;
        }),
        action: "update",
      });

    case actionTypes.UPDATE_PROPERTY_INFO_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
      });

    case actionTypes.RESET_ACTION:
      return Object.assign({}, state, {
        action: "",
      });

    case actionTypes.GET_PROPERTIES_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
      });

    case actionTypes.GET_BRANDS:
      return Object.assign({}, state, {
        error: null,
      });

    case actionTypes.GET_BRANDS_SUCCESS:
      return Object.assign({}, state, {
        error: null,
        brands: action.payload,
      });

    case actionTypes.GET_BRANDS_FAILED:
      return Object.assign({}, state, {
        error: action.error,
      });

    default:
      return state;
  }
};
