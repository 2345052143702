import Loadable from "react-loadable";
import LoadingComponent from "../../commons/loader";

export default [
  {
    path: "/website",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/Page"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/header",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/header"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/email-recipients",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/email-recipients"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/value-proposition",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/home-page/value-proposition"),
      loading: LoadingComponent,
    }),
  },

  {
    path: "/website/:brandSlug/about-us-page",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/about-us-page"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/page-list",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/page-list"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/footer",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/footer"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/side-menu",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/side-menu"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/jellybean-design",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/jellybean-design"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/order-settings",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/home-page/order-settings"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/banner-section",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/home-page/banner"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/about-us-section",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/home-page/about-us"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/featured-properties-section",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/home-page/featured-properties"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/location",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/location"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/news-and-promo-section",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/home-page/news-and-promo"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/latest-news-section",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/home-page/latest-news"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/bulletin-section",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/home-page/bulletin"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/contact-section",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/home-page/contact"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/testimonials-section",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/home-page/testimonials"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/abc-of-living-section",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/home-page/abc-of-living"),
      loading: LoadingComponent,
    }),
  },

  {
    path: "/website/:brandSlug/about-rlc-residences",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/home-page/about-rlc-residences"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/properties-page",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/properties-page"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/property-page",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/property-page"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/property-details-page",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/property-details-page"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/property-details",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/property-details"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/news-and-articles-page",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/news-and-articles-page"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/calculator-page",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/calculator-page"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/contact-us-page",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/contact-us-page"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/faq-page",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/faq-page"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/email-content",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/email-content"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/inner-properties",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/inner-properties"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/careers-page",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/careers-page"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/compare-page",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/compare-page"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/appointment-page",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/appointment-page"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/testimonials-page",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/testimonials-page"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/search-result-page",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/search-result-page"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/contact-information",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/contact-information"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/faq-category-list",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/faq-category-list"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/faq-category-list/:id",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/faq-category-list/form"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/faq-list",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/faq-list"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/faq-list/:id",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/faq-list/form"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/popup-promo-list",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/popup-promo-list"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/popup-promo-list/:id",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/popup-promo-list/form"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/testimonials-list",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/testimonials-list"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/testimonials-list/:id",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/testimonials-list/form"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/news-list",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/news-list"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/newsletter-subscriptions",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/newsletter-subscriptions"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/news-list/:id",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/news-list/form"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/careers-list",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/careers-list"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/careers-list/:id",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/careers-list/form"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/application-list",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/application-list"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/property-inquiry",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/property-inquiry"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/appointment-inquiry",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/appointment-inquiry"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/application-list/:id",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/application-list/form"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/award-page",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/award-page"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/website/:brandSlug/award-content",
    exact: true,
    auth: true,
    component: Loadable({
      loader: () => import("./pages/award-content"),
      loading: LoadingComponent,
    }),
  },
];
