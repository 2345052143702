import { takeEvery, all } from "redux-saga/effects";
import { actionTypes as newsletterSubscriptionsActionTypes } from "./reducer";
import {
	getWebNewsletterSubscriptions as getWebNewsletterSubscriptionsService,
	deleteWebNewsletterSubscriptions as deleteWebNewsletterSubscriptionsService,
	postExportWebNewsletterSubscriptions as postExportWebNewsletterSubscriptionsService,
	getSoftDeletedWebNewsletterSubscriptions as getSoftDeletedWebNewsletterSubscriptionsService,
	postRestoreWebNewsletterSubscriptions as postRestoreWebNewsletterSubscriptionsService
} from "./service";
import processAction from "../../../../utils/processAction";

export function* getWebNewsletterSubscriptions(action) {
	yield processAction({
		...action,
		service: getWebNewsletterSubscriptionsService,
		success: newsletterSubscriptionsActionTypes.GET_WEB_NEWSLETTER_SUBSCRIPTIONS_SUCCESS,
		failed: newsletterSubscriptionsActionTypes.GET_WEB_NEWSLETTER_SUBSCRIPTIONS_FAILED
	})
}

export function* deleteWebNewsletterSubscriptions(action) {
	yield processAction({
		...action,
		service: deleteWebNewsletterSubscriptionsService,
		success: newsletterSubscriptionsActionTypes.DELETE_WEB_NEWSLETTER_SUBSCRIPTIONS_SUCCESS,
		failed: newsletterSubscriptionsActionTypes.DELETE_WEB_NEWSLETTER_SUBSCRIPTIONS_FAILED
	})
}

export function* postExportWebNewsletterSubscriptions(action) {
	yield processAction({
		...action,
		service: postExportWebNewsletterSubscriptionsService,
		success: newsletterSubscriptionsActionTypes.POST_EXPORT_WEB_NEWSLETTER_SUBSCRIPTIONS_SUCCESS,
		failed: newsletterSubscriptionsActionTypes.POST_RESTORE_WEB_NEWSLETTER_SUBSCRIPTIONS_FAILED
	})
}

export function* getSoftDeletedWebNewsletterSubscriptions(action) {
	yield processAction({
		...action,
		service: getSoftDeletedWebNewsletterSubscriptionsService,
		success: newsletterSubscriptionsActionTypes.GET_SOFT_DELETED_WEB_NEWSLETTER_SUBSCRIPTIONS_SUCCESS,
		failed: newsletterSubscriptionsActionTypes.GET_SOFT_DELETED_WEB_NEWSLETTER_SUBSCRIPTIONS_FAILED
	})
}

export function* postRestoreWebNewsletterSubscriptions(action) {
	yield processAction({
		...action,
		service: postRestoreWebNewsletterSubscriptionsService,
		success: newsletterSubscriptionsActionTypes.POST_RESTORE_WEB_NEWSLETTER_SUBSCRIPTIONS_SUCCESS,
		failed: newsletterSubscriptionsActionTypes.POST_RESTORE_WEB_NEWSLETTER_SUBSCRIPTIONS_FAILED
	})
}

function* watchContentData() {
	yield all([
		yield takeEvery(newsletterSubscriptionsActionTypes.GET_WEB_NEWSLETTER_SUBSCRIPTIONS_REQUEST, getWebNewsletterSubscriptions),
		yield takeEvery(newsletterSubscriptionsActionTypes.DELETE_WEB_NEWSLETTER_SUBSCRIPTIONS_REQUEST, deleteWebNewsletterSubscriptions),
		yield takeEvery(newsletterSubscriptionsActionTypes.POST_EXPORT_WEB_NEWSLETTER_SUBSCRIPTIONS_REQUEST, postExportWebNewsletterSubscriptions),
		yield takeEvery(newsletterSubscriptionsActionTypes.GET_SOFT_DELETED_WEB_NEWSLETTER_SUBSCRIPTIONS_REQUEST, getSoftDeletedWebNewsletterSubscriptions),
		yield takeEvery(newsletterSubscriptionsActionTypes.POST_RESTORE_WEB_NEWSLETTER_SUBSCRIPTIONS_REQUEST, postRestoreWebNewsletterSubscriptions)
	])
}

export default [watchContentData()]
