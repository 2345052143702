import request from "../../utils/api";

// export const getProperties = params => {
//   return request("v1/property", {
//     params
//   });
// };

export const getSellersAuditTrail = (payload) => {
  return request(`v1/cms/sellers-audit-trail`, {
    payload,
  });
};

export const updateProjectInfo = (params) => {
  const { projectId } = params;

  return request(`v1/property/${projectId}`, {
    method: "put",
    body: params,
  });
};

// export const getBrands = params => {
//   return request("v1/cms/brands", {
//     params
//   });
// };

export const getYtd = (params) => {
  return request("v1/cms/ytd", {
    params,
  });
};

export const getSubmittedReservation = (params) => {
  return request("v1/cms/my-dashboard/reservations/submitted", {
    params,
  });
};

export const getCisDetailsByReservationNo = (payload) => {
  return request(
    `v1/cms/my-dashboard/reservations/customer-information/${payload}`,
    {
      payload,
    }
  );
};

export const getAttachments = (payload) => {
  return request(`v1/cms/my-dashboard/reservations/attachment/${payload}`, {
    payload,
  });
};

export const getPurchaseJourney = (payload) => {
  return request(`v1/cms/my-dashboard/reservations/survey/${payload}`, {
    payload,
  });
};

export const getClientSignature = (payload) => {
  return request(`v1/sellers/e-reservations/signature/${payload}`, {
    payload,
  });
};

export const updateReservationStatus = (params) => {
  const { rNo, partnerId } = params;
  const { body } = params;

  return request(
    `v1/cms/my-dashboard/reservations/status/${rNo}/${partnerId}`,
    {
      method: "post",
      body,
    }
  );
};

export const getTransferRequest = () => {
  return request(`v1/cms/my-dashboard/customer-360/transfer`);
};

export const updateTransferRequest = (params) => {
  return request(`v1/cms/my-dashboard/customer-360/transfer/update`, {
    method: "post",
    body: params,
  });
};

export const getIncentive = () => {
  return request(`v1/cms/incentive`);
};

export const updateIncentive = (params) => {
  return request(`v1/cms/incentive`, {
    method: "post",
    body: params,
  });
};

export const deleteIncentive = (params) => {
  return request(`v1/cms/incentive/${params.id}`, {
    method: "delete",
  });
};

export const getKnowledgeCenter = () => {
  return request(`v1/cms/knowledge-center`);
};
//update / delete folder
export const updateKnowledgeCenterFolder = (params) => {
  let state = params.state;

  let data = {
    id: params.id,
    name: params.name,
    type: params.type,
  };

  return request(`v1/sellers/knowledge-center-folder/${data.id}`, {
    method: state === "edit" ? "put" : "delete",
    body: data,
  });
};
//quota
export const getContractQuota = (params) => {
  // return request(`v1/cms/contract-quota`, { params });
  const { page, pageSize, searchText, position } = params;
  return request(
    `v1/cms/contract-quota${page ? `?page=${page}` : ""}${
      searchText ? `&search=${searchText}` : ""
    }${position ? `&position=${position}` : ""}`
  );
};
export const getFilterContractQuota = (params) => {
  return request(`v1/cms/filter-contract-quota`, {
    method: "post",
    body: params,
  });
};
export const downloadCsvContractQuota = (params) => {
  const { searchText, position } = params;
  return request(
    `v1/cms/extract-contract-quota${searchText ? `?search=${searchText}` : ""}${
      searchText
        ? position
          ? `&position=${position}`
          : ""
        : position
        ? `?position=${position}`
        : ""
    }`
  );
};
//folder
export const getKnowledgeCenterFolder = () => {
  return request(`v1/sellers/knowledge-center-folder`);
};
//
export const updateKnowledgeCenter = (params) => {
  return request(`v1/cms/knowledge-center`, {
    method: "post",
    body: params,
  });
};

export const deleteKnowledgeCenter = (params) => {
  return request(`v1/cms/knowledge-center/${params.id}`, {
    method: "delete",
  });
};

export const getRetention = () => {
  return request(`v1/cms/lead-retention`);
};

export const updateRetention = (params) => {
  return request(`v1/cms/lead-retention/${params.id}`, {
    method: "post",
    body: params,
  });
};

export const getMarketingMultilevel = () => {
  return request(`v1/cms/marketing-partner-approval-level`);
};

export const updateMarketingMultilevel = (params) => {
  return request(`v1/cms/marketing-partner-approval-level/${params.id}`, {
    method: "post",
    body: params,
  });
};

export const getMarketingPartner = () => {
  return request(`v1/cms/marketing-partner`);
};

export const updateMarketingPartner = (params) => {
  return request(`v1/cms/marketing-partner/${params.id}`, {
    method: "post",
    body: params,
  });
};
export const updateMarketingPartnerToSap = (params) => {
  return request(`v1/cms/marketing-partner/approval/set`, {
    method: "post",
    body: params,
  });
};

export const getSalesGroup = () => {
  return request("v1/mv/sales-group");
};

export const getOwnershipExtension = () => {
  return request("v1/cms/ownership-extension");
};

export const getSubmissionExtension = () => {
  return request("v1/cms/submission-extension");
};

export const getLeadRenewal = () => {
  return request("v1/cms/lead-renewal");
};

export const postOwnershipExtension = (params) => {
  return request(`v1/cms/ownership-extension`, {
    method: "put",
    body: params.body,
  });
};
export const updateReservation = (params) => {
  return request(`v1/cms/update-reservation`, {
    method: "put",
    body: params,
  });
};
export const updateCSB = (params) => {
  return request(`v1/cms/post-to-SAP`, {
    method: "post",
    body: params,
  });
};
//get draft user values
export const getDraftUser = (params) => {
  return request("v1/sellers/customer-360/e-booking/getDraftUser", {
    body: params,
  });
};
export const postSubmissionExtension = (params) => {
  return request(`v1/cms/submission-extension`, {
    method: "put",
    body: params.body,
  });
};

export const postLeadRenewal = (params) => {
  return request(`v1/cms/lead-renewal`, {
    method: "put",
    body: params.body,
  });
};
export const getReservation = (params) => {
  return request("v1/cms/get-reservation", {
    body: params,
  });
};
