import { all, call, put, takeLatest } from "redux-saga/effects";
import { actionTypes as cmsActionTypes } from "../../commons/ProjectCMSHOC/reducer";
import processAction from "../../utils/processAction";
import { actionTypes as propertiesActionTypes } from "./reducer";
import {
  deleteIncentive as deleteIncentiveService,
  deleteKnowledgeCenter as deleteKnowledgeCenterService,
  getAttachments,
  getCisDetailsByReservationNo,
  getClientSignature,
  getContractQuota as getContractQuotaService,
  getFilterContractQuota as getFilterContractQuotaService,
  getDraftUser as getDraftUserService,
  getIncentive as getIncentiveService,
  getKnowledgeCenter as getKnowledgeCenterService,
  getKnowledgeCenterFolder as getKnowledgeCenterFolderService,
  getLeadRenewal as getLeadRenewalService,
  getMarketingMultilevel as getMarketingMultilevelService,
  getMarketingPartner as getMarketingPartnerService,
  getOwnershipExtension as getOwnershipExtensionService,
  getPurchaseJourney,
  getReservation as getReservationService,
  getRetention as getRetentionService,
  getSalesGroup as getSalesGroupService,
  getSellersAuditTrail as getSellersAuditTrailService,
  getSubmissionExtension as getSubmissionExtensionService,
  // getBrands,
  getSubmittedReservation,
  getTransferRequest as getTransferRequestService,
  getYtd as getYtdService,
  postLeadRenewal as postLeadRenewalService,
  postOwnershipExtension as postOwnershipExtensionService,
  postSubmissionExtension as postSubmissionExtensionService,
  updateCSB as updateCSBService,
  updateIncentive,
  updateKnowledgeCenter,
  updateKnowledgeCenterFolder as updateKnowledgeCenterFolderService,
  updateMarketingMultilevel,
  updateMarketingPartner,
  updateMarketingPartnerToSap,
  // getProperties,
  updateProjectInfo,
  updateReservation as updateReservationService,
  updateReservationStatus,
  updateRetention,
  updateTransferRequest,
  downloadCsvContractQuota as downloadCsvContractQuotaservice,
} from "./service";

// function* getAllProperties(action) {
//   yield processAction({
//     ...action,
//     service: getProperties,
//     success: propertiesActionTypes.GET_PROPERTIES_SUCCESS,
//     failed: propertiesActionTypes.GET_PROPERTIES_FAILED
//   });
// }

function* updateProperty(action) {
  const { params } = action;

  try {
    const payload = yield call(updateProjectInfo, params);
    yield put({
      type: propertiesActionTypes.UPDATE_PROPERTY_INFO_SELLERS_SUCCESS,
      payload,
    });

    yield put({
      type: cmsActionTypes.SELECTED_PROPERTY,
      property: payload,
    });
  } catch (error) {
    yield put({
      type: propertiesActionTypes.UPDATE_PROPERTY_INFO_SELLERS_FAILED,
      error,
    });
  }

  // yield processAction({
  //   ...action,
  //   service: updateProjectInfo,
  //   success: propertiesActionTypes.UPDATE_PROPERTY_INFO_SUCCESS,
  //   failed: propertiesActionTypes.UPDATE_PROPERTY_INFO_FAILED
  // });
}

// function* getAllBrands(action) {
//   yield processAction({
//     ...action,
//     service: getBrands,
//     success: propertiesActionTypes.GET_BRANDS_SUCCESS,
//     failed: propertiesActionTypes.GET_BRANDS_FAILED
//   });
// }
// quota
function* getContractQuota(action) {
  yield processAction({
    ...action,
    service: getContractQuotaService,
    success: propertiesActionTypes.GET_CONTRACT_QUOTA_SUCCESS,
    failed: propertiesActionTypes.GET_CONTRACT_QUOTA_FAILED,
  });
}
function* getFilterContractQuota(action) {
  yield processAction({
    ...action,
    service: getFilterContractQuotaService,
    success: propertiesActionTypes.FILTER_CONTRACT_QUOTA_SUCCESS,
    failed: propertiesActionTypes.FILTER_CONTRACT_QUOTA_FAILED,
  });
}
function* downloadCsvContractQuota(action) {
  yield processAction({
    ...action,
    service: downloadCsvContractQuotaservice,
    success: propertiesActionTypes.DOWNLOADING_CSV_SUCCESS,
    failed: propertiesActionTypes.DOWNLOADING_CSV_FAILED,
  });
}
// ytd
function* getYtd(action) {
  yield processAction({
    ...action,
    service: getYtdService,
    success: propertiesActionTypes.GET_YTD_SUCCESS,
    failed: propertiesActionTypes.GET_YTD_FAILED,
  });
}
//
function* getSellersAuditTrail(action) {
  yield processAction({
    ...action,
    service: getSellersAuditTrailService,
    success: propertiesActionTypes.GET_SELLERS_AUDIT_TRAIL_REQUEST_SUCCESS,
    failed: propertiesActionTypes.GET_SELLERS_AUDIT_TRAIL_REQUEST_FAILED,
  });
}
function* updateReservation(action) {
  yield processAction({
    ...action,
    service: updateReservationService,
    success: propertiesActionTypes.UPDATE_RESERVATION_SUCCESS,
    failed: propertiesActionTypes.UPDATE_RESERVATION_FAILED,
  });
}
function* updateCSB(action) {
  yield processAction({
    ...action,
    service: updateCSBService,
    success: propertiesActionTypes.UPDATE_CSB_TO_SAP_SUCCESS,
    failed: propertiesActionTypes.UPDATE_CSB_TO_SAP_FAILED,
  });
}

function* getDraftUser(action) {
  yield processAction({
    ...action,
    service: getDraftUserService,
    success: propertiesActionTypes.GET_DRAFT_USER_SUCCESS,
    failed: propertiesActionTypes.GET_DRAFT_USER_FAILED,
  });
}

function* getReservation(action) {
  yield processAction({
    ...action,
    service: getReservationService,
    success: propertiesActionTypes.GET_RESERVATION_SUCCESS,
    failed: propertiesActionTypes.GET_RESERVATION_FAILED,
  });
}

function* getReservedList(action) {
  yield processAction({
    ...action,
    service: getSubmittedReservation,
    success: propertiesActionTypes.GET_SUBMITTED_RESERVATION_SUCCESS,
    failed: propertiesActionTypes.GET_SUBMITTED_RESERVATION_FAILED,
  });
}

function* getCisDetails(action) {
  yield processAction({
    ...action,
    service: getCisDetailsByReservationNo,
    success: propertiesActionTypes.GET_CIS_INFORMATION_SUCCESS,
    failed: propertiesActionTypes.GET_CIS_INFORMATION_FAILED,
  });
}

function* getUserCisAttachment(action) {
  yield processAction({
    ...action,
    service: getAttachments,
    success: propertiesActionTypes.GET_CIS_ATTACHMENT_SUCCESS,
    failed: propertiesActionTypes.GET_CIS_ATTACHMENT_FAILED,
  });
}

function* getClientPurchase(action) {
  yield processAction({
    ...action,
    service: getPurchaseJourney,
    success: propertiesActionTypes.GET_CLIENT_SURVEY_SUCCESS,
    failed: propertiesActionTypes.GET_CLIENT_SURVEY_FAILED,
  });
}

function* getUserSignature(action) {
  yield processAction({
    ...action,
    service: getClientSignature,
    success: propertiesActionTypes.GET_CLIENT_SIGNATURE_SUCCESS,
    failed: propertiesActionTypes.GET_CLIENT_SIGNATURE_FAILED,
  });
}

function* postReservationStatus(action) {
  yield processAction({
    ...action,
    service: updateReservationStatus,
    success: propertiesActionTypes.POST_RESERVATION_STATUS_SUCCESS,
    failed: propertiesActionTypes.POST_RESERVATION_STATUS_FAILED,
  });
}

function* getTransferRequest(action) {
  yield processAction({
    ...action,
    service: getTransferRequestService,
    success: propertiesActionTypes.GET_TRANSFER_REQUEST_SUCCESS,
    failed: propertiesActionTypes.GET_TRANSFER_REQUEST_FAILED,
  });
}

function* postTransferRequest(action) {
  yield processAction({
    ...action,
    service: updateTransferRequest,
    success: propertiesActionTypes.POST_TRANSFER_REQUEST_SUCCESS,
    failed: propertiesActionTypes.POST_TRANSFER_REQUEST_FAILED,
  });
}

function* getIncentive(action) {
  yield processAction({
    ...action,
    service: getIncentiveService,
    success: propertiesActionTypes.GET_INCENTIVE_SUCCESS,
    failed: propertiesActionTypes.GET_INCENTIVE_FAILED,
  });
}

function* postIncentive(action) {
  yield processAction({
    ...action,
    service: updateIncentive,
    success: propertiesActionTypes.POST_INCENTIVE_SUCCESS,
    failed: propertiesActionTypes.POST_INCENTIVE_FAILED,
  });
}

function* deleteIncentive(action) {
  yield processAction({
    ...action,
    service: deleteIncentiveService,
    success: propertiesActionTypes.DELETE_INCENTIVE_SUCCESS,
    failed: propertiesActionTypes.DELETE_INCENTIVE_FAILED,
  });
}

function* getKnowledgeCenter(action) {
  yield processAction({
    ...action,
    service: getKnowledgeCenterService,
    success: propertiesActionTypes.GET_KNOWLEDGE_CENTER_SUCCESS,
    failed: propertiesActionTypes.GET_KNOWLEDGE_CENTER_FAILED,
  });
}
//folder
function* getKnowledgeCenterFolder(action) {
  yield processAction({
    ...action,
    service: getKnowledgeCenterFolderService,
    success: propertiesActionTypes.GET_KNOWLEDGE_CENTER_FOLDER_SUCCESS,
    failed: propertiesActionTypes.GET_KNOWLEDGE_CENTER_FOLDER_FAILED,
  });
}
//update
function* updateKnowledgeCenterFolder(action) {
  yield processAction({
    ...action,
    service: updateKnowledgeCenterFolderService,
    success: propertiesActionTypes.UPDATE_KNOWLEDGE_CENTER_FOLDER_SUCCESS,
    failed: propertiesActionTypes.UPDATE_KNOWLEDGE_CENTER_FOLDER_FAILED,
  });
}
function* postKnowledgeCenter(action) {
  yield processAction({
    ...action,
    service: updateKnowledgeCenter,
    success: propertiesActionTypes.POST_KNOWLEDGE_CENTER_SUCCESS,
    failed: propertiesActionTypes.POST_KNOWLEDGE_CENTER_FAILED,
  });
}

function* deleteKnowledgeCenter(action) {
  yield processAction({
    ...action,
    service: deleteKnowledgeCenterService,
    success: propertiesActionTypes.DELETE_KNOWLEDGE_CENTER_SUCCESS,
    failed: propertiesActionTypes.DELETE_KNOWLEDGE_CENTER_FAILED,
  });
}

function* getRetention(action) {
  yield processAction({
    ...action,
    service: getRetentionService,
    success: propertiesActionTypes.GET_RETENTION_SUCCESS,
    failed: propertiesActionTypes.GET_RETENTION_FAILED,
  });
}

function* postRetention(action) {
  yield processAction({
    ...action,
    service: updateRetention,
    success: propertiesActionTypes.POST_RETENTION_SUCCESS,
    failed: propertiesActionTypes.POST_RETENTION_FAILED,
  });
}

function* getMarketingMultilevel(action) {
  yield processAction({
    ...action,
    service: getMarketingMultilevelService,
    success: propertiesActionTypes.GET_MARKETING_PARTNER_MULTILEVEL_SUCCESS,
    failed: propertiesActionTypes.GET_MARKETING_PARTNER_MULTILEVEL_FAILED,
  });
}

function* postMarketingMultilevel(action) {
  yield processAction({
    ...action,
    service: updateMarketingMultilevel,
    success: propertiesActionTypes.POST_MARKETING_PARTNER_MULTILEVEL_SUCCESS,
    failed: propertiesActionTypes.POST_MARKETING_PARTNER_MULTILEVEL_FAILED,
  });
}

function* getMarketingPartner(action) {
  yield processAction({
    ...action,
    service: getMarketingPartnerService,
    success: propertiesActionTypes.GET_MARKETING_PARTNER_SUCCESS,
    failed: propertiesActionTypes.GET_MARKETING_PARTNER_FAILED,
  });
}

function* postMarketingPartner(action) {
  yield processAction({
    ...action,
    service: updateMarketingPartner,
    success: propertiesActionTypes.POST_MARKETING_PARTNER_SUCCESS,
    failed: propertiesActionTypes.POST_MARKETING_PARTNER_FAILED,
  });
}
function* postMarketingPartnerToSap(action) {
  yield processAction({
    ...action,
    service: updateMarketingPartnerToSap,
    success: propertiesActionTypes.POST_MARKETING_PARTNER_TO_SAP_SUCCESS,
    failed: propertiesActionTypes.POST_MARKETING_PARTNER_TO_SAP_FAILED,
  });
}

function* getSalesGroup(action) {
  yield processAction({
    ...action,
    service: getSalesGroupService,
    success: propertiesActionTypes.GET_SALES_GROUP_SUCCESS,
    failed: propertiesActionTypes.GET_SALES_GROUP_FAILED,
  });
}

function* getOwnershipExtension(action) {
  yield processAction({
    ...action,
    service: getOwnershipExtensionService,
    success: propertiesActionTypes.GET_OWNERSHIP_EXTENSION_SUCCESS,
    failed: propertiesActionTypes.GET_OWNERSHIP_EXTENSION_FAILED,
  });
}

function* postOwnershipExtension(action) {
  yield processAction({
    ...action,
    service: postOwnershipExtensionService,
    success: propertiesActionTypes.POST_OWNERSHIP_EXTENSION_SUCCESS,
    failed: propertiesActionTypes.POST_OWNERSHIP_EXTENSION_FAILED,
  });
}

function* getSubmissionExtension(action) {
  yield processAction({
    ...action,
    service: getSubmissionExtensionService,
    success: propertiesActionTypes.GET_SUBMISSION_EXTENSION_SUCCESS,
    failed: propertiesActionTypes.GET_SUBMISSION_EXTENSION_FAILED,
  });
}

function* postSubmissionExtension(action) {
  yield processAction({
    ...action,
    service: postSubmissionExtensionService,
    success: propertiesActionTypes.POST_SUBMISSION_EXTENSION_SUCCESS,
    failed: propertiesActionTypes.POST_SUBMISSION_EXTENSION_FAILED,
  });
}

function* getLeadRenewal(action) {
  yield processAction({
    ...action,
    service: getLeadRenewalService,
    success: propertiesActionTypes.GET_LEAD_RENEWAL_SUCCESS,
    failed: propertiesActionTypes.GET_LEAD_RENEWAL_FAILED,
  });
}

function* postLeadRenewal(action) {
  yield processAction({
    ...action,
    service: postLeadRenewalService,
    success: propertiesActionTypes.POST_LEAD_RENEWAL_SUCCESS,
    failed: propertiesActionTypes.POST_LEAD_RENEWAL_FAILED,
  });
}

function* watcher() {
  yield all([
    // quota
    takeLatest(propertiesActionTypes.GET_CONTRACT_QUOTA, getContractQuota),
    takeLatest(
      propertiesActionTypes.FILTER_CONTRACT_QUOTA,
      getFilterContractQuota
    ),
    takeLatest(propertiesActionTypes.DOWNLOADING_CSV, downloadCsvContractQuota),
    // ytd
    takeLatest(propertiesActionTypes.GET_YTD, getYtd),
    // takeLatest(propertiesActionTypes.GET_PROPERTIES, getAllProperties),
    takeLatest(
      propertiesActionTypes.UPDATE_PROPERTY_INFO_SELLERS,
      updateProperty
    ),
    // takeLatest(propertiesActionTypes.GET_BRANDS, getAllBrands),
    takeLatest(
      propertiesActionTypes.GET_SUBMITTED_RESERVATION,
      getReservedList
    ),
    takeLatest(
      propertiesActionTypes.GET_SELLERS_AUDIT_TRAIL_REQUEST,
      getSellersAuditTrail
    ),
    takeLatest(propertiesActionTypes.GET_RESERVATION, getReservation),
    takeLatest(propertiesActionTypes.UPDATE_RESERVATION, updateReservation),
    takeLatest(propertiesActionTypes.UPDATE_CSB_TO_SAP, updateCSB),
    takeLatest(propertiesActionTypes.GET_CIS_INFORMATION, getCisDetails),
    takeLatest(propertiesActionTypes.GET_CIS_ATTACHMENT, getUserCisAttachment),
    takeLatest(propertiesActionTypes.GET_CLIENT_SURVEY, getClientPurchase),
    takeLatest(propertiesActionTypes.GET_CLIENT_SIGNATURE, getUserSignature),
    takeLatest(
      propertiesActionTypes.POST_RESERVATION_STATUS,
      postReservationStatus
    ),
    takeLatest(propertiesActionTypes.GET_TRANSFER_REQUEST, getTransferRequest),
    takeLatest(
      propertiesActionTypes.POST_TRANSFER_REQUEST,
      postTransferRequest
    ),
    takeLatest(propertiesActionTypes.GET_INCENTIVE, getIncentive),
    takeLatest(propertiesActionTypes.POST_INCENTIVE, postIncentive),
    takeLatest(propertiesActionTypes.DELETE_INCENTIVE, deleteIncentive),
    takeLatest(propertiesActionTypes.GET_KNOWLEDGE_CENTER, getKnowledgeCenter),
    takeLatest(
      propertiesActionTypes.GET_KNOWLEDGE_CENTER_FOLDER,
      getKnowledgeCenterFolder
    ),
    takeLatest(
      propertiesActionTypes.UPDATE_KNOWLEDGE_CENTER_FOLDER,
      updateKnowledgeCenterFolder
    ),
    takeLatest(
      propertiesActionTypes.POST_KNOWLEDGE_CENTER,
      postKnowledgeCenter
    ),
    takeLatest(
      propertiesActionTypes.DELETE_KNOWLEDGE_CENTER,
      deleteKnowledgeCenter
    ),
    takeLatest(propertiesActionTypes.GET_RETENTION, getRetention),
    takeLatest(propertiesActionTypes.POST_RETENTION, postRetention),
    takeLatest(
      propertiesActionTypes.GET_MARKETING_PARTNER_MULTILEVEL,
      getMarketingMultilevel
    ),
    takeLatest(
      propertiesActionTypes.POST_MARKETING_PARTNER_MULTILEVEL,
      postMarketingMultilevel
    ),
    takeLatest(
      propertiesActionTypes.GET_MARKETING_PARTNER,
      getMarketingPartner
    ),
    takeLatest(
      propertiesActionTypes.POST_MARKETING_PARTNER,
      postMarketingPartner
    ),
    takeLatest(
      propertiesActionTypes.POST_MARKETING_PARTNER_TO_SAP,
      postMarketingPartnerToSap
    ),
    takeLatest(propertiesActionTypes.GET_SALES_GROUP, getSalesGroup),
    takeLatest(
      propertiesActionTypes.GET_OWNERSHIP_EXTENSION,
      getOwnershipExtension
    ),
    takeLatest(
      propertiesActionTypes.POST_OWNERSHIP_EXTENSION,
      postOwnershipExtension
    ),
    //get reservation list from sap
    takeLatest(propertiesActionTypes.GET_DRAFT_USER, getDraftUser),
    takeLatest(
      propertiesActionTypes.GET_SUBMISSION_EXTENSION,
      getSubmissionExtension
    ),
    takeLatest(
      propertiesActionTypes.POST_SUBMISSION_EXTENSION,
      postSubmissionExtension
    ),
    takeLatest(propertiesActionTypes.GET_LEAD_RENEWAL, getLeadRenewal),
    takeLatest(propertiesActionTypes.POST_LEAD_RENEWAL, postLeadRenewal),
  ]);
}

export default [watcher()];
