import { call, put, takeEvery } from "redux-saga/effects";
import { actionTypes as authActionTypes } from "./reducer";
import { actionTypes as profileActionTypes } from "../profile/reducer";
import { login as loginService } from "./service";
import { whoami } from "../profile/service";
import { message } from "antd";

function* login(action) {
  const { payload } = action;

  try {
    const auth = yield call(loginService, payload);
    yield put({ type: authActionTypes.LOGIN_SUCCESS, auth });

    const profile = yield call(whoami);

    if (profile.user_type_id !== 3) {
      message.error("Invalid Username/Password");
      yield put({
        type: authActionTypes.LOGIN_FAILED,
        errors: { message: "no permission" }
      });
    } else {
      yield put({
        type: profileActionTypes.GET_PROFILE_REQUEST_SUCCESS,
        profile
      });

      // Completed authentication
      yield put({ type: authActionTypes.AUTH_COMPLETED, auth });
    }
  } catch (errors) {
    message.error("Invalid Username/Password");
    yield put({ type: authActionTypes.LOGIN_FAILED, errors });
  }
}

function* watchLoginRequest() {
  yield takeEvery(authActionTypes.LOGIN_REQUEST, login);
}

export default [watchLoginRequest()];
