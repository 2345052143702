// Action Types
export const actionTypes = {
  GET_USER_ROLE_REQUEST: "GET_USER_ROLE_REQUEST",
  GET_USER_ROLE_SUCCESS: "GET_USER_ROLE_SUCCESS",
  GET_USER_ROLE_FAILED: "GET_USER_ROLE_FAILED",

  GET_ROLE_ACCESS_REQUEST: "GET_ROLE_ACCESS_REQUEST",
  GET_ROLE_ACCESS_SUCCESS: "GET_ROLE_ACCESS_SUCCESS",
  GET_ROLE_ACCESS_FAILED: "GET_ROLE_ACCESS_FAILED",

  POST_ROLE_ACCESS_REQUEST: "POST_ROLE_ACCESS_REQUEST",
  POST_ROLE_ACCESS_SUCCESS: "POST_ROLE_ACCESS_SUCCESS",
  POST_ROLE_ACCESS_FAILED: "POST_ROLE_ACCESS_FAILED",

  PUT_ROLE_ACCESS_REQUEST: "PUT_ROLE_ACCESS_REQUEST",
  PUT_ROLE_ACCESS_SUCCESS: "PUT_ROLE_ACCESS_SUCCESS",
  PUT_ROLE_ACCESS__FAILED: "PUT_ROLE_ACCESS_FAILED",

  GET_USER_REQUEST: "GET_USER_REQUEST",
  GET_USER_SUCCESS: "GET_USER_SUCCESS",
  GET_USER_FAILED: "GET_USER_FAILED",

  POST_USER_REQUEST: "POST_USER_REQUEST",
  POST_USER_SUCCESS: "POST_USER_SUCCESS",
  POST_USER_FAILED: "POST_USER_FAILED",

  PUT_USER_REQUEST: "PUT_USER_REQUEST",
  PUT_USER_SUCCESS: "PUT_USER_SUCCESS",
  PUT_USER_FAILED: "PUT_USER_FAILED",

  GET_USER_AUDIT_TRAIL_REQUEST: "GET_USER_AUDIT_TRAIL_REQUEST",
  GET_USER_AUDIT_TRAIL_SUCCESS: "GET_USER_AUDIT_TRAIL_SUCCESS",
  GET_USER_AUDIT_TRAIL_FAILED: "GET_USER_AUDIT_TRAIL_FAILED",

  POST_USER_AUDIT_TRAIL_REQUEST: "POST_USER_AUDIT_TRAIL_REQUEST",
  POST_USER_AUDIT_TRAIL_SUCCESS: "POST_USER_AUDIT_TRAIL_SUCCESS",
  POST_USER_AUDIT_TRAIL_FAILED: "POST_USER_AUDIT_TRAIL_FAILED",

  GET_UNSUBSCRIBE_REQUEST: "GET_UNSUBSCRIBE_REQUEST",
  GET_UNSUBSCRIBE_SUCCESS: "GET_UNSUBSCRIBE_SUCCESS",
  GET_UNSUBSCRIBE_FAILED: "GET_UNSUBSCRIBE_FAILED",

  POST_ACTIVATE_USER_REQUEST: "POST_ACTIVATE_USER_REQUEST",
  POST_ACTIVATE_USER_SUCCESS: "POST_ACTIVATE_USER_SUCCESS",
  POST_ACTIVATE_USER_FAILED: "POST_ACTIVATE_USER_FAILED",

  GET_EMAIL_SETTINGS_REQUEST: "GET_EMAIL_SETTINGS_REQUEST",
  GET_EMAIL_SETTINGS_SUCCESS: "GET_EMAIL_SETTINGS_SUCCESS",
  GET_EMAIL_SETTINGS_FAILED: "GET_EMAIL_SETTINGS_FAILED",

  POST_EMAIL_SETTINGS_REQUEST: "POST_EMAIL_SETTINGS_REQUEST",
  POST_EMAIL_SETTINGS_SUCCESS: "POST_EMAIL_SETTINGS_SUCCESS",
  POST_EMAIL_SETTINGS_FAILED: "POST_EMAIL_SETTINGS_FAILED",

  PUT_EMAIL_SETTINGS_REQUEST: "PUT_EMAIL_SETTINGS_REQUEST",
  PUT_EMAIL_SETTINGS_SUCCESS: "PUT_EMAIL_SETTINGS_SUCCESS",
  PUT_EMAIL_SETTINGS_FAILED: "PUT_EMAIL_SETTINGS_FAILED",

  EXECUTE_USER_SEEDING: "EXECUTE_USER_SEEDING",
  EXECUTE_USER_SEEDING_SUCCESS: "EXECUTE_USER_SEEDING_SUCCESS",
  EXECUTE_USER_SEEDING_FAILED: "EXECUTE_USER_SEEDING_FAILED",

  EXECUTE_PAYMENT_SEEDING: "EXECUTE_PAYMENT_SEEDING",
  EXECUTE_PAYMENT_SEEDING_SUCCUESS: "EXECUTE_PAYMENT_SEEDING_SUCCUESS",
  EXECUTE_PAYMENT_SEEDING_FAILED: "EXECUTE_PAYMENT_SEEDING_FAILED",

  EXECUTE_RECEIPT_SEEDING: "EXECUTE_RECEIPT_SEEDING",
  EXECUTE_RECEIPT_SEEDING_SUCCESS: "EXECUTE_RECEIPT_SEEDING_SUCCESS",
  EXECUTE_RECEIPT_SEEDING_FAILED: "EXECUTE_RECEIPT_SEEDING_FAILED",

  EXECUTE_PROPERTY: "EXECUTE_PROPERTY",
  EXECUTE_PROPERTY_SUCCESS: "EXECUTE_PROPERTY_SEEDING_SUCCESS",
  EXECUTE_PROPERTY_FAILED: "EXECUTE_PROPERTY_SEEDING_FAILED",

  EXECUTE_PRODUCTIVITY: "EXECUTE_PRODUCTIVITY",
  EXECUTE_PRODUCTIVITY_SUCCESS: "EXECUTE_PRODUCTIVITY_SEEDING_SUCCESS",
  EXECUTE_PRODUCTIVITY_FAILED: "EXECUTE_PRODUCTIVITY_SEEDING_FAILED",

  EXECUTE_SALES_GROUP: "EXECUTE_SALES_GROUP",
  EXECUTE_SALES_GROUP_SEEDING_SUCCESS: "EXECUTE_SALES_GROUP_SEEDING_SUCCESS",
  EXECUTE_SALES_GROUP_SEEDING_FAILED: "EXECUTE_SALES_GROUP_SEEDING_FAILED",

  EXECUTE_FILE_EXTRACTION: "EXECUTE_FILE_EXTRACTION",
  EXECUTE_FILE_EXTRACTION_SUCCESS: "EXECUTE_FILE_EXTRACTION_SUCCESS",
  EXECUTE_FILE_EXTRACTION_FAILED: "EXECUTE_FILE_EXTRACTION_FAILED"
};

// Action creators
export const actionCreators = {
  getUserRoleRequest: params => ({
    type: actionTypes.GET_USER_ROLE_REQUEST,
    params
  }),
  getRoleAccess: params => ({
    type: actionTypes.GET_ROLE_ACCESS_REQUEST,
    params
  }),
  postUserRole: params => ({
    type: actionTypes.POST_ROLE_ACCESS_REQUEST,
    params
  }),
  putUserRole: params => ({
    type: actionTypes.PUT_ROLE_ACCESS_REQUEST,
    params
  }),
  getUser: params => ({
    type: actionTypes.GET_USER_REQUEST,
    params
  }),
  postUser: params => ({
    type: actionTypes.POST_USER_REQUEST,
    params
  }),
  putUser: params => ({
    type: actionTypes.PUT_USER_REQUEST,
    params
  }),
  getUserAuditTrail: params => ({
    type: actionTypes.GET_USER_AUDIT_TRAIL_REQUEST,
    params
  }),
  postUserAuditTrail: params => ({
    type: actionTypes.POST_USER_AUDIT_TRAIL_REQUEST,
    params
  }),
  getDeactivatedUser: params => ({
    type: actionTypes.GET_UNSUBSCRIBE_REQUEST,
    params
  }),
  postActivateUser: params => ({
    type: actionTypes.POST_ACTIVATE_USER_REQUEST,
    params
  }),
  getEmailSettings: params => ({
    type: actionTypes.GET_EMAIL_SETTINGS_REQUEST,
    params
  }),
  postEmailSettings: params => ({
    type: actionTypes.POST_EMAIL_SETTINGS_REQUEST,
    params
  }),
  putEmailSettings: params => ({
    type: actionTypes.PUT_EMAIL_SETTINGS_REQUEST,
    params
  }),
  executeUserSeeding: params => ({
    type: actionTypes.EXECUTE_USER_SEEDING,
    params
  }),
  executePaymentSeeding: params => ({
    type: actionTypes.EXECUTE_PAYMENT_SEEDING,
    params
  }),
  executeReceiptSeeding: params => ({
    type: actionTypes.EXECUTE_RECEIPT_SEEDING,
    params
  }),
  executePropertySeeding: params => ({
    type: actionTypes.EXECUTE_PROPERTY,
    params
  }),
  executeProductivitySeeding: params => ({
    type: actionTypes.EXECUTE_PRODUCTIVITY,
    params
  }),
  executeSalesGroupSeeding: params => ({
    type: actionTypes.EXECUTE_SALES_GROUP,
    params
  }),
  executeFileExtraction: params => ({
    type: actionTypes.EXECUTE_FILE_EXTRACTION,
    params
  })
};

// Reducer
const initialState = {
  isLoading: false,
  errors: null,
  userRoleData: [],
  roleAccessData: null,
  userData: [],
  userLogs: [],
  deactivatedUsers: [],
  emailData: [],
  successUser: false,
  executeUser: false,
  executePayment: false,
  executeReceipt: false,
  executeProperty: false,
  executeProductivity: false,
  executeSalesGroup: false,
  executeFile: false,
  isUsersLoading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.EXECUTE_FILE_EXTRACTION:
      return Object.assign({}, state, {
        executeFile: true,
        errors: null
      });

    case actionTypes.EXECUTE_FILE_EXTRACTION_SUCCESS:
      return Object.assign({}, state, {
        executeFile: false,
        errors: null
      });

    case actionTypes.EXECUTE_FILE_EXTRACTION_FAILED:
      return Object.assign({}, state, {
        executeFile: false,
        errors: action.error
      });

    case actionTypes.EXECUTE_PROPERTY:
      return Object.assign({}, state, {
        executeProperty: true,
        errors: null
      });

    case actionTypes.EXECUTE_PROPERTY_SUCCESS:
      return Object.assign({}, state, {
        executeProperty: false,
        errors: null
      });

    case actionTypes.EXECUTE_PROPERTY_FAILED:
      return Object.assign({}, state, {
        executeProperty: false,
        errors: action.error
      });

    case actionTypes.EXECUTE_PRODUCTIVITY:
      return Object.assign({}, state, {
        executeProductivity: true,
        errors: null
      });

    case actionTypes.EXECUTE_PRODUCTIVITY_SUCCESS:
      return Object.assign({}, state, {
        executeProductivity: false,
        errors: null
      });

    case actionTypes.EXECUTE_PRODUCTIVITY_FAILED:
      return Object.assign({}, state, {
        executeProductivity: false,
        errors: action.error
      });

    case actionTypes.EXECUTE_SALES_GROUP:
      return Object.assign({}, state, {
        executeSalesGroup: true,
        errors: null
      });

    case actionTypes.EXECUTE_SALES_GROUP_SEEDING_SUCCESS:
      return Object.assign({}, state, {
        executeSalesGroup: false,
        errors: null
      });

    case actionTypes.EXECUTE_SALES_GROUP_SEEDING_FAILED:
      return Object.assign({}, state, {
        executeSalesGroup: false,
        errors: action.error
      });

    case actionTypes.GET_USER_ROLE_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
        userRoleData: null
      });

    case actionTypes.GET_USER_ROLE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        userRoleData: action.payload
      });

    case actionTypes.GET_USER_ROLE_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
        userRoleData: []
      });

    case actionTypes.GET_ROLE_ACCESS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
        roleAccessData: null
      });

    case actionTypes.GET_ROLE_ACCESS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        roleAccessData: action.payload
      });

    case actionTypes.GET_ROLE_ACCESS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
        roleAccessData: null
      });

    case actionTypes.POST_ROLE_ACCESS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null
      });

    case actionTypes.POST_ROLE_ACCESS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        userRoleData: action.payload
      });

    case actionTypes.POST_ROLE_ACCESS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error
      });

    case actionTypes.PUT_ROLE_ACCESS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null
      });

    case actionTypes.PUT_ROLE_ACCESS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        userRoleData: action.payload
      });

    case actionTypes.PUT_ROLE_ACCESS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error
      });

    case actionTypes.GET_USER_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        isUsersLoading: true,
        errors: null,
        userData: null
      });

    case actionTypes.GET_USER_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        isUsersLoading: false,
        errors: null,
        userData: action.payload
      });

    case actionTypes.GET_USER_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        isUsersLoading: false,
        errors: action.error,
        userData: []
      });

    case actionTypes.POST_USER_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
        successUser: false
      });

    case actionTypes.POST_USER_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        userData: action.payload,
        successUser: true
      });

    case actionTypes.POST_USER_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
        successUser: false
      });

    case actionTypes.PUT_USER_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null
      });

    case actionTypes.PUT_USER_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        userData: action.payload
      });

    case actionTypes.PUT_USER_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error
      });

    case actionTypes.GET_USER_AUDIT_TRAIL_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null
      });

    case actionTypes.GET_USER_AUDIT_TRAIL_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        userLogs: action.payload
      });

    case actionTypes.GET_USER_AUDIT_TRAIL_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
        userLogs: []
      });

    case actionTypes.POST_USER_AUDIT_TRAIL_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null
      });

    case actionTypes.POST_USER_AUDIT_TRAIL_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        userLogs: action.payload
      });

    case actionTypes.POST_USER_AUDIT_TRAIL_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
        userLogs: []
      });

    case actionTypes.GET_UNSUBSCRIBE_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null
      });

    case actionTypes.GET_UNSUBSCRIBE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        deactivatedUsers: action.payload
      });

    case actionTypes.GET_UNSUBSCRIBE_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
        deactivatedUsers: []
      });

    case actionTypes.POST_ACTIVATE_USER_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null
      });

    case actionTypes.POST_ACTIVATE_USER_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        deactivatedUsers: action.payload
      });

    case actionTypes.POST_ACTIVATE_USER_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error
      });

    case actionTypes.GET_EMAIL_SETTINGS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
        emailData: null
      });

    case actionTypes.GET_EMAIL_SETTINGS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        emailData: action.payload
      });

    case actionTypes.GET_EMAIL_SETTINGS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
        emailData: []
      });

    case actionTypes.POST_EMAIL_SETTINGS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null
      });

    case actionTypes.POST_EMAIL_SETTINGS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        emailData: action.payload
      });

    case actionTypes.POST_EMAIL_SETTINGS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error
      });

    case actionTypes.PUT_EMAIL_SETTINGS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null
      });

    case actionTypes.PUT_EMAIL_SETTINGS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        emailData: action.payload
      });

    case actionTypes.PUT_EMAIL_SETTINGS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error
      });

    case actionTypes.EXECUTE_USER_SEEDING:
      return Object.assign({}, state, {
        executeUser: true,
        errors: null
      });

    case actionTypes.EXECUTE_USER_SEEDING_SUCCESS:
      return Object.assign({}, state, {
        executeUser: false,
        errors: null
      });

    case actionTypes.EXECUTE_USER_SEEDING_FAILED:
      return Object.assign({}, state, {
        executeUser: false,
        errors: action.error
      });

    case actionTypes.EXECUTE_PAYMENT_SEEDING:
      return Object.assign({}, state, {
        executePayment: true,
        errors: null
      });

    case actionTypes.EXECUTE_PAYMENT_SEEDING_SUCCUESS:
      return Object.assign({}, state, {
        executePayment: false,
        errors: null
      });

    case actionTypes.EXECUTE_PAYMENT_SEEDING_FAILED:
      return Object.assign({}, state, {
        executePayment: false,
        errors: action.error
      });

    case actionTypes.EXECUTE_RECEIPT_SEEDING:
      return Object.assign({}, state, {
        executeReceipt: true,
        errors: null
      });

    case actionTypes.EXECUTE_RECEIPT_SEEDING_SUCCESS:
      return Object.assign({}, state, {
        executeReceipt: false,
        errors: null
      });

    case actionTypes.EXECUTE_RECEIPT_SEEDING_FAILED:
      return Object.assign({}, state, {
        executeReceipt: false,
        errors: action.error
      });

    default:
      return state;
  }
};
