export const slugify = (name) => {
  return name.replace(/ /g, "-").toLowerCase();
};

export const checkBrandCode = (brandCode, allow) => {
  return allow.indexOf(brandCode) > -1 ? true : false;
};

export const ucFirst = (myString) => {
  let explode = myString.split(" ");
  let joinString = "";
  explode.forEach((row) => {
    joinString += row.slice(0, 1).toUpperCase() + row.slice(1) + " ";
  });

  return joinString;
};

export const uuid = () => "ss-s-s-s-sss".replace(/s/g, s4);

export const get = (arr, o) =>
  arr.reduce((xs, x) => (xs && xs[x] ? xs[x] : null), o);

export const lodashGet = (object, path) => {
  if (typeof path === "string")
    path = path.split(".").filter((key) => key.length);
  return path.reduce((dive, key) => dive && dive[key], object);
};

function s4() {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
}

export const fullDomain = (slug, path = "/") => {
  let omnibusProdSlug = "robinsonsproperties";
  let urlSlug =
    slug === "robinsons-omnibus" &&
    process.env.REACT_APP_NODE_ENV === "production"
      ? omnibusProdSlug
      : slug === "robinsons-communities"
      ? "rlc-website-v2"
      : slug.replace("-", "");

  let stagingDomain =
    slug === "robinsons-communities"
      ? ".medix.ph.s3-website-ap-northeast-1.amazonaws.com"
      : ".medix.ph.s3-website-ap-southeast-1.amazonaws.com";
  let prodDomain = ".com";
  let fullUrl =
    process.env.REACT_APP_NODE_ENV === "staging"
      ? `http://${urlSlug}${stagingDomain}${path}`
      : `http://${urlSlug}${prodDomain}${path}`;

  return fullUrl;
};
