import { all, takeEvery } from "redux-saga/effects";
import processAction from "../../utils/processAction";
import { actionTypes } from "./reducer";
import { activateUser as activateUserService, executeFileExtraction as executeFileExtractionService, executePaymentSeeding as executePaymentSeedingService, executeProductivitySeeding as executeProductivitySeedingService, executePropertySeeding as executePropertySeedingService, executeReceiptSeeding as executeReceiptSeedingService, executeSalesGroupSeeding as executeSalesGroupService, executeUserSeeding as executeUserSeedingService, getAdminAuditTrail as getAdminAuditTrailService, getDeactivatedUser as getDeactivatedUserService, getEmailSettings as getEmailSettingsService, getRoleAccess as getRoleAccessService, getUser as getUserService, getUserRole as getUserRoleService, postAdminAuditTrail as postAdminAuditTrailService, postEmailSettings as postEmailSettingsService, postUser as postUserService, postUserRole as postUserRoleService, putEmailSettings as putEmailSettingsService, putUser as putUserService, putUserRole as putUserRoleService } from "./service";

function* getOverdue(action) {
  yield processAction({
    ...action,
    service: getUserRoleService,
    success: actionTypes.GET_USER_ROLE_SUCCESS,
    failed: actionTypes.GET_USER_ROLE_FAILED
  });
}

function* getRoleAccess(action) {
  yield processAction({
    ...action,
    service: getRoleAccessService,
    success: actionTypes.GET_ROLE_ACCESS_SUCCESS,
    failed: actionTypes.GET_ROLE_ACCESS_FAILED
  });
}

function* postRoleAccess(action) {
  yield processAction({
    ...action,
    service: postUserRoleService,
    success: actionTypes.POST_ROLE_ACCESS_SUCCESS,
    failed: actionTypes.POST_ROLE_ACCESS_FAILED
  });
}

function* putRoleAccess(action) {
  yield processAction({
    ...action,
    service: putUserRoleService,
    success: actionTypes.PUT_ROLE_ACCESS_SUCCESS,
    failed: actionTypes.PUT_ROLE_ACCESS_FAILED
  });
}

function* getUser(action) {
  yield processAction({
    ...action,
    service: getUserService,
    success: actionTypes.GET_USER_SUCCESS,
    failed: actionTypes.GET_USER_FAILED
  });
}

function* postUser(action) {
  yield processAction({
    ...action,
    service: postUserService,
    success: actionTypes.POST_USER_SUCCESS,
    failed: actionTypes.POST_USER_FAILED
  });
}

function* putUser(action) {
  yield processAction({
    ...action,
    service: putUserService,
    success: actionTypes.PUT_USER_SUCCESS,
    failed: actionTypes.PUT_USER_FAILED
  });
}

function* getAdminAuditTrail(action) {
  yield processAction({
    ...action,
    service: getAdminAuditTrailService,
    success: actionTypes.GET_USER_AUDIT_TRAIL_SUCCESS,
    failed: actionTypes.GET_USER_AUDIT_TRAIL_FAILED
  });
}

function* postAdminAuditTrail(action) {
  yield processAction({
    ...action,
    service: postAdminAuditTrailService,
    success: actionTypes.POST_USER_AUDIT_TRAIL_SUCCESS,
    failed: actionTypes.POST_USER_AUDIT_TRAIL_FAILED
  });
}

function* getDeactivatedUser(action) {
  yield processAction({
    ...action,
    service: getDeactivatedUserService,
    success: actionTypes.GET_UNSUBSCRIBE_SUCCESS,
    failed: actionTypes.GET_UNSUBSCRIBE_FAILED
  });
}

function* postActivateUser(action) {
  yield processAction({
    ...action,
    service: activateUserService,
    success: actionTypes.POST_ACTIVATE_USER_SUCCESS,
    failed: actionTypes.POST_ACTIVATE_USER_FAILED
  });
}

function* getEmailSettings(action) {
  yield processAction({
    ...action,
    service: getEmailSettingsService,
    success: actionTypes.GET_EMAIL_SETTINGS_SUCCESS,
    failed: actionTypes.GET_EMAIL_SETTINGS_FAILED
  });
}

function* postEmailSettings(action) {
  yield processAction({
    ...action,
    service: postEmailSettingsService,
    success: actionTypes.POST_EMAIL_SETTINGS_SUCCESS,
    failed: actionTypes.POST_EMAIL_SETTINGS_FAILED
  });
}

function* putEmailSettings(action) {
  yield processAction({
    ...action,
    service: putEmailSettingsService,
    success: actionTypes.PUT_EMAIL_SETTINGS_SUCCESS,
    failed: actionTypes.PUT_EMAIL_SETTINGS_FAILED
  });
}

function* executeUserSeeding(action) {
  yield processAction({
    ...action,
    service: executeUserSeedingService,
    success: actionTypes.EXECUTE_USER_SEEDING_SUCCESS,
    failed: actionTypes.EXECUTE_USER_SEEDING_FAILED
  });
}

function* executePaymentSeeding(action) {
  yield processAction({
    ...action,
    service: executePaymentSeedingService,
    success: actionTypes.EXECUTE_PAYMENT_SEEDING_SUCCUESS,
    failed: actionTypes.EXECUTE_PAYMENT_SEEDING_FAILED
  });
}

function* executeReceiptSeeding(action) {
  yield processAction({
    ...action,
    service: executeReceiptSeedingService,
    success: actionTypes.EXECUTE_RECEIPT_SEEDING_SUCCESS,
    failed: actionTypes.EXECUTE_RECEIPT_SEEDING_FAILED
  });
}

function* executePropertySeeding(action) {
  yield processAction({
    ...action,
    service: executePropertySeedingService,
    success: actionTypes.EXECUTE_PROPERTY_SUCCESS,
    failed: actionTypes.EXECUTE_PROPERTY_FAILED
  });
}

function* executeProductivitySeeding(action) {
  yield processAction({
    ...action,
    service: executeProductivitySeedingService,
    success: actionTypes.EXECUTE_PRODUCTIVITY_SUCCESS,
    failed: actionTypes.EXECUTE_PRODUCTIVITY_FAILED
  });
}

function* executeSalesGroupSeeding(action) {
  yield processAction({
    ...action,
    service: executeSalesGroupService,
    success: actionTypes.EXECUTE_SALES_GROUP_SEEDING_SUCCESS,
    failed: actionTypes.EXECUTE_SALES_GROUP_SEEDING_FAILED
  });
}

function* executeFile(action) {
  yield processAction({
    ...action,
    service: executeFileExtractionService,
    success: actionTypes.EXECUTE_FILE_EXTRACTION_SUCCESS,
    failed: actionTypes.EXECUTE_FILE_EXTRACTION_FAILED
  });
}

function* watchContentData() {
  yield all([
    yield takeEvery(actionTypes.GET_USER_ROLE_REQUEST, getOverdue),
    yield takeEvery(actionTypes.GET_ROLE_ACCESS_REQUEST, getRoleAccess),
    yield takeEvery(actionTypes.POST_ROLE_ACCESS_REQUEST, postRoleAccess),
    yield takeEvery(actionTypes.PUT_ROLE_ACCESS_REQUEST, putRoleAccess),
    yield takeEvery(actionTypes.GET_USER_REQUEST, getUser),
    yield takeEvery(actionTypes.POST_USER_REQUEST, postUser),
    yield takeEvery(actionTypes.PUT_USER_REQUEST, putUser),
    yield takeEvery(
      actionTypes.GET_USER_AUDIT_TRAIL_REQUEST,
      getAdminAuditTrail
    ),
    yield takeEvery(
      actionTypes.POST_USER_AUDIT_TRAIL_REQUEST,
      postAdminAuditTrail
    ),
    yield takeEvery(actionTypes.GET_UNSUBSCRIBE_REQUEST, getDeactivatedUser),
    yield takeEvery(actionTypes.POST_ACTIVATE_USER_REQUEST, postActivateUser),
    yield takeEvery(actionTypes.GET_EMAIL_SETTINGS_REQUEST, getEmailSettings),
    yield takeEvery(actionTypes.POST_EMAIL_SETTINGS_REQUEST, postEmailSettings),
    yield takeEvery(actionTypes.PUT_EMAIL_SETTINGS_REQUEST, putEmailSettings),
    yield takeEvery(actionTypes.EXECUTE_USER_SEEDING, executeUserSeeding),
    yield takeEvery(actionTypes.EXECUTE_PAYMENT_SEEDING, executePaymentSeeding),
    yield takeEvery(actionTypes.EXECUTE_RECEIPT_SEEDING, executeReceiptSeeding),
    yield takeEvery(actionTypes.EXECUTE_PROPERTY, executePropertySeeding),
    yield takeEvery(actionTypes.EXECUTE_PRODUCTIVITY, executeProductivitySeeding),
    yield takeEvery(actionTypes.EXECUTE_SALES_GROUP, executeSalesGroupSeeding),
    yield takeEvery(actionTypes.EXECUTE_FILE_EXTRACTION, executeFile)
  ]);
}

export default [watchContentData()];
