import { all } from "redux-saga/effects";
import authSagas from "../modules/auth/saga";
import profileSagas from "../modules/profile/saga";
// import dashboardSagas from "../modules/dashboard/saga";
import seller from "../modules/seller/saga";
import buyer from "../modules/buyer/saga";
import admin from "../modules/admin/saga";
import website from "../modules/website/saga";
import projectInfo from "../modules/project-info/saga";

export default function* rootSaga() {
  yield all([
    ...authSagas,
    ...profileSagas,
    // ...dashboardSagas,
    ...seller,
    ...buyer,
    ...admin,
    ...website,
    ...projectInfo
  ]);
}
