import localforage from 'localforage';
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import cmsHoc from "../commons/ProjectCMSHOC/reducer";
import theme from "../commons/ThemeManager/reducer";
import admin from "../modules/admin/reducer";
import auth from "../modules/auth/reducer";
import buyer from "../modules/buyer/reducer";
import dashboard from "../modules/dashboard/reducer";
import profile from "../modules/profile/reducer";
import projectInfo from "../modules/project-info/reducer";
import seller from "../modules/seller/reducer";
import website from "../modules/website/reducer";


const authPersistConfig = {
  key: "rlspauth",
  storage,
  blacklist: ["isLoading"]
};

const projectInfoConfig = {
  key: "projectInfo",
  storage: localforage,
  blacklist: ["isLoading"]
}

const sellerPersistConfig = {
  key: "seller",
  storage: localforage,
  blacklist: ["isLoading"]
}

const buyerPersistConfig = {
  key: "buyer",
  storage: localforage,
  blacklist: ["isLoading"]
}
const websitePersistConfig = {
  key: "website",
  storage: localforage,
  blacklist: ["isLoading"]
}

export default combineReducers({
  auth: persistReducer(authPersistConfig, auth),
  profile,
  seller: persistReducer(sellerPersistConfig, seller),
  dashboard,
  theme,
  cmsHoc,
  buyer: persistReducer(buyerPersistConfig, buyer),
  admin,
  website: persistReducer(websitePersistConfig, website),
  projectInfo: persistReducer(projectInfoConfig, projectInfo)
});
