// Action Types
export const actionTypes = {
  CONTENT_DATA_REQUEST: "CONTENT_DATA_REQUEST",
  CONTENT_DATA_REQUEST_SUCCESS: "CONTENT_DATA_REQUEST_SUCCESS",
  CONTENT_DATA_REQUEST_FAILED: "CONTENT_DATA_REQUEST_FAILED",

  POST_DATA_REQUEST: "POST_DATA_REQUEST",
  POST_DATA_REQUEST_SUCCESS: "POST_DATA_REQUEST_SUCCESS",
  POST_DATA_REQUEST_FAILED: "POST_DATA_REQUEST_FAILED",

  AGREEMENT_DATA_REQUEST: "AGREEMENT_DATA_REQUEST",
  AGREEMENT_DATA_REQUEST_SUCCESS: "AGREEMENT_DATA_REQUEST_SUCCESS",
  AGREEMENT_DATA_REQUEST_FAILED: "AGREEMENT_DATA_REQUEST_FAILED",

  POST_AGREEMENT_REQUEST: "POST_AGREEMENT_REQUEST",
  POST_AGREEMENT_REQUEST_SUCCESS: "POST_AGREEMENT_REQUEST_SUCCESS",
  POST_AGREEMENT_REQUEST_FAILED: "POST_AGREEMENT_REQUEST_FAILED",

  GET_AUDIT_TRAIL_REQUEST: "GET_AUDIT_TRAIL_REQUEST",
  GET_AUDIT_TRAIL_REQUEST_SUCCESS: "GET_AUDIT_TRAIL_REQUEST_SUCCESS",
  GET_AUDIT_TRAIL_REQUEST_FAILED: "GET_AUDIT_TRAIL_REQUEST_FAILED",

  POST_AUDIT_TRAIL_REQUEST: "POST_AUDIT_TRAIL_REQUEST",
  POST_AUDIT_TRAIL_REQUEST_SUCCESS: "POST_AUDIT_TRAIL_REQUEST_SUCCESS",
  POST_AUDIT_TRAIL_REQUEST_FAILED: "POST_AUDIT_TRAIL_REQUEST_FAILED",

  GET_REMITTANCE_REQUEST: "GET_REMITTANCE_REQUEST",
  GET_REMITTANCE_REQUEST_SUCCESS: "GET_REMITTANCE_REQUEST_SUCCESS",
  GET_REMITTANCE_REQUEST_FAILED: "GET_REMITTANCE_REQUEST_FAILED",

  GET_PAY_NOW_TRANSACTION_LIST: "GET_PAY_NOW_TRANSACTION_LIST",
  GET_PAY_NOW_TRANSACTION_LIST_SUCCESS: "GET_PAY_NOW_TRANSACTION_LIST_SUCCESS",
  GET_PAY_NOW_TRANSACTION_LIST_FAILED: "GET_PAY_NOW_TRANSACTION_LIST_FAILED",

  GET_FAQ_CATEGORY_REQUEST: "GET_FAQ_CATEGORY_REQUEST",
  GET_FAQ_CATEGORY_SUCCESS: "GET_FAQ_CATEGORY_SUCCESS",
  GET_FAQ_CATEGORY_FAILED: "GET_FAQ_CATEGORY_FAILED",

  GET_FAQ_REQUEST: "GET_FAQ_REQUEST",
  GET_FAQ_SUCCESS: "GET_FAQ_SUCCESS",
  GET_FAQ_FAILED: "GET_FAQ_FAILED",

  POST_FAQ_REQUEST: "POST_FAQ_REQUEST",
  POST_FAQ_SUCCESS: "POST_FAQ_SUCCESS",
  POST_FAQ_FAILED: "POST_FAQ_FAILED",

  PUT_FAQ_REQUEST: "PUT_FAQ_REQUEST",
  PUT_FAQ_SUCCESS: "PUT_FAQ_SUCCESS",
  PUT_FAQ_FAILED: "PUT_FAQ_FAILED",

  DELETE_FAQ_REQUEST: "DELETE_FAQ_REQUEST",
  DELETE_FAQ_SUCCESS: "DELETE_FAQ_SUCCESS",
  DELETE_FAQ_FAILED: "DELETE_FAQ_FAILED",

  GET_REQUEST_AND_INQUIRY_REQUEST: "GET_REQUEST_AND_INQUIRY_REQUEST",
  GET_REQUEST_AND_INQUIRY_SUCCESS: "GET_REQUEST_AND_INQUIRY_SUCCESS",
  GET_REQUEST_AND_INQUIRY_FAILED: "GET_REQUEST_AND_INQUIRY_FAILED",

  // GET_PROPERTIES: "GET_PROPERTIES",
  // GET_PROPERTIES_SUCCESS: "GET_PROPERTIES_SUCCESS",
  // GET_PROPERTIES_FAILED: "GET_PROPERTIES_FAILED",

  POST_PROJECT_UPDATE: "POST_PROJECT_UPDATE",
  POST_PROJECT_UPDATE_SUCCESS: "POST_PROJECT_UPDATE_SUCCESS",
  POST_PROJECT_UPDATE_FAILED: "POST_PROJECT_UPDATE_FAILED",

  GET_PROJECT_UPDATE: "GET_PROJECT_UPDATE",
  GET_PROJECT_UPDATE_SUCCESS: "GET_PROJECT_UPDATE_SUCCESS",
  GET_PROJECT_UPDATE_FAILED: "GET_PROJECT_UPDATE_FAILED",

  DELETE_PROJECT_UPDATE: "DELETE_PROJECT_UPDATE",
  DELETE_PROJECT_UPDATE_SUCCESS: "DELETE_PROJECT_UPDATE_SUCCESS",
  DELETE_PROJECT_UPDATE_FAILED: "DELETE_PROJECT_UPDATE_FAILED",

  GET_BUYER_EMAIL_CONTENT: "GET_BUYER_EMAIL_CONTENT",
  GET_BUYER_EMAIL_CONTENT_SUCCESS: "GET_BUYER_EMAIL_CONTENT_SUCCESS",
  GET_BUYER_EMAIL_CONTENT_FAILED: "GET_BUYER_EMAIL_CONTENT_FAILED",

  POST_BUYER_EMAIL_CONTENT: "POST_BUYER_EMAIL_CONTENT",
  POST_BUYER_EMAIL_CONTENT_SUCCESS: "POST_BUYER_EMAIL_CONTENT_SUCCESS",
  POST_BUYER_EMAIL_CONTENT_FAILED: "POST_BUYER_EMAIL_CONTENT_FAILED",

  GET_SEARCH_INQUIRY_LIST: "GET_SEARCH_INQUIRY_LIST",
  GET_SEARCH_INQUIRY_LIST_SUCCESS: "GET_SEARCH_INQUIRY_LIST_SUCCESS",
  GET_SEARCH_INQUIRY_LIST_FAILED: "GET_SEARCH_INQUIRY_LIST_FAILED",

  GET_SEARCH_INQUIRY_LIST_BY_BRAND: "GET_SEARCH_INQUIRY_LIST_BY_BRAND",
  GET_SEARCH_INQUIRY_LIST_BY_BRAND_SUCCESS:
    "GET_SEARCH_INQUIRY_LIST_BY_BRAND_SUCCESS",
  GET_SEARCH_INQUIRY_LIST_BY_BRAND_FAILED:
    "GET_SEARCH_INQUIRY_LIST_BY_BRAND_FAILED",

  GET_SEARCH_INQUIRY_LIST_BY_DATE: "GET_SEARCH_INQUIRY_LIST_BY_DATE",
  GET_SEARCH_INQUIRY_LIST_BY_DATE_SUCCESS:
    "GET_SEARCH_INQUIRY_LIST_BY_DATE_SUCCESS",
  GET_SEARCH_INQUIRY_LIST_BY_DATE_FAILED:
    "GET_SEARCH_INQUIRY_LIST_BY_DATE_FAILED",

  GET_SEARCH_REMITTANCE_SLIP: "GET_SEARCH_REMITTANCE_SLIP",
  GET_SEARCH_REMITTANCE_SLIP_SUCCESS: "GET_SEARCH_REMITTANCE_SLIP_SUCCESS",
  GET_SEARCH_REMITTANCE_SLIP_FAILED: "GET_SEARCH_REMITTANCE_SLIP_FAILED",

  GET_SEARCH_REMITTANCE_BY_BRAND: "GET_SEARCH_REMITTANCE_BY_BRAND",
  GET_SEARCH_REMITTANCE_BY_BRAND_SUCCESS:
    "GET_SEARCH_REMITTANCE_BY_BRAND_SUCCESS",
  GET_SEARCH_REMITTANCE_BY_BRAND_FAILED:
    "GET_SEARCH_REMITTANCE_BY_BRAND_FAILED",

  GET_SEARCH_DEPENDENT_REMITTANCE: "GET_SEARCH_DEPENDENT_REMITTANCE",
  GET_SEARCH_DEPENDENT_REMITTANCE_SUCCESS:
    "GET_SEARCH_DEPENDENT_REMITTANCE_SUCCESS",
  GET_SEARCH_DEPENDENT_REMITTANCE_FAILED:
    "GET_SEARCH_DEPENDENT_REMITTANCE_FAILED",

  GET_SEARCH_REMITTANCE_BY_DATE: "GET_SEARCH_REMITTANCE_BY_DATE",
  GET_SEARCH_REMITTANCE_BY_DATE_SUCCESS:
    "GET_SEARCH_REMITTANCE_BY_DATE_SUCCESS",
  GET_SEARCH_REMITTANCE_BY_DATE_FAILED: "GET_SEARCH_REMITTANCE_BY_DATE_FAILED",

  GET_SEARCH_PROJECT_NEWS_LETTER_BY_PROPERTY:
    "GET_SEARCH_PROJECT_NEWS_LETTER_BY_PROPERTY",
  GET_SEARCH_PROJECT_NEWS_LETTER_BY_PROPERTY_SUCCESS:
    "GET_SEARCH_PROJECT_NEWS_LETTER_BY_PROPERTY_SUCCESS",
  GET_SEARCH_PROJECT_NEWS_LETTER_BY_PROPERTY_FAILED:
    "GET_SEARCH_PROJECT_NEWS_LETTER_BY_PROPERTY_FAILED",

  GET_SEARCH_PROJECT_NEWS_LETTER_BY_BRAND:
    "GET_SEARCH_PROJECT_NEWS_LETTER_BY_BRAND",
  GET_SEARCH_PROJECT_NEWS_LETTER_BY_BRAND_SUCCESS:
    "GET_SEARCH_PROJECT_NEWS_LETTER_BY_BRAND_SUCCESS",
  GET_SEARCH_PROJECT_NEWS_LETTER_BY_BRAND_FAILED:
    "GET_SEARCH_PROJECT_NEWS_LETTER_BY_BRAND_FAILED",

  GET_SEARCH_DEPENDENT_PROJECT_NEWS_LETTER:
    "GET_SEARCH_DEPENDENT_PROJECT_NEWS_LETTER",
  GET_SEARCH_DEPENDENT_PROJECT_NEWS_LETTER_SUCCESS:
    "GET_SEARCH_DEPENDENT_PROJECT_NEWS_LETTER_SUCCESS",
  GET_SEARCH_DEPENDENT_PROJECT_NEWS_LETTER_FAILED:
    "GET_SEARCH_DEPENDENT_PROJECT_NEWS_LETTER_FAILED",

  GET_SEARCH_FAQ: "GET_SEARCH_FAQ",
  GET_SEARCH_FAQ_SUCCESS: "GET_SEARCH_FAQ_SUCCESS",
  GET_SEARCH_FAQ_FAILED: "GET_SEARCH_FAQ_FAILED",

  GET_SEARCH_DEPENDENT_INQUIRY_LIST: "GET_SEARCH_DEPENDENT_INQUIRY_LIST",
  GET_SEARCH_DEPENDENT_INQUIRY_LIST_SUCCESS:
    "GET_SEARCH_DEPENDENT_INQUIRY_LIST_SUCCESS",
  GET_SEARCH_DEPENDENT_INQUIRY_LIST_FAILED:
    "GET_SEARCH_DEPENDENT_INQUIRY_LIST_FAILED",

  GET_SEARCH_PAYNOW_CONTRACT_NO: "GET_SEARCH_PAYNOW_CONTRACT_NO",
  GET_SEARCH_PAYNOW_CONTRACT_NO_SUCCESS:
    "GET_SEARCH_PAYNOW_CONTRACT_NO_SUCCESS",
  GET_SEARCH_PAYNOW_CONTRACT_NO_FAILED: "GET_SEARCH_PAYNOW_CONTRACT_NO_FAILED",

  GET_SEARCH_PAYNOW_BY_BRAND: "GET_SEARCH_PAYNOW_BY_BRAND",
  GET_SEARCH_PAYNOW_BY_BRAND_SUCCESS: "GET_SEARCH_PAYNOW_BY_BRAND_SUCCESS",
  GET_SEARCH_PAYNOW_BY_BRAND_FAILED: "GET_SEARCH_PAYNOW_BY_BRAND_FAILED",

  GET_SEARCH_DEPENDENT_PAYNOW: "GET_SEARCH_DEPENDENT_PAYNOW",
  GET_SEARCH_DEPENDENT_PAYNOW_SUCCESS: "GET_SEARCH_DEPENDENT_PAYNOW_SUCCESS",
  GET_SEARCH_DEPENDENT_PAYNOW_FAILED: "GET_SEARCH_DEPENDENT_PAYNOW_FAILED",
};

// Action creators
export const actionCreators = {
  getContentData: (params) => ({
    type: actionTypes.CONTENT_DATA_REQUEST,
    params,
  }),
  getEmailContent: (params) => ({
    type: actionTypes.GET_BUYER_EMAIL_CONTENT,
    params,
  }),
  postEmailContent: (params) => ({
    type: actionTypes.POST_BUYER_EMAIL_CONTENT,
    params,
  }),
  postContent: (params) => ({ type: actionTypes.POST_DATA_REQUEST, params }),
  getAgreementData: (params) => ({
    type: actionTypes.AGREEMENT_DATA_REQUEST,
    params,
  }),
  postAgreement: (params) => ({
    type: actionTypes.POST_AGREEMENT_REQUEST,
    params,
  }),
  getAuditTrails: (params) => ({
    type: actionTypes.GET_AUDIT_TRAIL_REQUEST,
    params,
  }),
  postAuditTrails: (params) => ({
    type: actionTypes.POST_AUDIT_TRAIL_REQUEST,
    params,
  }),
  getRemittanceList: (params) => ({
    type: actionTypes.GET_REMITTANCE_REQUEST,
    params,
  }),
  getPayNowTransactionList: (params) => ({
    type: actionTypes.GET_PAY_NOW_TRANSACTION_LIST,
    params,
  }),
  getFaqCategory: (params) => ({
    type: actionTypes.GET_FAQ_CATEGORY_REQUEST,
    params,
  }),
  getFaq: (params) => ({
    type: actionTypes.GET_FAQ_REQUEST,
    params,
  }),
  postFaq: (params) => ({
    type: actionTypes.POST_FAQ_REQUEST,
    params,
  }),
  putFaq: (params) => ({
    type: actionTypes.PUT_FAQ_REQUEST,
    params,
  }),
  removeFaq: (params) => ({
    type: actionTypes.DELETE_FAQ_REQUEST,
    params,
  }),
  getInquiryAudit: (params) => ({
    type: actionTypes.GET_REQUEST_AND_INQUIRY_REQUEST,
    params,
  }),
  // getProperties: params => ({
  //   type: actionTypes.GET_PROPERTIES,
  //   params
  // }),
  createProjectNews: (params) => ({
    type: actionTypes.POST_PROJECT_UPDATE,
    params,
  }),
  getProjectNews: (params) => ({
    type: actionTypes.GET_PROJECT_UPDATE,
    params,
  }),
  deleteProjectNews: (params) => ({
    type: actionTypes.DELETE_PROJECT_UPDATE,
    params,
  }),
  searchInquiryList: (params) => ({
    type: actionTypes.GET_SEARCH_INQUIRY_LIST,
    params,
  }),
  searchDependentInquiryList: (params) => ({
    type: actionTypes.GET_SEARCH_DEPENDENT_INQUIRY_LIST,
    params,
  }),
  searchInquiryListByBrand: (params) => ({
    type: actionTypes.GET_SEARCH_INQUIRY_LIST_BY_BRAND,
    params,
  }),
  searchInquiryListByDate: (params) => ({
    type: actionTypes.GET_SEARCH_INQUIRY_LIST_BY_DATE,
    params,
  }),
  searchRemittnceSlip: (params) => ({
    type: actionTypes.GET_SEARCH_REMITTANCE_SLIP,
    params,
  }),
  searchRemittanceByDate: (params) => ({
    type: actionTypes.GET_SEARCH_REMITTANCE_BY_DATE,
    params,
  }),
  searchRemittanceByBrand: (params) => ({
    type: actionTypes.GET_SEARCH_REMITTANCE_BY_BRAND,
    params,
  }),
  searchDependentRemittance: (params) => ({
    type: actionTypes.GET_SEARCH_DEPENDENT_REMITTANCE,
    params,
  }),
  searchProjectNewsLetterByProperty: (params) => ({
    type: actionTypes.GET_SEARCH_PROJECT_NEWS_LETTER_BY_PROPERTY,
    params,
  }),
  searchProjectNewsLetterByBrand: (params) => ({
    type: actionTypes.GET_SEARCH_PROJECT_NEWS_LETTER_BY_BRAND,
    params,
  }),

  searchDependentProjectNewsLetter: (params) => ({
    type: actionTypes.GET_SEARCH_DEPENDENT_PROJECT_NEWS_LETTER,
    params,
  }),
  searchFaq: (params) => ({
    type: actionTypes.GET_SEARCH_FAQ,
    params,
  }),
  searchPaynowContractNo: (params) => ({
    type: actionTypes.GET_SEARCH_PAYNOW_CONTRACT_NO,
    params,
  }),
  searchPaynowByBrand: (params) => ({
    type: actionTypes.GET_SEARCH_PAYNOW_BY_BRAND,
    params,
  }),
  searchDependentPaynow: (params) => ({
    type: actionTypes.GET_SEARCH_DEPENDENT_PAYNOW,
    params,
  }),
};

// Reducer
const initialState = {
  isLoading: false,
  errors: null,
  data: null,
  dataAgreement: null,
  auditTrail: [],
  remittanceList: [],
  faqData: [],
  faqCategoryData: [],
  requestAndInquiryList: [],
  successDeleteFaq: false,
  properties: [],
  isCreateProjectNews: false,
  projectNews: [],
  emailContents: [],
  isEmailContentLoading: false,
  isSearchingInquiryList: false,
  searchingInquiryListData: [],
  isSearchingDependentInquiryList: false,
  isSearchingInquiryListByBrand: false,
  isSearchingInquiryListByDate: false,
  isSearchingRemittanceSlip: false,
  isSearchingRemittanceByDate: false,
  isSearchingRemittanceByBrand: false,
  isSearchingDependentRemittance: false,
  searchingRemittanceData: [],
  isSearchingProjectNewsLetterByProperty: false,
  isSearchingProjectNewsLetterByBrand: false,
  isSearchingDependentProjectNewsLetter: false,
  searchingProjectNewsLetterData: [],
  searchingFaqData: [],
  isSearchingFaq: false,
  searchingPaynowData: [],
  isSearchingPaynowContractNo: false,
  isSearchingPaynowByBrand: false,
  isSearchingDependentPaynow: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_BUYER_EMAIL_CONTENT:
      return Object.assign({}, state, {
        isLoading: true,
        error: null,
      });

    case actionTypes.GET_BUYER_EMAIL_CONTENT_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        emailContents: action.payload,
      });

    case actionTypes.GET_BUYER_EMAIL_CONTENT_FAILED:
      return Object.assign({}, state, {
        isLoading: true,
        error: action.error,
        emailContents: [],
      });

    case actionTypes.POST_BUYER_EMAIL_CONTENT:
      return Object.assign({}, state, {
        isEmailContentLoading: true,
        error: null,
      });

    case actionTypes.POST_BUYER_EMAIL_CONTENT_SUCCESS:
      return Object.assign({}, state, {
        isEmailContentLoading: false,
        error: null,
      });

    case actionTypes.POST_BUYER_EMAIL_CONTENT_FAILED:
      return Object.assign({}, state, {
        isEmailContentLoading: false,
        error: action.error,
      });

    case actionTypes.DELETE_PROJECT_UPDATE:
      return Object.assign({}, state, {
        isLoading: true,
        error: null,
      });

    case actionTypes.DELETE_PROJECT_UPDATE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
      });

    case actionTypes.DELETE_PROJECT_UPDATE_FAILED:
      return Object.assign({}, state, {
        isLoading: true,
        error: action.error,
      });

    case actionTypes.GET_PROJECT_UPDATE:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
        // projectNews: []
      });

    case actionTypes.GET_PROJECT_UPDATE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        projectNews: action.payload,
      });

    case actionTypes.GET_PROJECT_UPDATE_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
        // projectNews: true
      });

    case actionTypes.POST_PROJECT_UPDATE:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
        isCreateProjectNews: false,
      });

    case actionTypes.POST_PROJECT_UPDATE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        isCreateProjectNews: true,
      });

    case actionTypes.POST_PROJECT_UPDATE_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
        isCreateProjectNews: true,
      });

    // case actionTypes.GET_PROPERTIES:
    //   return Object.assign({}, state, {
    //     isLoading: true,
    //     errors: null,
    //     properties: []
    //   });

    // case actionTypes.GET_PROPERTIES_SUCCESS:
    //   return Object.assign({}, state, {
    //     isLoading: false,
    //     errors: null,
    //     properties: action.payload
    //   });

    // case actionTypes.GET_PROPERTIES_FAILED:
    //   return Object.assign({}, state, {
    //     isLoading: false,
    //     errors: action.error,
    //     properties: false
    //   });

    case actionTypes.DELETE_FAQ_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
        successDeleteFaq: false,
      });

    case actionTypes.DELETE_FAQ_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        successDeleteFaq: true,
      });

    case actionTypes.DELETE_FAQ_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
        successDeleteFaq: false,
      });

    case actionTypes.CONTENT_DATA_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
        data: null,
      });

    case actionTypes.CONTENT_DATA_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        data: action.payload,
      });

    case actionTypes.CONTENT_DATA_REQUEST_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
        data: null,
      });

    case actionTypes.POST_DATA_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.POST_DATA_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        data: action.payload,
      });

    case actionTypes.POST_DATA_REQUEST_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.AGREEMENT_DATA_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.AGREEMENT_DATA_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        dataAgreement: action.payload,
      });

    case actionTypes.AGREEMENT_DATA_REQUEST_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.POST_AGREEMENT_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.POST_AGREEMENT_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
      });

    case actionTypes.POST_AGREEMENT_REQUEST_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.GET_AUDIT_TRAIL_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.GET_AUDIT_TRAIL_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        auditTrail: action.payload,
      });

    case actionTypes.GET_AUDIT_TRAIL_REQUEST_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.POST_AUDIT_TRAIL_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
        auditTrail: [],
      });

    case actionTypes.POST_AUDIT_TRAIL_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        auditTrail: action.payload,
      });

    case actionTypes.POST_AUDIT_TRAIL_REQUEST_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.GET_REMITTANCE_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.GET_REMITTANCE_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        remittanceList: action.payload,
      });

    case actionTypes.GET_REMITTANCE_REQUEST_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.GET_PAY_NOW_TRANSACTION_LIST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.GET_PAY_NOW_TRANSACTION_LIST_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        paymentList: action.payload,
      });

    case actionTypes.GET_PAY_NOW_TRANSACTION_LIST_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.GET_FAQ_CATEGORY_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
        faqCategoryData: null,
      });

    case actionTypes.GET_FAQ_CATEGORY_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        faqCategoryData: action.payload,
      });

    case actionTypes.GET_FAQ_CATEGORY_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
        faqCategoryData: [],
      });

    case actionTypes.GET_FAQ_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.GET_FAQ_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        faqData: action.payload,
      });

    case actionTypes.GET_FAQ_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
        faqData: [],
      });

    case actionTypes.POST_FAQ_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.GET_REQUEST_AND_INQUIRY_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.POST_FAQ_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        faqData: action.payload,
      });

    case actionTypes.POST_FAQ_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.PUT_FAQ_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.PUT_FAQ_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        faqData: action.payload,
      });

    case actionTypes.PUT_FAQ_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.GET_REQUEST_AND_INQUIRY_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        requestAndInquiryList: action.payload,
      });

    case actionTypes.GET_REQUEST_AND_INQUIRY_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
        requestAndInquiryList: [],
      });

    case actionTypes.GET_SEARCH_INQUIRY_LIST:
      return Object.assign({}, state, {
        isSearchingInquiryList: true,
        errors: null,
      });

    case actionTypes.GET_SEARCH_INQUIRY_LIST_SUCCESS:
      return Object.assign({}, state, {
        isSearchingInquiryList: false,
        errors: null,
        searchingInquiryListData: action.payload,
      });

    case actionTypes.GET_SEARCH_INQUIRY_LIST_FAILED:
      return Object.assign({}, state, {
        isSearchingInquiryList: false,
        errors: action.error,
      });

    case actionTypes.GET_SEARCH_DEPENDENT_INQUIRY_LIST:
      return Object.assign({}, state, {
        isSearchingDependentInquiryList: true,
        errors: null,
      });

    case actionTypes.GET_SEARCH_DEPENDENT_INQUIRY_LIST_SUCCESS:
      return Object.assign({}, state, {
        isSearchingDependentInquiryList: false,
        errors: null,
        searchingInquiryListData: action.payload,
      });

    case actionTypes.GET_SEARCH_DEPENDENT_INQUIRY_LIST_FAILED:
      return Object.assign({}, state, {
        isSearchingDependentInquiryList: false,
        errors: action.error,
      });

    case actionTypes.GET_SEARCH_INQUIRY_LIST_BY_BRAND:
      return Object.assign({}, state, {
        isSearchingInquiryListByBrand: true,
        errors: null,
      });

    case actionTypes.GET_SEARCH_INQUIRY_LIST_BY_BRAND_SUCCESS:
      return Object.assign({}, state, {
        isSearchingInquiryListByBrand: false,
        errors: null,
        searchingInquiryListData: action.payload,
      });

    case actionTypes.GET_SEARCH_INQUIRY_LIST_BY_BRAND_FAILED:
      return Object.assign({}, state, {
        isSearchingInquiryListByBrand: false,
        errors: action.error,
      });

    case actionTypes.GET_SEARCH_INQUIRY_LIST_BY_DATE:
      return Object.assign({}, state, {
        isSearchingInquiryListByDate: true,
        errors: null,
      });

    case actionTypes.GET_SEARCH_INQUIRY_LIST_BY_DATE_SUCCESS:
      return Object.assign({}, state, {
        isSearchingInquiryListByDate: false,
        errors: null,
        searchingInquiryListData: action.payload,
      });

    case actionTypes.GET_SEARCH_INQUIRY_LIST_BY_DATE_FAILED:
      return Object.assign({}, state, {
        isSearchingInquiryListByDate: false,
        errors: action.error,
      });

    case actionTypes.GET_SEARCH_REMITTANCE_SLIP:
      return Object.assign({}, state, {
        isSearchingRemittanceSlip: true,
        errors: null,
      });

    case actionTypes.GET_SEARCH_REMITTANCE_SLIP_SUCCESS:
      return Object.assign({}, state, {
        isSearchingRemittanceSlip: false,
        errors: null,
        searchingRemittanceData: action.payload,
      });

    case actionTypes.GET_SEARCH_REMITTANCE_SLIP_FAILED:
      return Object.assign({}, state, {
        isSearchingRemittanceSlip: false,
        errors: action.error,
      });

    case actionTypes.GET_SEARCH_REMITTANCE_BY_DATE:
      return Object.assign({}, state, {
        isSearchingRemittanceByDate: true,
        errors: null,
      });

    case actionTypes.GET_SEARCH_REMITTANCE_BY_DATE_SUCCESS:
      return Object.assign({}, state, {
        isSearchingRemittanceByDate: false,
        errors: null,
        searchingRemittanceData: action.payload,
      });

    case actionTypes.GET_SEARCH_REMITTANCE_BY_DATE_FAILED:
      return Object.assign({}, state, {
        isSearchingRemittanceByDate: false,
        errors: action.error,
      });

    case actionTypes.GET_SEARCH_REMITTANCE_BY_BRAND:
      return Object.assign({}, state, {
        isSearchingRemittanceByBrand: true,
        errors: null,
      });

    case actionTypes.GET_SEARCH_REMITTANCE_BY_BRAND_SUCCESS:
      return Object.assign({}, state, {
        isSearchingRemittanceByBrand: false,
        errors: null,
        searchingRemittanceData: action.payload,
      });

    case actionTypes.GET_SEARCH_REMITTANCE_BY_BRAND_FAILED:
      return Object.assign({}, state, {
        isSearchingRemittanceByBrand: false,
        errors: action.error,
      });

    case actionTypes.GET_SEARCH_DEPENDENT_REMITTANCE:
      return Object.assign({}, state, {
        isSearchingDependentRemittance: true,
        errors: null,
      });

    case actionTypes.GET_SEARCH_DEPENDENT_REMITTANCE_SUCCESS:
      return Object.assign({}, state, {
        isSearchingDependentRemittance: false,
        errors: null,
        searchingRemittanceData: action.payload,
      });

    case actionTypes.GET_SEARCH_DEPENDENT_REMITTANCE_FAILED:
      return Object.assign({}, state, {
        isSearchingDependentRemittance: false,
        errors: action.error,
      });

    case actionTypes.GET_SEARCH_PROJECT_NEWS_LETTER_BY_PROPERTY:
      return Object.assign({}, state, {
        isSearchingProjectNewsLetterByProperty: true,
        errors: null,
      });

    case actionTypes.GET_SEARCH_PROJECT_NEWS_LETTER_BY_PROPERTY_SUCCESS:
      return Object.assign({}, state, {
        isSearchingProjectNewsLetterByProperty: false,
        errors: null,
        searchingProjectNewsLetterData: action.payload,
      });

    case actionTypes.GET_SEARCH_PROJECT_NEWS_LETTER_BY_PROPERTY_FAILED:
      return Object.assign({}, state, {
        isSearchingProjectNewsLetterByProperty: false,
        errors: action.error,
      });

    case actionTypes.GET_SEARCH_PROJECT_NEWS_LETTER_BY_BRAND:
      return Object.assign({}, state, {
        isSearchingProjectNewsLetterByBrand: true,
        errors: null,
      });

    case actionTypes.GET_SEARCH_PROJECT_NEWS_LETTER_BY_BRAND_SUCCESS:
      return Object.assign({}, state, {
        isSearchingProjectNewsLetterByBrand: false,
        errors: null,
        searchingProjectNewsLetterData: action.payload,
      });

    case actionTypes.GET_SEARCH_PROJECT_NEWS_LETTER_BY_BRAND_FAILED:
      return Object.assign({}, state, {
        isSearchingProjectNewsLetterByBrand: false,
        errors: action.error,
      });

    case actionTypes.GET_SEARCH_DEPENDENT_PROJECT_NEWS_LETTER:
      return Object.assign({}, state, {
        isSearchingDependentProjectNewsLetter: true,
        errors: null,
      });

    case actionTypes.GET_SEARCH_DEPENDENT_PROJECT_NEWS_LETTER_SUCCESS:
      return Object.assign({}, state, {
        isSearchingDependentProjectNewsLetter: false,
        errors: null,
        searchingProjectNewsLetterData: action.payload,
      });

    case actionTypes.GET_SEARCH_DEPENDENT_PROJECT_NEWS_LETTER_FAILED:
      return Object.assign({}, state, {
        isSearchingDependentProjectNewsLetter: false,
        errors: action.error,
      });

    case actionTypes.GET_SEARCH_FAQ:
      return Object.assign({}, state, {
        isSearchingFaq: true,
        errors: null,
      });

    case actionTypes.GET_SEARCH_FAQ_SUCCESS:
      return Object.assign({}, state, {
        isSearchingFaq: false,
        errors: null,
        searchingFaqData: action.payload,
      });

    case actionTypes.GET_SEARCH_FAQ_FAILED:
      return Object.assign({}, state, {
        isSearchingFaq: false,
        errors: action.error,
      });

    case actionTypes.GET_SEARCH_PAYNOW_CONTRACT_NO:
      return Object.assign({}, state, {
        isSearchingPaynowContractNo: true,
        errors: null,
      });

    case actionTypes.GET_SEARCH_PAYNOW_CONTRACT_NO_SUCCESS:
      return Object.assign({}, state, {
        isSearchingPaynowContractNo: false,
        errors: null,
        searchingPaynowData: action.payload,
      });

    case actionTypes.GET_SEARCH_PAYNOW_CONTRACT_NO_FAILED:
      return Object.assign({}, state, {
        isSearchingPaynowContractNo: false,
        errors: action.error,
      });

    case actionTypes.GET_SEARCH_PAYNOW_BY_BRAND:
      return Object.assign({}, state, {
        isSearchingPaynowByBrand: true,
        errors: null,
      });

    case actionTypes.GET_SEARCH_PAYNOW_BY_BRAND_SUCCESS:
      return Object.assign({}, state, {
        isSearchingPaynowByBrand: false,
        errors: null,
        searchingPaynowData: action.payload,
      });

    case actionTypes.GET_SEARCH_PAYNOW_BY_BRAND_FAILED:
      return Object.assign({}, state, {
        isSearchingPaynowByBrand: false,
        errors: action.error,
      });

    case actionTypes.GET_SEARCH_DEPENDENT_PAYNOW:
      return Object.assign({}, state, {
        isSearchingDependentPaynow: true,
        errors: null,
      });

    case actionTypes.GET_SEARCH_DEPENDENT_PAYNOW_SUCCESS:
      return Object.assign({}, state, {
        isSearchingDependentPaynow: false,
        errors: null,
        searchingPaynowData: action.payload,
      });

    case actionTypes.GET_SEARCH_DEPENDENT_PAYNOW_FAILED:
      return Object.assign({}, state, {
        isSearchingDependentPaynow: false,
        errors: action.error,
      });

    default:
      return state;
  }
};
