import request, { downloadFile } from "../../utils/api";
//web locations

export const getWebLocations = (payload) => {
  return request(`v1/cms/web-locations`, {
    payload,
  });
};
export const postWebLocations = (payload) => {
  return request(`v1/cms/web-locations`, {
    method: "post",
    body: payload,
  });
};

export const deleteWebLocations = (payload) => {
  return request(`v1/cms/web-locations/` + payload.id, {
    method: "delete",
    body: payload,
  });
};

export const editWebLocations = (payload) => {
  return request(`v1/cms/web-locations/` + payload.id, {
    method: "put",
    body: payload,
  });
};
//web awards
export const getWebAwards = (payload) => {
  return request(`v1/cms/web-awards`, {
    payload,
  });
};
export const postWebAwards = (payload) => {
  return request(`v1/cms/web-awards`, {
    method: "post",
    body: payload,
  });
};

export const deleteWebAwards = (payload) => {
  return request(`v1/cms/web-awards/` + payload.id, {
    method: "delete",
    body: payload,
  });
};

export const editWebAwards = (payload) => {
  return request(`v1/cms/web-awards/` + payload.id, {
    method: "put",
    body: payload,
  });
};
//web awards years
export const getWebAwardsYears = (payload) => {
  return request(`v1/cms/web-awards-years`, {
    payload,
  });
};
export const postWebAwardsYears = (payload) => {
  return request(`v1/cms/web-awards-years`, {
    method: "post",
    body: payload,
  });
};

export const deleteWebAwardsYears = (payload) => {
  return request(`v1/cms/web-awards-years/` + payload.id, {
    method: "delete",
    body: payload,
  });
};

export const editWebAwardsYears = (payload) => {
  return request(`v1/cms/web-awards-years/` + payload.id, {
    method: "put",
    body: payload,
  });
};
//properties
export const getProperties = (payload) => {
  return request(`v1/cms/all-properties`, {
    payload,
  });
};
// WEB CONTENTS
export const getWebContent = (payload) => {
  return request(`v1/cms/web-content/${payload}`, {
    payload,
  });
};

export const postWebContent = (payload) => {
  return request(`v1/cms/web-content`, {
    method: "post",
    body: payload,
  });
};
// FAQ CATEGORY
export const getWebFaqCategory = (payload) => {
  return request(`v1/cms/faq-category/${payload}`, {
    payload,
  });
};

export const postWebFaqCategory = (payload) => {
  return request(`v1/cms/faq-category`, {
    method: "post",
    body: payload,
  });
};

export const putWebFaqCategory = (payload) => {
  return request(`v1/cms/faq-category/` + payload.id, {
    method: "put",
    body: payload,
  });
};

export const deleteWebFaqCategory = (payload) => {
  return request(`v1/cms/faq-category/` + payload.id, {
    method: "delete",
    body: payload,
  });
};

// FAQ
export const getWebFaq = (payload) => {
  return request(`v1/cms/web-faq/${payload}`, {
    payload,
  });
};

export const postWebFaq = (payload) => {
  return request(`v1/cms/web-faq`, {
    method: "post",
    body: payload,
  });
};

export const putWebFaq = (payload) => {
  return request(`v1/cms/web-faq/` + payload.id, {
    method: "put",
    body: payload,
  });
};

export const deleteWebFaq = (payload) => {
  return request(`v1/cms/web-faq/` + payload.id, {
    method: "delete",
    body: payload,
  });
};

// POPUP PROMO
export const getWebPopupPromo = (payload) => {
  return request(`v1/cms/web-popup-promo/${payload}`, {
    payload,
  });
};

export const postWebPopupPromo = (payload) => {
  return request(`v1/cms/web-popup-promo`, {
    method: "post",
    body: payload,
  });
};

export const putWebPopupPromo = (payload) => {
  return request(`v1/cms/web-popup-promo/` + payload.id, {
    method: "put",
    body: payload,
  });
};

export const deleteWebPopupPromo = (payload) => {
  return request(`v1/cms/web-popup-promo/` + payload.id, {
    method: "delete",
    body: payload,
  });
};

// TESTIMONIALS
export const getWebTestimonials = (payload) => {
  return request(`v1/cms/web-testimonials/${payload}`, {
    payload,
  });
};

export const postWebTestimonials = (payload) => {
  return request(`v1/cms/web-testimonials`, {
    method: "post",
    body: payload,
  });
};

export const putWebTestimonials = (payload) => {
  return request(`v1/cms/web-testimonials/` + payload.id, {
    method: "put",
    body: payload,
  });
};

export const deleteWebTestimonials = (payload) => {
  return request(`v1/cms/web-testimonials/` + payload.id, {
    method: "delete",
    body: payload,
  });
};

// NEWS
export const getWebNews = (payload) => {
  return request(`v1/cms/web-news/${payload}`, {
    payload,
  });
};

export const postWebNews = (payload) => {
  return request(`v1/cms/web-news`, {
    method: "post",
    body: payload,
  });
};

export const putWebNews = (payload) => {
  return request(`v1/cms/web-news/` + payload.id, {
    method: "put",
    body: payload,
  });
};

export const deleteWebNews = (payload) => {
  return request(`v1/cms/web-news/` + payload.id, {
    method: "delete",
    body: payload,
  });
};

// CAREER
export const getWebCareer = (payload) => {
  return request(`v1/cms/web-career/${payload}`, {
    payload,
  });
};

export const postWebCareer = (payload) => {
  return request(`v1/cms/web-career`, {
    method: "post",
    body: payload,
  });
};

export const putWebCareer = (payload) => {
  return request(`v1/cms/web-career/` + payload.id, {
    method: "put",
    body: payload,
  });
};

export const deleteWebCareer = (payload) => {
  return request(`v1/cms/web-career/` + payload.id, {
    method: "delete",
    body: payload,
  });
};

// CAREER APPLICATION
export const getWebCareerApplication = (payload) => {
  return request(`v1/cms/web-career-application/${payload}`, {
    payload,
  });
};

export const postWebCareerApplication = (payload) => {
  return request(`v1/cms/web-career-application`, {
    method: "post",
    body: payload,
  });
};

export const putWebCareerApplication = (payload) => {
  return request(`v1/cms/web-career-application/` + payload.id, {
    method: "put",
    body: payload,
  });
};

export const deleteWebCareerApplication = (payload) => {
  return request(`v1/cms/web-career-application/` + payload.id, {
    method: "delete",
    body: payload,
  });
};

export const deletePropertyInquiry = (payload) => {
  return request(`v1/cms/web-inquiry/brand/` + payload.id, {
    method: "delete",
    body: payload,
  });
};

export const deleteAppointmentInquiry = (payload) => {
  return request(`v1/cms/web-appointment/brand/` + payload.id, {
    method: "delete",
    body: payload,
  });
};

export const postWebRecipients = (payload) => {
  return request(`v1/web/web-recipient`, {
    method: "post",
    body: payload,
  });
};

export const getWebRecipients = (payload) => {
  return request(`v1/web/web-recipient/${payload.id}`, {
    method: "get",
  });
};

export const getSoftDeletedWebCareerApplication = (payload) => {
  return request(`v1/cms/trashed-web-career-application/${payload}`, {
    method: "get",
  });
};

export const getSoftDeletedWebAppointments = (payload) => {
  return request(`v1/cms/trashed-web-appointment/${payload}`, {
    method: "get",
  });
};

export const getSoftDeletedWebPropertyInquiries = (payload) => {
  return request(`v1/cms/trashed-web-inquiry/${payload}`, {
    method: "get",
  });
};

export const postRestoreWebPropertyInquiries = (payload) => {
  return request(`v1/cms/web-inquiry/brand/${payload.id}`, {
    method: "post",
    body: payload,
  });
};

export const postRestoreWebCareerApplication = (payload) => {
  return request(`v1/cms/restore-web-career-application`, {
    method: "post",
    body: payload,
  });
};

export const postRestoreWebAppointments = (payload) => {
  return request(`v1/cms/web-appointment/brand/${payload.id}`, {
    method: "post",
    body: payload,
  });
};

export const postExportWebAppointments = (payload) => {
  return downloadFile(
    `v1/cms/export-web-appointment/${payload.brand_id}`,
    payload,
    "export-web-appointments.xlsx"
  );
};

export const postExportWebCareerApplication = (payload) => {
  return downloadFile(
    `v1/cms/export-web-career-application/${payload.brand_id}`,
    payload,
    "export-web-career-applications.xlsx"
  );
};

export const postExportWebPropertyInquiries = (payload) => {
  return downloadFile(
    `v1/cms/export-web-inquiry/${payload.brand_id}`,
    payload,
    "export-web-inquiry.xlsx"
  );
};

export const getWebCareersRecipients = (payload) => {
  return request(`v1/web/web-careers-recipient/${payload.id}`, {
    method: "get",
  });
};

export const getWebInquiryRecipients = (payload) => {
  return request(`v1/web/web-property-inquiry/${payload.id}`, {
    method: "get",
  });
};

export const getWebPropertyInquiries = (payload) => {
  return request(`v1/cms/web-inquiry/brand/${payload}`, {
    method: "get",
  });
};

export const getWebAppointmentInquiries = (payload) => {
  return request(`v1/cms/web-appointment/brand/${payload}`, {
    method: "get",
  });
};
