import { uuid } from "./helper";
export const buyerMenu = () => {
  return [
    {
      link: "content-management",
      label: "Content Management",
      sub: [
        {
          link: "automatic-debit-arrangement",
          label: "Automatic Debit Arrangement",
        },
        {
          link: "dashboard",
          label: "Dashboard",
        },
        {
          link: "document-list",
          label: "Document List",
        },
        {
          link: "email-content",
          label: "Email Content",
        },
        {
          link: "faq",
          label: "FAQ",
        },
        {
          link: "login",
          label: "Login",
        },
        {
          link: "my-account",
          label: "My Account",
        },
        {
          link: "official-receipt",
          label: "Official Receipt",
        },
        {
          link: "post-dated-checks",
          label: "Post Dated Checks",
        },
        {
          link: "privacy-policy",
          label: "Privacy Policy",
        },
        {
          link: "project-updates",
          label: "Project Updates",
        },
        {
          link: "remittance-slip",
          label: "Remittance Slip",
        },
        {
          link: "request-and-inquiry",
          label: "Request and Inquiry",
        },
        {
          link: "statement-of-accounts",
          label: "Statement of Accounts",
        },

        {
          link: "terms-and-condition",
          label: "Terms and Condition",
        }
      ],
    },
    {
      link: "data-management",
      label: "Data Management",
      sub: [
        {
          link: "inquiry-list",
          label: "Inquiry List",
        },
        {
          link: "project-newsletter",
          label: "Project Newsletter",
        },
        {
          link: "remittance-slip-list",
          label: "Remittance Slip List",
        },
        {
          link: "pay-now-transaction",
          label: "Pay Now Transaction",
        },
      ],
    },
    {
      link: "audit-trail",
      label: "Audit Trail",
    },
  ];
};

export const sellerMenu = () => {
  return [
    // {
    //   link: "resources",
    //   label: "Resources",
    //   sub: [
    //     {
    //       link: "knowledge",
    //       label: "Knowledge"
    //     }
    //   ]
    // }
    {
      link: "resources",
      label: "Resources",
    },
    {
      link: "my-dashboard",
      label: "My Dashboard",
      subMenu: [
        {
          label: "Customer Lead",
          sub: [
            {
              link: "ownership-extension",
              label: "Ownership Extension",
            },
            {
              link: "renewal-request",
              label: "Renew Lead",
            },
            {
              link: "transfer-request",
              label: "Request Transfer",
            },
            {
              link: "submission-extension",
              label: "Submission Extension",
            },
          ],
        },
        {
          label: "Audit Trail",
          sub: [
            {
              link: "audit-trail-reservation",
              label: "Reservation",
            },
            {
              link: "audit-trail-ebooking",
              label: "CIS / RA",
            },
          ],
        },
      ],
      sub: [
        {
          link: "e-booking",
          label: "CIS/RA Approval",
        },
        // {
        //   link: "customer-360",
        //   label: "Customer 360"
        // },
        {
          link: "marketing-partners",
          label: "Marketing Partners",
        },
        {
          link: "marketing-partners-approvers",
          label: "Marketing Partners Approvers",
        },
      ],
    },
    {
      link: "lead-retention",
      label: "Lead Retention",
    },
    // {
    //   link: "audit-trail",
    //   label: "Audit Trail",
    // },
    {
      link: "audit-trail",
      label: "Audit Trail",
      subMenu: [],
      sub: [
        {
          link: "audit-trail",
          label: "Login Trails",
        },
        {
          link: "sellers-trail",
          label: "Seller's Trail",
        },
      ],
    },
    {
      link: "sales-report",
      label: "Sales Report",
      subMenu: [],
      sub: [
        {
          link: "current-contract-quota",
          label: "Current Contract Quota",
        },
        {
          link: "year-to-date-report",
          label: "Year to Date Report",
        },
      ],
    },
  ];
};

export const websiteBrands = () => {
  return [
    { id: 0, name: "Robinsons Omnibus", code: "OMNI" },
    { id: 1, name: "Robinsons Residences", code: "ZRRE" },
    { id: 2, name: "Robinsons Communities", code: "ZRCO" },
    { id: 3, name: "Robinsons Luxuria", code: "ZRLU" },
    // { id: 4, name: "RLC Residences", code: "ZRLU" },
  ];
};

export const pageList = {
  header: [
    {
      name: "Home",
      route: "/",
      id: uuid(),
      enable: true,
    },
    {
      name: "About Us",
      route: "/about",
      id: uuid(),
      enable: true,
    },
    {
      name: "Properties",
      route: "/property",
      id: uuid(),
      enable: true,
    },
    {
      name: "Property Details",
      route: "/property/:id",
      id: uuid(),
      enable: true,
    },
    {
      name: "Contact Us",
      route: "/contact-us",
      id: uuid(),
      enable: true,
    },
    {
      name: "News And Promos",
      route: "/news",
      id: uuid(),
      enable: true,
    },
    {
      name: "News And Articles",
      route: "/news",
      id: uuid(),
      enable: true,
    },
    {
      name: "Inquire Now",
      route: "/contact-us",
      id: uuid(),
      enable: true,
    },
    {
      name: "Appointment",
      route: "/appointment",
      id: uuid(),
      enable: true,
    },
    {
      name: "Property Finder",
      route: "/search",
      id: uuid(),
      enable: true,
    },
    {
      name: "Award",
      route: "/award",
      id: uuid(),
      enable: true,
    },
  ],
  footer: [
    {
      name: "Investment Calculator",
      route: "/calculator",
      id: uuid(),
      enable: true,
    },
    {
      name: "Compare Properties",
      route: "/compare",
      id: uuid(),
      enable: true,
    },
    {
      name: "Appointment",
      route: "/appointment",
      id: uuid(),
      enable: true,
    },
    {
      name: "Careers",
      route: "/careers",
      id: uuid(),
      enable: true,
    },
    {
      name: "FAQ",
      route: "/faq",
      id: uuid(),
      enable: true,
    },
    {
      name: "Testimonials",
      route: "/testimonials",
      id: uuid(),
      enable: true,
    },
    {
      name: "Privacy Policy",
      route: "/privacy-policy",
      id: uuid(),
      enable: true,
    },
  ],
  side_menu: [
    {
      name: "Appointment",
      route: "/appointment",
      id: uuid(),
      enable: true,
    },
    {
      name: "Investment Calculator",
      route: "/calculator",
      id: uuid(),
      enable: true,
    },
    {
      name: "Contact Number",
      route: "",
      id: uuid(),
      enable: true,
    },
    {
      name: "Email",
      route: "",
      id: uuid(),
      enable: true,
    },
  ],
  mobile_menu: [
    {
      name: "Home",
      route: "/",
      id: uuid(),
      enable: true,
    },
    {
      name: "Properties",
      route: "/properties",
      id: uuid(),
      enable: true,
    },
    {
      name: "Contact Us",
      route: "/contact-us",
      id: uuid(),
      enable: true,
    },
    {
      name: "News And Promos",
      route: "/news-&-promos",
      id: uuid(),
      enable: true,
    },

    {
      name: "Compare Properties",
      route: "/compare-properties",
      id: uuid(),
      enable: true,
    },
    {
      name: "Affordability Calculator",
      route: "/affordability-calculator",
      id: uuid(),
      enable: true,
    },
    {
      name: "Book Appointment",
      route: "/book-appointment",
      id: uuid(),
      enable: true,
    },
  ],
};

export const websiteMenu = () => {
  return [
    {
      link: "general",
      label: "General",
      sub: [
        {
          link: "contact-information",
          label: "Contact Information",
        },
        {
          link: "email-recipients",
          label: "Email Recipients",
        },
        {
          link: "email-content",
          label: "Email Content",
        },
        {
          link: "footer",
          label: "Footer",
        },
        {
          link: "header",
          label: "Header",
        },
        // {
        //   link: "jellybean-design",
        //   label: "Jellybean Design",
        //   allow: ["ZRCO"],
        // },
        {
          link: "page-list",
          label: "Page List",
        },
        // {
        //   link: "side-menu",
        //   label: "Side Menu"
        // }
      ],
    },
    {
      link: "home",
      label: "Home",
      sub: [
        // {
        //   link: "order-settings",
        //   label: "Order Settings"
        // },
        {
          link: "abc-of-living-section",
          label: "Value Proposition",
          allow: ["ZRCO"],
        },
        {
          link: "about-rlc-residences",
          label: "About RLC Residences",
          allow: ["ZRCO"],
        },
        {
          link: "about-us-section",
          label: "About Us Section",
          allow: ["ZRRE"],
        },
        {
          link: "banner-section",
          label: "Banner Section",
        },
        {
          link: "bulletin-section",
          label: "Bulletin Section",
          allow: ["ZRLU"],
        },
        {
          link: "contact-section",
          label: "Contact Section",
          allow: ["ZRLU"],
        },
        {
          link: "featured-properties-section",
          label: "Featured Properties Section",
        },
        {
          link: "latest-news-section",
          label: "Latest News Section",
          allow: ["OMNI"],
        },
        {
          link: "news-and-promo-section",
          label: "News and Promo Section",
          allow: ["ZRCO", "ZRRE", "OMNI"],
        },
        {
          link: "popup-promo-list",
          label: "Popup Promo List",
        },
        {
          link: "testimonials-section",
          label: "Testimonials Section",
          allow: ["ZRCO"],
        },
        {
          link: "value-proposition",
          label: "Value Proposition",
          allow: ["ZRLU"],
        },
      ],
    },
    {
      link: "properties",
      label: "Properties",
      sub: [
        {
          link: "inner-properties",
          label: "Inner Properties",
          allow: ["OMNI"],
        },
        {
          link: "properties-page",
          label: "Properties Page",
        },
        {
          link: "property-details",
          label: "Property Details",
          allow: ["ZRLU", "ZRRE", "ZRCO"],
        },
        {
          link: "property-details-page",
          label: "Property Details Page",
        },
        {
          link: "search-result-page",
          label: "Search Result Page",
          allow: ["ZRLU", "ZRRE", "OMNI"],
        },
      ],
    },
    {
      link: "news-and-articles",
      label: "News and Articles",
      sub: [
        {
          link: "news-and-articles-page",
          label: "News and Articles Page",
        },
        {
          link: "news-list",
          label: "News List",
        },
        {
          link: "newsletter-subscriptions",
          label: "Newsletter Subscriptions",
        },
      ],
    },
    //for Award
    {
      link: "award",
      label: "Awards",
      sub: [
        {
          link: "award-page",
          label: "Awards Page",
          allow: ["ZRCO"],
        },
        {
          link: "award-content",
          label: "Awards Content",
          allow: ["ZRCO"],
        },
      ],
      allow: ["ZRCO"],
    },
    {
      link: "contact-us-page",
      label: "Contact Us Page",
    },
    {
      link: "about-us-page",
      label: "About Us Page",
      allow: ["ZRCO"],
    },
    {
      link: "appointment-page",
      label: "Appointment Page",
    },
    {
      link: "calculator-page",
      label: "Calculator Page",
    },
    {
      link: "careers",
      label: "Careers",
      sub: [
        {
          link: "application-list",
          label: "Application List",
        },
        {
          link: "careers-list",
          label: "Careers List",
        },
        {
          link: "careers-page",
          label: "Careers Page",
        },
      ],
    },
    {
      link: "compare-page",
      label: "Compare Page",
    },
    {
      link: "location",
      label: "Location Page",
      allow: ["ZRCO"],
    },
    {
      link: "faq",
      label: "FAQ",
      sub: [
        {
          link: "faq-category-list",
          label: "FAQ Category List",
          allow: ["ZRLU", "ZRRE", "OMNI"],
        },
        {
          link: "faq-list",
          label: "FAQ List",
          allow: ["ZRLU", "ZRRE", "OMNI"],
        },
        {
          link: "faq-page",
          label: "FAQ Page",
          allow: ["ZRLU", "ZRRE", "OMNI"],
        },
      ],
      allow: ["ZRLU", "ZRRE", "OMNI"],
    },
    {
      link: "testimonials",
      label: "Testimonials",
      sub: [
        {
          link: "testimonials-list",
          label: "Testimonials List",
        },
        {
          link: "testimonials-page",
          label: "Testimonials Page",
        },
      ],
    },
    {
      link: "property-inquiry",
      label: "Property Inquiry",
    },
    {
      link: "appointment-inquiry",
      label: "Appointment Inquiry",
    },
  ];
};

export const adminMenu = () => {
  return [
    {
      link: "user-account",
      label: "User Account",
    },
    {
      link: "user-role",
      label: "User Role",
    },
    {
      link: "unsubscribed-users",
      label: "Unsubscribed Users",
    },
    {
      link: "email-settings",
      label: "Email Settings",
    },
    {
      link: "audit-trail",
      label: "Audit Trail",
    },
    {
      link: "internal-commands",
      label: "Internal Commands",
    },
  ];
};

export const projectInfoMenu = () => {
  return [
    {
      link: "basic-info",
      label: "Basic Info",
    },
    {
      link: "project-overview",
      label: "Project Overview",
    },
    {
      link: "locations",
      label: "Locations",
    },
    {
      link: "building-details",
      label: "Building Details",
    },
    {
      link: "amenities",
      label: "Amenities",
    },
    {
      link: "floor-plans",
      label: "Floor Plans",
    },
    {
      link: "unit-details",
      label: "Unit Details",
    },
    // {
    //   link: "website-data",
    //   label: "Website Data"
    // }
  ];
};
