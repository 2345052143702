import request from "../../utils/api";

export const getEmailContent = (payload) => {
  return request(`v1/cms/email-content`, {
    payload,
  });
};
export const postEmailContent = (payload) => {
  return request(`v1/cms/email-content`, {
    method: "post",
    body: payload,
  });
};

export const getContent = (payload) => {
  return request(`v1/cms/buyer-content/${payload}`, {
    payload,
  });
};

export const postContent = (payload) => {
  return request(`v1/cms/buyer-content`, {
    method: "post",
    body: payload,
  });
};

export const getAgreement = (payload) => {
  return request(`v1/cms/buyer-agreement/${payload}`, {
    payload,
  });
};

export const postAgreement = (payload) => {
  return request(`v1/cms/buyer-agreement`, {
    method: "post",
    body: payload,
  });
};

export const getAuditTrail = (payload) => {
  return request(`v1/cms/audit-trail`, {
    payload,
  });
};

export const postAuditTrail = (payload) => {
  return request(`v1/cms/audit-trail`, {
    method: "post",
    body: payload,
  });
};

export const getRemittanceList = (payload) => {
  const { params } = payload;
  return request(`v1/cms/document-list`, {
    params,
  });
};

export const getPayNowTransactionList = (payload) => {
  return request(`v1/cms/payments`, {
    payload,
  });
};

// export const getFaqSuggestive = payload => {
//   return request(`v1/cms/faq`, {
//     payload
//   });
// };

export const getFaqCategory = (payload) => {
  return request(`v1/cms/faq-category`, {
    payload,
  });
};

export const getFaq = (payload) => {
  return request(`v1/cms/faq`, {
    payload,
  });
};

export const postFaq = (payload) => {
  return request(`v1/cms/faq`, {
    method: "post",
    body: payload,
  });
};

export const putFaq = (payload) => {
  return request(`v1/cms/faq/` + payload.id, {
    method: "put",
    body: payload,
  });
};
export const getInquiryAudit = (payload) => {
  return request(`v1/cms/inquiry-list`, {
    payload,
  });
};

export const deleteFaq = (payload) => {
  return request(`v1/cms/faq/` + payload.id, {
    method: "delete",
    body: payload,
  });
};

// export const getProperties = payload => {
//   return request(`v1/property`, {
//     payload
//   });
// };

export const postProjectNews = (payload) => {
  return request(`v1/cms/buyer/project-news`, {
    method: "post",
    body: payload,
  });
};

export const getProjectNews = (payload) => {
  const { params } = payload;
  return request(`v1/cms/buyer/project-news`, {
    params,
  });
};

export const deleteProjectNews = (params) => {
  return request(`v1/cms/buyer/project-news/${params.id}`, {
    method: "delete",
  });
};

export const searchInquiryList = (params) => {
  return request(
    `v1/cms/search-inquiry-list${params.name ? `?name=${params.name}` : ""}`
  );
};

export const searchDependentInquiryList = (payload) => {
  return request(`v1/cms/search-dependent-inquiry-list`, {
    method: "post",
    body: payload,
  });
};

export const searchInquiryListByBrand = (params) => {
  return request(
    `v1/cms/search-inquiry-list-by-brand${
      params.brand_id !== "" ? `?brand_id=${params.brand_id}` : ""
    }`
  );
};

export const searchInquiryListByDate = (params) => {
  return request(
    `v1/cms/search-inquiry-list-by-date${
      params.startDate ? `?start_date=${params.startDate}` : ""
    }${params.endDate ? `&end_date=${params.endDate}` : ""}`
  );
};

export const searchRemittnceSlip = (params) => {
  return request(
    `v1/cms/search-by-remittance-slip${
      params.remittnaceSlip ? `?remittance_slip=${params.remittnaceSlip}` : ""
    }`
  );
};

export const searchRemittnceByDate = (params) => {
  return request(
    `v1/cms/search-remittance-by-date${
      params.startDate ? `?start_date=${params.startDate}` : ""
    }${params.endDate ? `&end_date=${params.endDate}` : ""}`
  );
};

export const searchRemittnceByBrand = (params) => {
  return request(
    `v1/cms/search-remittance-by-brand${
      params.brandCode !== "" ? `?brand_code=${params.brandCode}` : ""
    }`
  );
};

export const searchDependentRemittance = (payload) => {
  return request(`v1/cms/search-dependent-remittance`, {
    method: "post",
    body: payload,
  });
};

export const searchProjectNewsLetterByProperty = (params) => {
  return request(
    `v1/cms/buyer/search-project-news-by-property${
      params.propertyName ? `?property_name=${params.propertyName}` : ""
    }`
  );
};

export const searchProjectNewsLetterByBrand = (params) => {
  return request(
    `v1/cms/buyer/search-project-news-by-brand${
      params.brandCode ? `?brand_code=${params.brandCode}` : ""
    }`
  );
};

export const searchDependentProjectNewsLetter = (payload) => {
  return request(`v1/cms/buyer/search-dependent-project-news`, {
    method: "post",
    body: payload,
  });
};

export const searchFaq = (params) => {
  return request(
    `v1/cms/search-faq${
      params.searchValue ? `?search=${params.searchValue}` : ""
    }`
  );
};

export const searchPaynowContractNo = (params) => {
  return request(
    `v1/cms/search-paynow${
      params.contractNo ? `?contract_no=${params.contractNo}` : ""
    }`
  );
};

export const searchPaynowByBrand = (params) => {
  return request(
    `v1/cms/search-paynow${
      params.brandCode ? `?brand_code=${params.brandCode}` : ""
    }`
  );
};

export const searchDependentPaynow = (payload) => {
  return request(`v1/cms/search-dependent-paynow`, {
    method: "post",
    body: payload,
  });
};
