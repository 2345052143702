import request from "../../utils/api";

export const getManageContent = params => {
  return request("v1/cms/get-manage-content", {
    params
  });
};
export const getProperties = params => {
  return request("v1/property", {
    params
  });
};

export const updateManageContent = params => {
  return request(`v1/cms/set-bank`, {
    method: "put",
    body: params
  });
};

export const updateProjectInfo = params => {
  const { projectId } = params;

  return request(`v1/property/${projectId}`, {
    method: "put",
    body: params
  });
};

export const getBrands = params => {
  return request("v1/cms/brands", {
    params
  });
};

export const getAllActiveProperties = params => {
  return request("v1/property/project-info", {
    params
  });
};
