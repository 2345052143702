// Action Types
export const actionTypes = {
  CHANGED_THEME: "CHANGED_THEME",
  SELECTED_MENU: "SELECTED_MENU",
  OPEN_SUB_MENU: "OPEN_SUB_MENU",
  RESET_THEME: "RESET_THEME"
};

// Action creators
export const actionCreators = {
  changeTheme: theme => ({
    type: actionTypes.CHANGED_THEME,
    theme
  }),
  selectMenu: menu => ({
    type: actionTypes.SELECTED_MENU,
    menu
  }),
  openSubMenu: submenu => ({
    type: actionTypes.OPEN_SUB_MENU,
    submenu
  }),
  resetTheme: () => ({
    type: actionTypes.RESET_THEME
  })
};

// Reducer
const initialState = {
  selectedTheme: "default",
  selectedMenu: ["1"],
  submenu: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CHANGED_THEME:
      return Object.assign({}, state, {
        selectedTheme: action.theme
      });

    case actionTypes.SELECTED_MENU:
      return Object.assign({}, state, {
        selectedMenu: action.menu.selectedKeys
      });

    case actionTypes.OPEN_SUB_MENU:
      return Object.assign({}, state, {
        submenu: action.submenu
      });

    case actionTypes.RESET_THEME:
      return Object.assign({}, state, {
        selectedTheme: "default",
        selectedMenu: ["1"],
        submenu: []
      });

    default:
      return state;
  }
};
