import { call, put, takeEvery } from "redux-saga/effects";
import { actionTypes as profileActionTypes } from "./reducer";
import { whoami as serviceGetProfile } from "./service";
import { message } from "antd";

function* getProfile(action) {
  try {
    const profile = yield call(serviceGetProfile);

    yield put({
      type: profileActionTypes.GET_PROFILE_REQUEST_SUCCESS,
      profile
    });
  } catch (err) {
    yield put({ type: profileActionTypes.GET_PROFILE_REQUEST_FAILED, err });

    message.error("Failed to get profile");
  }
}

function* watchGetProfileRequest() {
  yield takeEvery(profileActionTypes.GET_PROFILE_REQUEST, getProfile);
}

export default [watchGetProfileRequest()];
