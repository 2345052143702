// Action Types
export const actionTypes = {
  // get quota

  GET_CONTRACT_QUOTA: "GET_CONTRACT_QUOTA",
  GET_CONTRACT_QUOTA_SUCCESS: "GET_CONTRACT_QUOTA_SUCCESS",
  GET_CONTRACT_QUOTA_FAILED: "GET_CONTRACT_QUOTA_FAILED",

  GET_YTD: "GET_YTD",
  GET_YTD_SUCCESS: "GET_YTD_SUCCESS",
  GET_YTD_FAILED: "GET_YTD_FAILED",

  // get knowledge center folder

  GET_KNOWLEDGE_CENTER_FOLDER: "GET_KNOWLEDGE_CENTER_FOLDER",
  GET_KNOWLEDGE_CENTER_FOLDER_SUCCESS: "GET_KNOWLEDGE_CENTER_FOLDER_SUCCESS",
  GET_KNOWLEDGE_CENTER_FOLDER_FAILED: "GET_KNOWLEDGE_CENTER_FOLDER_FAILED",

  // update / delete folder

  UPDATE_KNOWLEDGE_CENTER_FOLDER: "UPDATE_KNOWLEDGE_CENTER_FOLDER",
  UPDATE_KNOWLEDGE_CENTER_FOLDER_SUCCESS:
    "UPDATE_KNOWLEDGE_CENTER_FOLDER_SUCCESS",
  UPDATE_KNOWLEDGE_CENTER_FOLDER_FAILED:
    "UPDATE_KNOWLEDGE_CENTER_FOLDER_FAILED",
  // GET_PROPERTIES: "GET_PROPERTIES",
  // GET_PROPERTIES_SUCCESS: "GET_PROPERTIES_SUCCESS",
  // GET_PROPERTIES_FAILED: "GET_PROPERTIES_FAILED",

  UPDATE_PROPERTY_INFO_SELLERS: "UPDATE_PROPERTY_INFO_SELLERS",
  UPDATE_PROPERTY_INFO_SELLERS_SUCCESS: "UPDATE_PROPERTY_INFO_SELLERS_SUCCESS",
  UPDATE_PROPERTY_INFO_SELLERS_FAILED: "UPDATE_PROPERTY_INFO_SELLERS_FAILED",

  // GET_BRANDS: "GET_BRANDS",
  // GET_BRANDS_SUCCESS: "GET_BRANDS_SUCCESS",
  // GET_BRANDS_FAILED: "GET_BRANDS_FAILED",

  GET_SUBMITTED_RESERVATION: "GET_SUBMITTED_RESERVATION",
  GET_SUBMITTED_RESERVATION_SUCCESS: "GET_SUBMITTED_RESERVATION_SUCCESS",
  GET_SUBMITTED_RESERVATION_FAILED: "GET_SUBMITTED_RESERVATION_FAILED",

  GET_CIS_INFORMATION: "GET_CIS_INFORMATION",
  GET_CIS_INFORMATION_SUCCESS: "GET_CIS_INFORMATION_SUCCESS",
  GET_CIS_INFORMATION_FAILED: "GET_CIS_INFORMATION_FAILED",

  GET_CIS_ATTACHMENT: "GET_CIS_ATTACHMENT",
  GET_CIS_ATTACHMENT_SUCCESS: "GET_CIS_ATTACHMENT_SUCCESS",
  GET_CIS_ATTACHMENT_FAILED: "GET_CIS_ATTACHMENT_FAILED",

  GET_CLIENT_SURVEY: "GET_CLIENT_SURVEY",
  GET_CLIENT_SURVEY_SUCCESS: "GET_CLIENT_SURVEY_SUCCESS",
  GET_CLIENT_SURVEY_FAILED: "GET_CLIENT_SURVEY_FAILED",

  GET_CLIENT_SIGNATURE: "GET_CLIENT_SIGNATURE",
  GET_CLIENT_SIGNATURE_SUCCESS: "GET_CLIENT_SIGNATURE_SUCCESS",
  GET_CLIENT_SIGNATURE_FAILED: "GET_CLIENT_SIGNATURE_FAILED",

  POST_RESERVATION_STATUS: "POST_RESERVATION_STATUS",
  POST_RESERVATION_STATUS_SUCCESS: "POST_RESERVATION_STATUS_SUCCESS",
  POST_RESERVATION_STATUS_FAILED: "POST_RESERVATION_STATUS_FAILED",

  GET_TRANSFER_REQUEST: "GET_TRANSFER_REQUEST",
  GET_TRANSFER_REQUEST_SUCCESS: "GET_TRANSFER_REQUEST_SUCCESS",
  GET_TRANSFER_REQUEST_FAILED: "GET_TRANSFER_REQUEST_FAILED",

  POST_TRANSFER_REQUEST: "POST_TRANSFER_REQUEST",
  POST_TRANSFER_REQUEST_SUCCESS: "POST_TRANSFER_REQUEST_SUCCESS",
  POST_TRANSFER_REQUEST_FAILED: "POST_TRANSFER_REQUEST_FAILED",

  GET_INCENTIVE: "GET_INCENTIVE",
  GET_INCENTIVE_SUCCESS: "GET_INCENTIVE_SUCCESS",
  GET_INCENTIVE_FAILED: "GET_INCENTIVE_FAILED",

  POST_INCENTIVE: "POST_INCENTIVE",
  POST_INCENTIVE_SUCCESS: "POST_INCENTIVE_SUCCESS",
  POST_INCENTIVE_FAILED: "POST_INCENTIVE_FAILED",

  DELETE_INCENTIVE: "DELETE_INCENTIVE",
  DELETE_INCENTIVE_SUCCESS: "DELETE_INCENTIVE_SUCCESS",
  DELETE_INCENTIVE_FAILED: "DELETE_INCENTIVE_FAILED",

  GET_KNOWLEDGE_CENTER: "GET_KNOWLEDGE_CENTER",
  GET_KNOWLEDGE_CENTER_SUCCESS: "GET_KNOWLEDGE_CENTER_SUCCESS",
  GET_KNOWLEDGE_CENTER_FAILED: "GET_KNOWLEDGE_CENTER_FAILED",

  POST_KNOWLEDGE_CENTER: "POST_KNOWLEDGE_CENTER",
  POST_KNOWLEDGE_CENTER_SUCCESS: "POST_KNOWLEDGE_CENTER_SUCCESS",
  POST_KNOWLEDGE_CENTER_FAILED: "POST_KNOWLEDGE_CENTER_FAILED",

  DELETE_KNOWLEDGE_CENTER: "DELETE_KNOWLEDGE_CENTER",
  DELETE_KNOWLEDGE_CENTER_SUCCESS: "DELETE_KNOWLEDGE_CENTER_SUCCESS",
  DELETE_KNOWLEDGE_CENTER_FAILED: "DELETE_KNOWLEDGE_CENTER_FAILED",

  GET_RETENTION: "GET_RETENTION",
  GET_RETENTION_SUCCESS: "GET_RETENTION_SUCCESS",
  GET_RETENTION_FAILED: "GET_RETENTION_FAILED",

  POST_RETENTION: "POST_RETENTION",
  POST_RETENTION_SUCCESS: "POST_RETENTION_SUCCESS",
  POST_RETENTION_FAILED: "POST_RETENTION_FAILED",

  GET_MARKETING_PARTNER_MULTILEVEL: "GET_MARKETING_PARTNER_MULTILEVEL",
  GET_MARKETING_PARTNER_MULTILEVEL_SUCCESS:
    "GET_MARKETING_PARTNER_MULTILEVEL_SUCCESS",
  GET_MARKETING_PARTNER_MULTILEVEL_FAILED:
    "GET_MARKETING_PARTNER_MULTILEVEL_FAILED",

  POST_MARKETING_PARTNER_MULTILEVEL: "POST_MARKETING_PARTNER_MULTILEVEL",
  POST_MARKETING_PARTNER_MULTILEVEL_SUCCESS:
    "POST_MARKETING_PARTNER_MULTILEVEL_SUCCESS",
  POST_MARKETING_PARTNER_MULTILEVEL_FAILED:
    "POST_MARKETING_PARTNER_MULTILEVEL_FAILED",

  GET_MARKETING_PARTNER: "GET_MARKETING_PARTNER",
  GET_MARKETING_PARTNER_SUCCESS: "GET_MARKETING_PARTNER_SUCCESS",
  GET_MARKETING_PARTNER_FAILED: "GET_MARKETING_PARTNER_FAILED",

  POST_MARKETING_PARTNER: "POST_MARKETING_PARTNER",
  POST_MARKETING_PARTNER_SUCCESS: "POST_MARKETING_PARTNER_SUCCESS",
  POST_MARKETING_PARTNER_FAILED: "POST_MARKETING_PARTNER_FAILED",

  POST_MARKETING_PARTNER_TO_SAP: "POST_MARKETING_PARTNER_TO_SAP",
  POST_MARKETING_PARTNER_TO_SAP_SUCCESS:
    "POST_MARKETING_PARTNER_TO_SAP_SUCCESS",
  POST_MARKETING_PARTNER_TO_SAP_FAILED: "POST_MARKETING_PARTNER_TO_SAP_FAILED",

  GET_SALES_GROUP: "GET_SALES_GROUP",
  GET_SALES_GROUP_SUCCESS: "GET_SALES_GROUP_SUCCESS",
  GET_SALES_GROUP_FAILED: "GET_SALES_GROUP_FAILED",

  GET_OWNERSHIP_EXTENSION: "GET_OWNERSHIP_EXTENSION",
  GET_OWNERSHIP_EXTENSION_SUCCESS: "GET_OWNERSHIP_EXTENSION_SUCCESS",
  GET_OWNERSHIP_EXTENSION_FAILED: "GET_OWNERSHIP_EXTENSION_FAILED",

  POST_OWNERSHIP_EXTENSION: "POST_OWNERSHIP_EXTENSION",
  POST_OWNERSHIP_EXTENSION_SUCCESS: "POST_OWNERSHIP_EXTENSION_SUCCESS",
  POST_OWNERSHIP_EXTENSION_FAILED: "POST_OWNERSHIP_EXTENSION_FAILED",
  //get draft user
  GET_DRAFT_USER: "GET_DRAFT_USER",
  GET_DRAFT_USER_SUCCESS: "GET_DRAFT_USER_SUCCESS",
  GET_DRAFT_USER_FAILED: "GET_DRAFT_USER_FAILED",
  //get draft user
  UPDATE_RESERVATION: "UPDATE_RESERVATION",
  UPDATE_RESERVATION_SUCCESS: "UPDATE_RESERVATION_SUCCESS",
  UPDATE_RESERVATION_FAILED: "UPDATE_RESERVATION_FAILED",

  GET_SUBMISSION_EXTENSION: "GET_SUBMISSION_EXTENSION",
  GET_SUBMISSION_EXTENSION_SUCCESS: "GET_SUBMISSION_EXTENSION_SUCCESS",
  GET_SUBMISSION_EXTENSION_FAILED: "GET_SUBMISSION_EXTENSION_FAILED",

  POST_SUBMISSION_EXTENSION: "POST_SUBMISSION_EXTENSION",
  POST_SUBMISSION_EXTENSION_SUCCESS: "POST_SUBMISSION_EXTENSION_SUCCESS",
  POST_SUBMISSION_EXTENSION_FAILED: "POST_SUBMISSION_EXTENSION_FAILED",

  GET_LEAD_RENEWAL: "GET_LEAD_RENEWAL",
  GET_LEAD_RENEWAL_SUCCESS: "GET_LEAD_RENEWAL_SUCCESS",
  GET_LEAD_RENEWAL_FAILED: "GET_LEAD_RENEWAL_FAILED",

  POST_LEAD_RENEWAL: "POST_LEAD_RENEWAL",
  POST_LEAD_RENEWAL_SUCCESS: "POST_LEAD_RENEWAL_SUCCESS",
  POST_LEAD_RENEWAL_FAILED: "POST_LEAD_RENEWAL_FAILED",

  UPDATE_CSB_TO_SAP: "UPDATE_CSB_TO_SAP",
  UPDATE_CSB_TO_SAP_SUCCESS: "UPDATE_CSB_TO_SAP_SUCCESS",
  UPDATE_CSB_TO_SAP_FAILED: "UPDATE_CSB_TO_SAP_FAILED",

  GET_RESERVATION: "GET_RESERVATION",
  GET_RESERVATION_SUCCESS: "GET_RESERVATION_SUCCESS",
  GET_RESERVATION_FAILED: "GET_RESERVATION_FAILED",

  //sellers audit trail
  GET_SELLERS_AUDIT_TRAIL_REQUEST: "GET_SELLERS_AUDIT_TRAIL_REQUEST",
  GET_SELLERS_AUDIT_TRAIL_REQUEST_SUCCESS:
    "GET_SELLERS_AUDIT_TRAIL_REQUEST_SUCCESS",
  GET_SELLERS_AUDIT_TRAIL_REQUEST_FAILED:
    "GET_SELLERS_AUDIT_TRAIL_REQUEST_FAILED",

  DOWNLOADING_CSV: "DOWNLOADING_CSV",
  DOWNLOADING_CSV_SUCCESS: "DOWNLOADING_CSV_SUCCESS",
  DOWNLOADING_CSV_FAILED: "DOWNLOADING_CSV_FAILED",

  FILTER_CONTRACT_QUOTA: "FILTER_CONTRACT_QUOTA",
  FILTER_CONTRACT_QUOTA_SUCCESS: "FILTER_CONTRACT_QUOTA_SUCCESS",
  FILTER_CONTRACT_QUOTA_FAILED: "FILTER_CONTRACT_QUOTA_FAILED",

  RESET_CSV: "RESET_CSV",
};

// Action creators
export const actionCreators = {
  //get contract quota
  getContractQuota: (params) => ({
    type: actionTypes.GET_CONTRACT_QUOTA,
    params,
  }),
  getFilterContractQuota: (params) => ({
    type: actionTypes.FILTER_CONTRACT_QUOTA,
    params,
  }),

  getYtd: (params) => ({
    type: actionTypes.GET_YTD,
    params,
  }),

  // update / delete knowledge center folder
  updateKnowledgeCenterFolder: (params) => ({
    type: actionTypes.UPDATE_KNOWLEDGE_CENTER_FOLDER,
    params,
  }),
  // get knowledge center folder
  getKnowledgeCenterFolder: () => ({
    type: actionTypes.GET_KNOWLEDGE_CENTER_FOLDER,
  }),
  // getProperties: params => ({
  //   type: actionTypes.GET_PROPERTIES,
  //   params
  // }),
  getReservation: (params) => ({
    type: actionTypes.GET_RESERVATION,
    params,
  }),
  updateReservation: (params) => ({
    type: actionTypes.UPDATE_RESERVATION,
    params,
  }),
  updateProjectInfo: (params) => ({
    type: actionTypes.UPDATE_PROPERTY_INFO_SELLERS,
    params,
  }),
  // getBrands: params => ({
  //   type: actionTypes.GET_BRANDS,
  //   params
  // }),
  getSubmittedReservation: (params) => ({
    type: actionTypes.GET_SUBMITTED_RESERVATION,
    params,
  }),
  getCisInformation: (params) => ({
    type: actionTypes.GET_CIS_INFORMATION,
    params,
  }),
  getAttachment: (params) => ({
    type: actionTypes.GET_CIS_ATTACHMENT,
    params,
  }),
  getClientPurchase: (params) => ({
    type: actionTypes.GET_CLIENT_SURVEY,
    params,
  }),
  getClientSignature: (params) => ({
    type: actionTypes.GET_CLIENT_SIGNATURE,
    params,
  }),
  postReservationStatus: (params) => ({
    type: actionTypes.POST_RESERVATION_STATUS,
    params,
  }),
  getTransferRequest: () => ({
    type: actionTypes.GET_TRANSFER_REQUEST,
  }),
  postTransferRequest: (params) => ({
    type: actionTypes.POST_TRANSFER_REQUEST,
    params,
  }),
  getIncentive: () => ({
    type: actionTypes.GET_INCENTIVE,
  }),
  postIncentive: (params) => ({
    type: actionTypes.POST_INCENTIVE,
    params,
  }),
  deleteIncentive: (params) => ({
    type: actionTypes.DELETE_INCENTIVE,
    params,
  }),
  getKnowledgeCenter: () => ({
    type: actionTypes.GET_KNOWLEDGE_CENTER,
  }),
  postKnowledgeCenter: (params) => ({
    type: actionTypes.POST_KNOWLEDGE_CENTER,
    params,
  }),
  deleteKnowledgeCenter: (params) => ({
    type: actionTypes.DELETE_KNOWLEDGE_CENTER,
    params,
  }),
  getRetention: () => ({
    type: actionTypes.GET_RETENTION,
  }),
  postRetention: (params) => ({
    type: actionTypes.POST_RETENTION,
    params,
  }),
  getMarketingMultilevel: () => ({
    type: actionTypes.GET_MARKETING_PARTNER_MULTILEVEL,
  }),
  postMarketingMultilevel: (params) => ({
    type: actionTypes.POST_MARKETING_PARTNER_MULTILEVEL,
    params,
  }),
  getMarketingPartner: () => ({
    type: actionTypes.GET_MARKETING_PARTNER,
  }),
  postMarketingPartner: (params) => ({
    type: actionTypes.POST_MARKETING_PARTNER,
    params,
  }),
  postMarketingPartnerToSap: (params) => ({
    type: actionTypes.POST_MARKETING_PARTNER_TO_SAP,
    params,
  }),
  getSalesGroup: () => ({
    type: actionTypes.GET_SALES_GROUP,
  }),
  getOwnershipExtension: () => ({
    type: actionTypes.GET_OWNERSHIP_EXTENSION,
  }),
  postOwnershipExtension: (params) => ({
    type: actionTypes.POST_OWNERSHIP_EXTENSION,
    params,
  }),
  getDraftUser: (params) => ({
    type: actionTypes.GET_DRAFT_USER,
    params,
  }),
  getSubmissionExtension: () => ({
    type: actionTypes.GET_SUBMISSION_EXTENSION,
  }),
  postSubmissionExtension: (params) => ({
    type: actionTypes.POST_SUBMISSION_EXTENSION,
    params,
  }),
  getLeadRenewal: () => ({
    type: actionTypes.GET_LEAD_RENEWAL,
  }),
  postLeadRenewal: (params) => ({
    type: actionTypes.POST_LEAD_RENEWAL,
    params,
  }),
  updateCSBToSap: (params) => ({
    type: actionTypes.UPDATE_CSB_TO_SAP,
    params,
  }),
  //sellers audit trail
  getSellersAuditTrails: (params) => ({
    type: actionTypes.GET_SELLERS_AUDIT_TRAIL_REQUEST,
    params,
  }),
  downloadCsvContractQuota: (params) => ({
    type: actionTypes.DOWNLOADING_CSV,
    params,
  }),
  resetCsvData: (params) => ({
    type: actionTypes.RESET_CSV,
    params,
  }),
  // filterContractQuota: (params) => ({
  //   type: actionTypes.FILTER_CONTRACT_QUOTA,
  //   params,
  // }),
};

// Reducer
const initialState = {
  doneUpdating: false,
  isGettingEbooking: false,
  //contract quota
  isGettingQuota: false,
  contractQuota: [],
  // ytd
  isGettingYTD: false,
  ytdData: [],

  // knowledge center folder
  knowledgeCenterFolder: [],
  isGettingFolder: false,

  reservationData: [],
  isGettingReservation: false,
  data: [],
  buildings: [],
  units: [],
  meta: null,
  isLoading: false,
  error: null,
  // brands: [],
  reservedList: [],
  cisDetails: [],
  cisAttachments: [],
  surveyData: null,
  clientSignatures: [],
  approveStatus: false,
  transferRequest: [],
  incentive: [],
  knowledgeCenter: [],
  retention: [],
  marketingMultilevel: [],
  marketingPartners: [],
  marketingPartnersToSap: [],
  isPostingMarketingPartnersToSap: false,
  isLoadingMarketingMultilevel: false,
  isLoadingMarketingMultilevelPost: false,
  isLoadingMarketingPartners: false,
  salesGroup: [],
  isLoadingGetKnowledgeCenter: false,
  isLoadingGetIncentive: false,
  ownershipExtension: [],
  //draft user
  draftUserResult: [],
  updateReservationResult: [],
  isUpdatingReservation: false,
  isLoadingOwnershipExtension: false,
  isTransferRequestLoading: false,
  submissionExtension: [],
  isLoadingSubmissionExtension: false,
  leadRenewal: [],
  isLoadingLeadRenewal: false,

  updateCSBResult: [],
  //sellers audit trail
  sellersAuditTrail: [],
  isGettingAudit: false,
  isDownloadingCsv: false,
  csvData: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    //get contract quota
    case actionTypes.GET_CONTRACT_QUOTA:
      return Object.assign({}, state, {
        isGettingQuota: true,
        errors: null,
      });

    case actionTypes.GET_CONTRACT_QUOTA_SUCCESS:
      return Object.assign({}, state, {
        isGettingQuota: false,
        errors: null,
        contractQuota: action.payload,
      });

    case actionTypes.GET_CONTRACT_QUOTA_FAILED:
      return Object.assign({}, state, {
        isGettingQuota: false,
        errors: action.error,
        contractQuota: [],
      });

    case actionTypes.FILTER_CONTRACT_QUOTA:
      return Object.assign({}, state, {
        isGettingQuota: true,
        error: null,
        contractQuota: [],
      });

    case actionTypes.FILTER_CONTRACT_QUOTA_SUCCESS:
      return Object.assign({}, state, {
        isGettingQuota: false,
        error: null,
        contractQuota: action.payload,
      });

    case actionTypes.FILTER_CONTRACT_QUOTA_FAILED:
      return Object.assign({}, state, {
        isGettingQuota: false,
        error: action.error,
      });

    // ytd
    case actionTypes.GET_YTD:
      return Object.assign({}, state, {
        isGettingYTD: true,
        errors: null,
      });

    case actionTypes.GET_YTD_SUCCESS:
      return Object.assign({}, state, {
        isGettingYTD: false,
        errors: null,
        ytdData: action.payload,
      });

    case actionTypes.GET_YTD_FAILED:
      return Object.assign({}, state, {
        isGettingYTD: false,
        errors: action.error,
        ytdData: [],
      });

    //get sellers audit trail
    case actionTypes.GET_SELLERS_AUDIT_TRAIL_REQUEST:
      return Object.assign({}, state, {
        isGettingAudit: true,
        errors: null,
      });

    case actionTypes.GET_SELLERS_AUDIT_TRAIL_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isGettingAudit: false,
        errors: null,
        sellersAuditTrail: action.payload,
      });

    case actionTypes.GET_SELLERS_AUDIT_TRAIL_REQUEST_FAILED:
      return Object.assign({}, state, {
        isGettingAudit: false,
        errors: action.error,
      });
    //get reservation
    case actionTypes.GET_RESERVATION:
      return Object.assign({}, state, {
        error: null,
        isGettingReservation: true,
        reservationData: [],
      });
    case actionTypes.GET_RESERVATION_SUCCESS:
      return Object.assign({}, state, {
        error: null,
        isGettingReservation: false,
        reservationData: action.payload,
      });
    case actionTypes.GET_RESERVATION_FAILED:
      return Object.assign({}, state, {
        error: null,
        isGettingReservation: false,
        reservationData: [],
      });
    //update spouse and cobuyer
    case actionTypes.UPDATE_CSB_TO_SAP:
      return Object.assign({}, state, {
        error: null,
        updateCSBResult: [],
      });
    case actionTypes.UPDATE_CSB_TO_SAP_SUCCESS:
      return Object.assign({}, state, {
        error: null,
        updateCSBResult: action.payload,
      });
    case actionTypes.UPDATE_CSB_TO_SAP_FAILED:
      return Object.assign({}, state, {
        error: null,
        updateCSBResult: [],
      });
    //update reservation
    case actionTypes.UPDATE_RESERVATION:
      return Object.assign({}, state, {
        error: null,
        isUpdatingReservation: true,
        updateReservationResult: [],
        doneUpdating: false,
      });
    case actionTypes.UPDATE_RESERVATION_SUCCESS:
      return Object.assign({}, state, {
        error: null,
        isUpdatingReservation: false,
        updateReservationResult: action.payload,
        doneUpdating: true,
      });
    case actionTypes.UPDATE_RESERVATION_FAILED:
      return Object.assign({}, state, {
        error: null,
        updateReservationResult: [],
        isUpdatingReservation: false,
        doneUpdating: false,
      });
    //get draft user
    case actionTypes.GET_DRAFT_USER:
      return Object.assign({}, state, {
        error: null,
        draftUserResult: [],
      });
    case actionTypes.GET_DRAFT_USER_SUCCESS:
      return Object.assign({}, state, {
        error: null,
        draftUserResult: action.payload,
      });
    case actionTypes.GET_DRAFT_USER_FAILED:
      return Object.assign({}, state, {
        error: action.error,
      });

    case actionTypes.POST_MARKETING_PARTNER:
      return Object.assign({}, state, {
        isLoadingMarketingPartners: true,
        error: null,
      });

    case actionTypes.POST_MARKETING_PARTNER_SUCCESS:
      return Object.assign({}, state, {
        isLoadingMarketingPartners: false,
        marketingPartners: state.marketingPartners.map((partner) =>
          partner.id === action.id
            ? {
                ...partner,
                status: action.payload.status,
                approval: [...action.payload.approval],
              }
            : partner
        ),
        error: null,
      });

    case actionTypes.POST_MARKETING_PARTNER_FAILED:
      return Object.assign({}, state, {
        isLoadingMarketingPartners: false,
        error: action.error,
      });
    //sap
    case actionTypes.POST_MARKETING_PARTNER_TO_SAP:
      return Object.assign({}, state, {
        error: null,
        marketingPartnersToSap: [],
        isPostingMarketingPartnersToSap: true,
      });

    case actionTypes.POST_MARKETING_PARTNER_TO_SAP_SUCCESS:
      return Object.assign({}, state, {
        marketingPartnersToSap: action.payload,
        error: null,
        isPostingMarketingPartnersToSap: false,
      });

    case actionTypes.POST_MARKETING_PARTNER_TO_SAP_FAILED:
      return Object.assign({}, state, {
        error: action.error,
        isPostingMarketingPartnersToSap: false,
      });

    //
    case actionTypes.GET_MARKETING_PARTNER:
      return Object.assign({}, state, {
        isLoadingMarketingPartners: true,
        error: null,
      });

    case actionTypes.GET_MARKETING_PARTNER_SUCCESS:
      return Object.assign({}, state, {
        isLoadingMarketingPartners: false,
        error: null,
        marketingPartners: action.payload ? action.payload : [],
      });

    case actionTypes.GET_MARKETING_PARTNER_FAILED:
      return Object.assign({}, state, {
        isLoadingMarketingPartners: false,
        error: action.error,
        retention: [],
      });

    case actionTypes.POST_MARKETING_PARTNER_MULTILEVEL:
      return Object.assign({}, state, {
        isLoadingMarketingMultilevelPost: true,
        error: null,
      });

    case actionTypes.POST_MARKETING_PARTNER_MULTILEVEL_SUCCESS:
      return Object.assign({}, state, {
        isLoadingMarketingMultilevelPost: false,
        marketingMultilevel: state.marketingMultilevel.map((approver) =>
          approver.id === action.payload.id
            ? {
                ...approver,
                approvers: [...action.payload.approvers],
                sales_group: [...action.payload.sales_group],
              }
            : approver
        ),
        error: null,
      });

    case actionTypes.POST_MARKETING_PARTNER_MULTILEVEL_FAILED:
      return Object.assign({}, state, {
        isLoadingMarketingMultilevelPost: false,
        error: action.error,
      });

    case actionTypes.GET_MARKETING_PARTNER_MULTILEVEL:
      return Object.assign({}, state, {
        isLoadingMarketingMultilevel: true,
        error: null,
      });

    case actionTypes.GET_MARKETING_PARTNER_MULTILEVEL_SUCCESS:
      return Object.assign({}, state, {
        isLoadingMarketingMultilevel: false,
        error: null,
        marketingMultilevel: action.payload ? action.payload : [],
      });

    case actionTypes.GET_MARKETING_PARTNER_MULTILEVEL_FAILED:
      return Object.assign({}, state, {
        isLoadingMarketingMultilevel: false,
        error: action.error,
        retention: [],
      });

    case actionTypes.POST_RETENTION:
      return Object.assign({}, state, {
        isLoading: true,
        error: null,
      });

    case actionTypes.POST_RETENTION_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
      });

    case actionTypes.POST_RETENTION_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
      });

    case actionTypes.GET_RETENTION:
      return Object.assign({}, state, {
        isLoading: true,
        error: null,
      });

    case actionTypes.GET_RETENTION_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        retention: action.payload ? action.payload : [],
      });

    case actionTypes.GET_RETENTION_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
        retention: [],
      });

    case actionTypes.DELETE_KNOWLEDGE_CENTER:
      return Object.assign({}, state, {
        isLoading: true,
        error: null,
      });

    case actionTypes.DELETE_KNOWLEDGE_CENTER_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        knowledgeCenterFolder: action.payload,

        // transferRequest: action.payload
      });

    case actionTypes.DELETE_KNOWLEDGE_CENTER_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
      });

    case actionTypes.POST_KNOWLEDGE_CENTER:
      return Object.assign({}, state, {
        isLoading: true,
        error: null,
      });

    case actionTypes.POST_KNOWLEDGE_CENTER_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
      });

    case actionTypes.POST_KNOWLEDGE_CENTER_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
      });
    // UPDATE / DELETE folder
    case actionTypes.UPDATE_KNOWLEDGE_CENTER_FOLDER:
      return Object.assign({}, state, {
        isGettingFolder: true,
        error: null,
      });

    case actionTypes.UPDATE_KNOWLEDGE_CENTER_FOLDER_SUCCESS:
      return Object.assign({}, state, {
        isGettingFolder: false,
        error: null,
        // knowledgeCenterFolder: action.payload ? action.payload : [],
      });

    case actionTypes.UPDATE_KNOWLEDGE_CENTER_FOLDER_FAILED:
      return Object.assign({}, state, {
        isGettingFolder: false,
        error: action.error,
        // knowledgeCenterFolder: [],
      });
    //
    // folder
    case actionTypes.GET_KNOWLEDGE_CENTER_FOLDER:
      return Object.assign({}, state, {
        isGettingFolder: true,
        error: null,
      });

    case actionTypes.GET_KNOWLEDGE_CENTER_FOLDER_SUCCESS:
      return Object.assign({}, state, {
        isGettingFolder: false,
        error: null,
        knowledgeCenterFolder: action.payload ? action.payload : [],
      });

    case actionTypes.GET_KNOWLEDGE_CENTER_FOLDER_FAILED:
      return Object.assign({}, state, {
        isGettingFolder: false,
        error: action.error,
        knowledgeCenterFolder: [],
      });
    //
    case actionTypes.GET_KNOWLEDGE_CENTER:
      return Object.assign({}, state, {
        isLoadingGetKnowledgeCenter: true,
        error: null,
      });

    case actionTypes.GET_KNOWLEDGE_CENTER_SUCCESS:
      return Object.assign({}, state, {
        isLoadingGetKnowledgeCenter: false,
        error: null,
        knowledgeCenter: action.payload ? action.payload : [],
      });

    case actionTypes.GET_KNOWLEDGE_CENTER_FAILED:
      return Object.assign({}, state, {
        isLoadingGetKnowledgeCenter: false,
        error: action.error,
        knowledgeCenter: [],
      });

    case actionTypes.DELETE_INCENTIVE:
      return Object.assign({}, state, {
        isLoading: true,
        error: null,
      });

    case actionTypes.DELETE_INCENTIVE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        // transferRequest: action.payload
      });

    case actionTypes.DELETE_INCENTIVE_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
      });

    case actionTypes.POST_INCENTIVE:
      return Object.assign({}, state, {
        isLoading: true,
        error: null,
      });

    case actionTypes.POST_INCENTIVE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        // transferRequest: action.payload
      });

    case actionTypes.POST_INCENTIVE_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
      });

    case actionTypes.GET_INCENTIVE:
      return Object.assign({}, state, {
        isLoadingGetIncentive: true,
        error: null,
        // transferRequest: []
      });

    case actionTypes.GET_INCENTIVE_SUCCESS:
      return Object.assign({}, state, {
        isLoadingGetIncentive: false,
        error: null,
        incentive: action.payload ? action.payload : [],
      });

    case actionTypes.GET_INCENTIVE_FAILED:
      return Object.assign({}, state, {
        isLoadingGetIncentive: false,
        error: action.error,
        incentive: [],
      });

    case actionTypes.POST_TRANSFER_REQUEST:
      return Object.assign({}, state, {
        isTransferRequestLoading: true,
        error: null,
      });

    case actionTypes.POST_TRANSFER_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isTransferRequestLoading: false,
        error: null,
        // transferRequest: action.payload
      });

    case actionTypes.POST_TRANSFER_REQUEST_FAILED:
      return Object.assign({}, state, {
        isTransferRequestLoading: false,
        error: action.error,
      });

    case actionTypes.GET_TRANSFER_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        error: null,
        transferRequest: [],
      });

    case actionTypes.GET_TRANSFER_REQUEST_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        transferRequest: action.payload ? action.payload : [],
      });

    case actionTypes.GET_TRANSFER_REQUEST_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
        transferRequest: [],
      });

    case actionTypes.POST_RESERVATION_STATUS:
      return Object.assign({}, state, {
        isLoading: true,
        error: null,
        approveStatus: false,
      });

    case actionTypes.POST_RESERVATION_STATUS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        approveStatus: true,
      });

    case actionTypes.POST_RESERVATION_STATUS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
        approveStatus: false,
      });

    case actionTypes.GET_CLIENT_SIGNATURE:
      return Object.assign({}, state, {
        isLoading: true,
        error: null,
        clientSignatures: [],
      });

    case actionTypes.GET_CLIENT_SIGNATURE_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        clientSignatures: action.payload,
      });

    case actionTypes.GET_CLIENT_SIGNATURE_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
        clientSignatures: [],
      });

    case actionTypes.GET_CLIENT_SURVEY:
      return Object.assign({}, state, {
        isLoading: true,
        error: null,
        surveyData: null,
      });

    case actionTypes.GET_CLIENT_SURVEY_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        surveyData: action.payload,
      });

    case actionTypes.GET_CLIENT_SURVEY_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
        surveyData: null,
      });

    case actionTypes.GET_CIS_ATTACHMENT:
      return Object.assign({}, state, {
        isLoading: true,
        error: null,
        cisAttachments: [],
      });

    case actionTypes.GET_CIS_ATTACHMENT_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        cisAttachments: action.payload,
      });

    case actionTypes.GET_CIS_ATTACHMENT_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
        cisAttachments: [],
      });

    case actionTypes.GET_CIS_INFORMATION:
      return Object.assign({}, state, {
        isLoading: true,
        error: null,
        cisDetails: [],
      });

    case actionTypes.GET_CIS_INFORMATION_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        cisDetails: action.payload,
      });

    case actionTypes.GET_CIS_INFORMATION_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
        cisDetails: [],
      });

    case actionTypes.GET_SUBMITTED_RESERVATION:
      return Object.assign({}, state, {
        isGettingEbooking: true,
        error: null,
        reservedList: [],
      });

    case actionTypes.GET_SUBMITTED_RESERVATION_SUCCESS:
      return Object.assign({}, state, {
        isGettingEbooking: false,
        error: null,
        reservedList: action.payload,
      });

    case actionTypes.GET_SUBMITTED_RESERVATION_FAILED:
      return Object.assign({}, state, {
        isGettingEbooking: false,
        error: action.error,
        reservedList: [],
      });

    // case actionTypes.GET_PROPERTIES:
    //   return Object.assign({}, state, {
    //     isLoading: true,
    //     error: null,
    //     data: []
    //   });

    // case actionTypes.GET_PROPERTIES_SUCCESS:
    //   return Object.assign({}, state, {
    //     isLoading: false,
    //     error: null,
    //     data: action.payload
    //   });

    case actionTypes.UPDATE_PROPERTY_INFO_SELLERS:
      return Object.assign({}, state, {
        isLoading: true,
        error: null,
      });

    case actionTypes.UPDATE_PROPERTY_INFO_SELLERS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        data: state.data.map((item) => {
          if (item.id === action.payload.id) {
            return action.payload;
          }

          return item;
        }),
      });

    case actionTypes.UPDATE_PROPERTY_INFO_SELLERS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
      });

    // case actionTypes.GET_PROPERTIES_FAILED:
    //   return Object.assign({}, state, {
    //     isLoading: false,
    //     error: action.error
    //   });

    // case actionTypes.GET_BRANDS:
    //   return Object.assign({}, state, {
    //     error: null,
    //     brands: []
    //   });

    // case actionTypes.GET_BRANDS_SUCCESS:
    //   return Object.assign({}, state, {
    //     error: null,
    //     brands: action.payload
    //   });

    // case actionTypes.GET_BRANDS_FAILED:
    //   return Object.assign({}, state, {
    //     error: action.error
    //   });

    case actionTypes.GET_SALES_GROUP:
      return Object.assign({}, state, {
        isLoading: true,
        error: null,
        salesGroup: [],
      });

    case actionTypes.GET_SALES_GROUP_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        error: null,
        salesGroup: action.payload.sales_group,
      });

    case actionTypes.GET_SALES_GROUP_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        error: action.error,
      });

    case actionTypes.GET_OWNERSHIP_EXTENSION:
      return Object.assign({}, state, {
        isLoadingOwnershipExtension: true,
        error: null,
        ownershipExtension: [],
      });

    case actionTypes.GET_OWNERSHIP_EXTENSION_SUCCESS:
      return Object.assign({}, state, {
        isLoadingOwnershipExtension: false,
        error: null,
        ownershipExtension: action.payload,
      });

    case actionTypes.GET_OWNERSHIP_EXTENSION_FAILED:
      return Object.assign({}, state, {
        isLoadingOwnershipExtension: false,
        error: action.error,
      });

    case actionTypes.POST_OWNERSHIP_EXTENSION:
      return Object.assign({}, state, {
        isLoadingOwnershipExtension: true,
        error: null,
      });

    case actionTypes.POST_OWNERSHIP_EXTENSION_SUCCESS:
      return Object.assign({}, state, {
        isLoadingOwnershipExtension: false,
        error: null,
        ownershipExtension: state.ownershipExtension.map((item) =>
          item.id === action.payload.id
            ? {
                ...item,
                status: action.payload.status,
                ownership_expiration: action.payload.ownership_expiration,
                approver_id: action.payload.approver_id,
                disapproval_date: action.payload.disapproval_date,
                disapproval_attachment: action.payload.disapproval_attachment,
                disapproval_reason: action.payload.disapproval_reason,
                approver: action.payload.approver,
              }
            : item
        ),
      });

    case actionTypes.POST_OWNERSHIP_EXTENSION_FAILED:
      return Object.assign({}, state, {
        isLoadingOwnershipExtension: false,
        error: action.error,
      });

    case actionTypes.GET_SUBMISSION_EXTENSION:
      return Object.assign({}, state, {
        isLoadingSubmissionExtension: true,
        error: null,
        submissionExtension: [],
      });

    case actionTypes.GET_SUBMISSION_EXTENSION_SUCCESS:
      return Object.assign({}, state, {
        isLoadingSubmissionExtension: false,
        error: null,
        submissionExtension: action.payload,
      });

    case actionTypes.GET_SUBMISSION_EXTENSION_FAILED:
      return Object.assign({}, state, {
        isLoadingSubmissionExtension: false,
        error: action.error,
      });

    case actionTypes.POST_SUBMISSION_EXTENSION:
      return Object.assign({}, state, {
        isLoadingSubmissionExtension: true,
        error: null,
      });

    case actionTypes.POST_SUBMISSION_EXTENSION_SUCCESS:
      return Object.assign({}, state, {
        isLoadingSubmissionExtension: false,
        error: null,
        submissionExtension: state.submissionExtension.map((item) =>
          item.id === action.payload.id
            ? {
                ...item,
                status: action.payload.status,
                submission_expiration: action.payload.submission_expiration,
                approver_id: action.payload.approver_id,
                disapproval_date: action.payload.disapproval_date,
                disapproval_attachment: action.payload.disapproval_attachment,
                disapproval_reason: action.payload.disapproval_reason,
                approver: action.payload.approver,
              }
            : item
        ),
      });

    case actionTypes.POST_SUBMISSION_EXTENSION_FAILED:
      return Object.assign({}, state, {
        isLoadingSubmissionExtension: false,
        error: action.error,
      });

    case actionTypes.GET_LEAD_RENEWAL:
      return Object.assign({}, state, {
        isLoadingLeadRenewal: true,
        error: null,
        leadRenewal: [],
      });

    case actionTypes.GET_LEAD_RENEWAL_SUCCESS:
      return Object.assign({}, state, {
        isLoadingLeadRenewal: false,
        error: null,
        leadRenewal: action.payload,
      });

    case actionTypes.GET_LEAD_RENEWAL_FAILED:
      return Object.assign({}, state, {
        isLoadingLeadRenewal: false,
        error: action.error,
      });

    case actionTypes.POST_LEAD_RENEWAL:
      return Object.assign({}, state, {
        isLoadingLeadRenewal: true,
        error: null,
      });

    case actionTypes.POST_LEAD_RENEWAL_SUCCESS:
      return Object.assign({}, state, {
        isLoadingLeadRenewal: false,
        error: null,
        leadRenewal: state.leadRenewal.map((item) =>
          item.id === action.payload.id
            ? {
                ...item,
                status: action.payload.status,
                current_expiry_date: action.payload.current_expiry_date,
                approver_id: action.payload.approver_id,
                disapproval_date: action.payload.disapproval_date,
                disapproval_attachment: action.payload.disapproval_attachment,
                disapproval_reason: action.payload.disapproval_reason,
                approver: action.payload.approver,
              }
            : item
        ),
      });

    case actionTypes.POST_LEAD_RENEWAL_FAILED:
      return Object.assign({}, state, {
        isLoadingLeadRenewal: false,
        error: action.error,
      });

    case actionTypes.DOWNLOADING_CSV:
      return Object.assign({}, state, {
        isDownloadingCsv: true,
        error: null,
        csvData: [],
      });

    case actionTypes.DOWNLOADING_CSV_SUCCESS:
      return Object.assign({}, state, {
        isDownloadingCsv: false,
        error: null,
        csvData: action.payload,
      });

    case actionTypes.DOWNLOADING_CSV_FAILED:
      return Object.assign({}, state, {
        isDownloadingCsv: false,
        error: action.error,
      });

    case actionTypes.RESET_CSV:
      return Object.assign({}, state, {
        // isDownloadingCsv: true,
        error: null,
        csvData: [],
      });

    default:
      return state;
  }
};
