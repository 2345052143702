import request, { downloadFile } from "../../../../utils/api";

export const getWebNewsletterSubscriptions = payload => {
  return request(`v1/cms/subscribe`, {
    payload
  })
}

export const deleteWebNewsletterSubscriptions = payload => {
  return request(`v1/cms/subscribe/` + payload.id, {
    method: "delete",
    body: payload
  })
}

export const postRestoreWebNewsletterSubscriptions = payload => {
  return request(`v1/cms/restore-web-newsletter-subscriptions/${payload.id}`, {
    method: "post",
    body: payload
  })
}

export const getSoftDeletedWebNewsletterSubscriptions = () => {
  return request('v1/cms/trashed-web-newsletter-subscriptions', {
    method: "get"
  });
}

export const postExportWebNewsletterSubscriptions = payload => {
  return downloadFile(
    `v1/cms/export-web-newsletter-subscriptions/${payload.brand_id}`,
    payload,
    "export-web-newsletter-subscriptions.xlsx"
  );
}
