export const actionTypes = {
	GET_WEB_NEWSLETTER_SUBSCRIPTIONS_REQUEST: "GET_WEB_NEWSLETTER_SUBSCRIPTIONS_REQUEST",
	GET_WEB_NEWSLETTER_SUBSCRIPTIONS_SUCCESS: "GET_WEB_NEWSLETTER_SUBSCRIPTIONS_SUCCESS",
	GET_WEB_NEWSLETTER_SUBSCRIPTIONS_FAILED: "GET_WEB_NEWSLETTER_SUBSCRIPTIONS_FAILED",

	DELETE_WEB_NEWSLETTER_SUBSCRIPTIONS_REQUEST: "DELETE_WEB_NEWSLETTER_SUBSCRIPTIONS_REQUEST",
	DELETE_WEB_NEWSLETTER_SUBSCRIPTIONS_SUCCESS: "DELETE_WEB_NEWSLETTER_SUBSCRIPTIONS_SUCCESS",
	DELETE_WEB_NEWSLETTER_SUBSCRIPTIONS_FAILED: "DELETE_WEB_NEWSLETTER_SUBSCRIPTIONS_FAILED",

	POST_EXPORT_WEB_NEWSLETTER_SUBSCRIPTIONS_REQUEST: "POST_EXPORT_WEB_NEWSLETTER_SUBSCRIPTIONS_REQUEST",
	POST_EXPORT_WEB_NEWSLETTER_SUBSCRIPTIONS_SUCCESS: "POST_EXPORT_WEB_NEWSLETTER_SUBSCRIPTIONS_SUCCESS",
	POST_EXPORT_WEB_NEWSLETTER_SUBSCRIPTIONS_FAILED: "POST_EXPORT_WEB_NEWSLETTER_SUBSCRIPTIONS_FAILED",

	POST_RESTORE_WEB_NEWSLETTER_SUBSCRIPTIONS_REQUEST: "POST_RESTORE_WEB_NEWSLETTER_SUBSCRIPTIONS_REQUEST",
	POST_RESTORE_WEB_NEWSLETTER_SUBSCRIPTIONS_SUCCESS: "POST_RESTORE_WEB_NEWSLETTER_SUBSCRIPTIONS_SUCCESS",
	POST_RESTORE_WEB_NEWSLETTER_SUBSCRIPTIONS_FAILED: "POST_RESTORE_WEB_NEWSLETTER_SUBSCRIPTIONS_FAILED",

	GET_SOFT_DELETED_WEB_NEWSLETTER_SUBSCRIPTIONS_REQUEST: "GET_SOFT_DELETED_WEB_NEWSLETTER_SUBSCRIPTIONS_REQUEST",
	GET_SOFT_DELETED_WEB_NEWSLETTER_SUBSCRIPTIONS_SUCCESS: "GET_SOFT_DELETED_WEB_NEWSLETTER_SUBSCRIPTIONS_SUCCESS",
	GET_SOFT_DELETED_WEB_NEWSLETTER_SUBSCRIPTIONS_FAILED: "GET_SOFT_DELETED_WEB_NEWSLETTER_SUBSCRIPTIONS_FAILED",

}

export const actionCreators = {
	getWebNewsletterSubscriptions: params => ({
		type: actionTypes.GET_WEB_NEWSLETTER_SUBSCRIPTIONS_REQUEST,
		params
	}),
	deleteWebNewsletterSubscriptions: params => ({
		type: actionTypes.DELETE_WEB_NEWSLETTER_SUBSCRIPTIONS_REQUEST,
		params
	}),
	postExportWebNewsletterSubscriptions: params => ({
		type: actionTypes.POST_EXPORT_WEB_NEWSLETTER_SUBSCRIPTIONS_REQUEST,
		params
	}),
	postRestoreWebNewsletterSubscriptions: params => ({
		type: actionTypes.POST_RESTORE_WEB_NEWSLETTER_SUBSCRIPTIONS_REQUEST,
		params
	}),
	getSoftDeletedWebNewsletterSubscriptions: params => ({
		type: actionTypes.GET_SOFT_DELETED_WEB_NEWSLETTER_SUBSCRIPTIONS_REQUEST,
		params
	}),
}

export const initialState = {
	newsletterSubscriptions: []
}

// export default (state = initialState, action) => {
// 	switch (action.type) {

// 		// GET_WEB_NEWSLETTER_SUBSCRIPTIONS

// 		case actionTypes.GET_WEB_NEWSLETTER_SUBSCRIPTIONS_REQUEST:
// 			return Object.assign({}, state, {
// 				isLoading: true,
// 				errors: null
// 			});

// 		case actionTypes.GET_WEB_NEWSLETTER_SUBSCRIPTIONS_SUCCESS:
// 			return Object.assign({}, state, {
// 				isLoading: false,
// 				errors: null,
// 				newsletterSubscriptions: action.payload
// 			});

// 		case actionTypes.GET_WEB_NEWSLETTER_SUBSCRIPTIONS_FAILED:
// 			return Object.assign({}, state, {
// 				isLoading: false,
// 				errors: action.error
// 			});

// 		// DELETE_WEB_NEWSLETTER_SUBSCRIPTIONS

// 		case actionTypes.DELETE_WEB_NEWSLETTER_SUBSCRIPTIONS_REQUEST:
// 			return Object.assign({}, state, {
// 				isLoading: true,
// 				errors: null
// 			});

// 		case actionTypes.DELETE_WEB_NEWSLETTER_SUBSCRIPTIONS_SUCCESS:
// 			return Object.assign({}, state, {
// 				isLoading: false,
// 				errors: null,
// 				newsletterSubscriptions: action.payload
// 			});

// 		case actionTypes.DELETE_WEB_NEWSLETTER_SUBSCRIPTIONS_FAILED:
// 			return Object.assign({}, state, {
// 				isLoading: false,
// 				errors: action.error
// 			});

// 		// POST_EXPORT_WEB_NEWSLETTER_SUBSCRIPTIONS

// 		case actionTypes.POST_EXPORT_WEB_NEWSLETTER_SUBSCRIPTIONS_REQUEST:
// 			return Object.assign({}, state, {
// 				isLoading: true,
// 				errors: null
// 			});

// 		case actionTypes.POST_EXPORT_WEB_NEWSLETTER_SUBSCRIPTIONS_SUCCESS:
// 			return Object.assign({}, state, {
// 				isLoading: false,
// 				errors: null
// 			});

// 		case actionTypes.POST_EXPORT_WEB_NEWSLETTER_SUBSCRIPTIONS_FAILED:
// 			return Object.assign({}, state, {
// 				isLoading: false,
// 				errors: action.error
// 			});

// 		// POST_RESTORE_WEB_NEWSLETTER_SUBSCRIPTIONS

// 		case actionTypes.POST_RESTORE_WEB_NEWSLETTER_SUBSCRIPTIONS_REQUEST:
// 			return Object.assign({}, state, {
// 				isLoading: true,
// 				errors: null,
// 				newsletterSubscriptions: null
// 			});

// 		case actionTypes.POST_RESTORE_WEB_NEWSLETTER_SUBSCRIPTIONS_SUCCESS:
// 			return Object.assign({}, state, {
// 				isLoading: false,
// 				errors: null,
// 				newsletterSubscriptions: action.payload
// 			});

// 		case actionTypes.POST_RESTORE_WEB_NEWSLETTER_SUBSCRIPTIONS_FAILED:
// 			return Object.assign({}, state, {
// 				isLoading: false,
// 				errors: action.error
// 			});

// 		// GET_SOFT_DELETED_WEB_NEWSLETTER_SUBSCRIPTIONS

// 		case actionTypes.GET_SOFT_DELETED_WEB_NEWSLETTER_SUBSCRIPTIONS_REQUEST:
// 			return Object.assign({}, state, {
// 				isLoading: true,
// 				errors: null,
// 				webAppointments: null
// 			});

// 		case actionTypes.GET_SOFT_DELETED_WEB_NEWSLETTER_SUBSCRIPTIONS_SUCCESS:
// 			return Object.assign({}, state, {
// 				isLoading: false,
// 				errors: null,
// 				webAppointments: action.payload
// 			});

// 		case actionTypes.GET_SOFT_DELETED_WEB_NEWSLETTER_SUBSCRIPTIONS_FAILED:
// 			return Object.assign({}, state, {
// 				isLoading: false,
// 				errors: action.error,
// 				webAppointments: null
// 			});

// 		default:
// 			return state;
// 	}
// }