import Loadable from "react-loadable";
import LoadingComponent from "../../commons/loader";

export default [
  // {
  //   path: "/dashboard/brands",
  //   exact: true,
  //   authL: true,
  //   component: Loadable({
  //     loader: () => import("./pages/brands"),
  //     loading: LoadingComponent
  //   })
  // },
  // {
  //   path: "/dashboard/brands/:brand/projects",
  //   exact: true,
  //   authL: true,
  //   component: Loadable({
  //     loader: () => import("./pages/projects"),
  //     loading: LoadingComponent
  //   })
  // },
  // {
  //   path: "/dashboard/brands/:brand/projects/:projectId/basic-info",
  //   exact: true,
  //   authL: true,
  //   component: Loadable({
  //     loader: () => import("./pages/management/basic"),
  //     loading: LoadingComponent
  //   })
  // },
  // {
  //   path: "/dashboard/brands/:brand/projects/:projectId/project-overview",
  //   exact: true,
  //   authL: true,
  //   component: Loadable({
  //     loader: () => import("./pages/management/overview"),
  //     loading: LoadingComponent
  //   })
  // },
  // {
  //   path: "/dashboard/brands/:brand/projects/:projectId/locations",
  //   exact: true,
  //   authL: true,
  //   component: Loadable({
  //     loader: () => import("./pages/management/location"),
  //     loading: LoadingComponent
  //   })
  // },
  // {
  //   path: "/dashboard/brands/:brand/projects/:projectId/building-details",
  //   exact: true,
  //   authL: true,
  //   component: Loadable({
  //     loader: () => import("./pages/management/building-details"),
  //     loading: LoadingComponent
  //   })
  // },
  // {
  //   path: "/dashboard/brands/:brand/projects/:projectId/amenities",
  //   exact: true,
  //   authL: true,
  //   component: Loadable({
  //     loader: () => import("./pages/management/amenities"),
  //     loading: LoadingComponent
  //   })
  // },
  // {
  //   path: "/dashboard/brands/:brand/projects/:projectId/floor-plans",
  //   exact: true,
  //   authL: true,
  //   component: Loadable({
  //     loader: () => import("./pages/management/floor-plans"),
  //     loading: LoadingComponent
  //   })
  // },
  // {
  //   path: "/dashboard/brands/:brand/projects/:projectId/unit-details",
  //   exact: true,
  //   authL: true,
  //   component: Loadable({
  //     loader: () => import("./pages/management/unit-details"),
  //     loading: LoadingComponent
  //   })
  // },
  // {
  //   path:
  //     "/dashboard/brands/:brand/projects/:projectId/availability/unit-pages",
  //   exact: true,
  //   authL: true,
  //   component: Loadable({
  //     loader: () => import("./pages/management/unit-pages"),
  //     loading: LoadingComponent
  //   })
  // },
  {
    path: "/sellers-portal",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/sellers-portal/resources",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/resources/knowledge"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/sellers-portal/resources/incentive-master",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/resources/incentive-master"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/sellers-portal/lead-retention",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/lead-retention"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/sellers-portal/e-booking",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/dashboard/e-booking"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/sellers-portal/e-booking/:id",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () =>
        import("./pages/dashboard/e-booking/components/view-application"),
      loading: LoadingComponent,
    }),
  },
  // {
  //   path: "/sellers-portal/customer-360",
  //   exact: true,
  //   authL: true,
  //   component: Loadable({
  //     loader: () => import("./pages/dashboard/customer360"),
  //     loading: LoadingComponent
  //   })
  // },
  {
    path: "/sellers-portal/marketing-partners",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/dashboard/marketing-partners"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/sellers-portal/marketing-partners/:id",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () =>
        import("./pages/dashboard/marketing-partners/pages/details"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/sellers-portal/marketing-partners-approvers",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/dashboard/marketing-partners-approvers"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/sellers-portal/ownership-extension",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/dashboard/ownership-extension"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/sellers-portal/submission-extension",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/dashboard/submission-extension"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/sellers-portal/renewal-request",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/dashboard/renewal-request"),
      loading: LoadingComponent,
    }),
  },
  //audit trail
  {
    path: "/sellers-portal/audit-trail-reservation",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/dashboard/audit-trail"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/sellers-portal/audit-trail-ebooking",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/dashboard/audit-trail"),
      loading: LoadingComponent,
    }),
  },

  {
    path: "/sellers-portal/reservation-details/:rNo/:partnerId/:form",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () =>
        import("./pages/dashboard/customer360/pages/reservation-details"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/sellers-portal/audit-trail",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/audit-trail"),
      loading: LoadingComponent,
    }),
  },

  {
    path: "/sellers-portal/sellers-trail",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/sellers-trail"),
      loading: LoadingComponent,
    }),
  },
  // sales report
  {
    path: "/sellers-portal/current-contract-quota",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/current-contract-quota"),
      loading: LoadingComponent,
    }),
  },
  {
    path: "/sellers-portal/year-to-date-report",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/year-to-date-report"),
      loading: LoadingComponent,
    }),
  },
  //
  {
    path: "/sellers-portal/transfer-request",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/dashboard/transfer-request"),
      loading: LoadingComponent,
    }),
  },
];
