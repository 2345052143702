import React from "react";
import PropTypes from "prop-types";
import { Spin, Icon } from "antd";

// set display name for component
const displayName = "CommonLoader";

// validate component properties
const propTypes = {
  isLoading: PropTypes.bool,
  error: PropTypes.object
};

const antIcon = (
  <Icon type="loading" style={{ fontSize: "5em", color: "#D20F84" }} spin />
);

const LoadingComponent = ({ isLoading, error }) => {
  // Handle the loading state
  if (isLoading) {
    return (
      <div
        className="loading"
        style={{ width: "100%", height: "100%", textAlign: "center" }}
      >
        <Spin
          spinning={isLoading}
          indicator={antIcon}
          style={{ marginTop: "130px" }}
        />
      </div>
    );
  }
  // Handle the error state
  else if (error) {
    return <div>Sorry, there was a problem loading the page.</div>;
  } else {
    return null;
  }
};

LoadingComponent.displayName = displayName;
LoadingComponent.propTypes = propTypes;

export default LoadingComponent;
