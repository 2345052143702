import { all, takeLatest, call, put } from "redux-saga/effects";
import { actionTypes as propertiesActionTypes } from "./reducer";
import { actionTypes as cmsActionTypes } from "../../commons/ProjectCMSHOC/reducer";
import {
  getProperties,
  updateProjectInfo,
  getBrands,
  getAllActiveProperties,
  getManageContent as getManageContentService,
  updateManageContent as updateManageContentService,
} from "./service";
import processAction from "../../utils/processAction";

function* updateManageContent(action) {
  yield processAction({
    ...action,
    service: updateManageContentService,
    success: propertiesActionTypes.UPDATE_MANAGE_CONTENT_SUCCESS,
    failed: propertiesActionTypes.UPDATE_MANAGE_CONTENT_FAILED,
  });
}
function* getManageContent(action) {
  yield processAction({
    ...action,
    service: getManageContentService,
    success: propertiesActionTypes.GET_MANAGE_CONTENT_SUCCESS,
    failed: propertiesActionTypes.GET_MANAGE_CONTENT_FAILED,
  });
}
function* getAllProperties(action) {
  yield processAction({
    ...action,
    service: getProperties,
    success: propertiesActionTypes.GET_PROPERTIES_SUCCESS,
    failed: propertiesActionTypes.GET_PROPERTIES_FAILED,
  });
}

function* updateProperty(action) {
  const { params } = action;

  try {
    const payload = yield call(updateProjectInfo, params);
    yield put({
      type: propertiesActionTypes.UPDATE_PROPERTY_INFO_SUCCESS,
      payload,
    });

    yield put({
      type: cmsActionTypes.SELECTED_PROPERTY,
      property: payload,
    });
  } catch (error) {
    yield put({
      type: propertiesActionTypes.UPDATE_PROPERTY_INFO_FAILED,
      error,
    });
  }

  // yield processAction({
  //   ...action,
  //   service: updateProjectInfo,
  //   success: propertiesActionTypes.UPDATE_PROPERTY_INFO_SUCCESS,
  //   failed: propertiesActionTypes.UPDATE_PROPERTY_INFO_FAILED,
  // });
}

function* getAllBrands(action) {
  yield processAction({
    ...action,
    service: getBrands,
    success: propertiesActionTypes.GET_BRANDS_SUCCESS,
    failed: propertiesActionTypes.GET_BRANDS_FAILED,
  });
}

function* getProjectInfoProperties(action) {
  yield processAction({
    ...action,
    service: getAllActiveProperties,
    success: propertiesActionTypes.GET_PROJECT_INFO_PROPERTIES_SUCCESS,
    failed: propertiesActionTypes.GET_PROJECT_INFO_PROPERTIES_FAILED,
  });
}

function* watcher() {
  yield all([
    takeLatest(propertiesActionTypes.GET_PROPERTIES, getAllProperties),
    takeLatest(propertiesActionTypes.UPDATE_PROPERTY_INFO, updateProperty),
    takeLatest(propertiesActionTypes.GET_BRANDS, getAllBrands),
    takeLatest(
      propertiesActionTypes.UPDATE_MANAGE_CONTENT,
      updateManageContent
    ),
    takeLatest(propertiesActionTypes.GET_MANAGE_CONTENT, getManageContent),
    takeLatest(
      propertiesActionTypes.GET_PROJECT_INFO_PROPERTIES,
      getProjectInfoProperties
    ),
  ]);
}

export default [watcher()];
