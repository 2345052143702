import Loadable from "react-loadable";
import LoadingComponent from "../../commons/loader";

export default [
  {
    path: "/admin/user-role",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/user-role"),
      loading: LoadingComponent
    })
  },
  {
    path: "/admin/user-role/:userRoleId",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/user-role/form"),
      loading: LoadingComponent
    })
  },
  {
    path: "/admin/user-account",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/user-account"),
      loading: LoadingComponent
    })
  },
  {
    path: "/admin/user-account/:userId",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/user-account/form"),
      loading: LoadingComponent
    })
  },
  {
    path: "/admin/audit-trail",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/audit-trail"),
      loading: LoadingComponent
    })
  },
  {
    path: "/admin/unsubscribed-users",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/unsubscribed-users"),
      loading: LoadingComponent
    })
  },
  {
    path: "/admin/email-settings",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/email-settings"),
      loading: LoadingComponent
    })
  },
  {
    path: "/admin/email-settings/:emailSettingsId",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/email-settings/form"),
      loading: LoadingComponent
    })
  },
  {
    path: "/admin/internal-commands",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/internal-commands"),
      loading: LoadingComponent
    })
  }
];
