// Action Types
import { defaultWebContent } from "../../../src/utils/singleWebContent";
import {
  actionTypes as newsletterSubscriptionsActionTypes,
  actionCreators as newsletterSubscriptionsActionCreators,
  initialState as newsletterSubscriptionsInitialState,
} from "./pages/newsletter-subscriptions/reducer";
export const actionTypes = {
  ...newsletterSubscriptionsActionTypes,

  GET_ALL_PROPERTIES: "GET_ALL_PROPERTIES",
  GET_ALL_PROPERTIES_SUCCESS: "GET_ALL_PROPERTIES_SUCCESS",
  GET_ALL_PROPERTIES_FAILED: "GET_ALL_PROPERTIES_FAILED",

  CONTENT_WEB_DATA_REQUEST: "CONTENT_WEB_DATA_REQUEST",
  CONTENT_WEB_DATA_SUCCESS: "CONTENT_WEB_DATA_SUCCESS",
  CONTENT_WEB_DATA_FAILED: "CONTENT_WEB_DATA_FAILED",

  POST_WEB_DATA_REQUEST: "POST_WEB_DATA_REQUEST",
  POST_WEB_DATA_SUCCESS: "POST_WEB_DATA_SUCCESS",
  POST_WEB_DATA_FAILED: "POST_WEB_DATA_FAILED",

  GET_WEB_FAQ_CATEGORY_REQUEST: "GET_WEB_FAQ_CATEGORY_REQUEST",
  GET_WEB_FAQ_CATEGORY_SUCCESS: "GET_WEB_FAQ_CATEGORY_SUCCESS",
  GET_WEB_FAQ_CATEGORY_FAILED: "GET_WEB_FAQ_CATEGORY_FAILED",

  POST_WEB_FAQ_CATEGORY_REQUEST: "POST_WEB_FAQ_CATEGORY_REQUEST",
  POST_WEB_FAQ_CATEGORY_SUCCESS: "POST_WEB_FAQ_CATEGORY_SUCCESS",
  POST_WEB_FAQ_CATEGORY_FAILED: "POST_WEB_FAQ_CATEGORY_FAILED",

  PUT_WEB_FAQ_CATEGORY_REQUEST: "PUT_WEB_FAQ_CATEGORY_REQUEST",
  PUT_WEB_FAQ_CATEGORY_SUCCESS: "PUT_WEB_FAQ_CATEGORY_SUCCESS",
  PUT_WEB_FAQ_CATEGORY_FAILED: "PUT_WEB_FAQ_CATEGORY_FAILED",

  DELETE_WEB_FAQ_CATEGORY_REQUEST: "DELETE_WEB_FAQ_CATEGORY_REQUEST",
  DELETE_WEB_FAQ_CATEGORY_SUCCESS: "DELETE_WEB_FAQ_CATEGORY_SUCCESS",
  DELETE_WEB_FAQ_CATEGORY_FAILED: "DELETE_WEB_FAQ_CATEGORY_FAILED",

  GET_WEB_FAQ_REQUEST: "GET_WEB_FAQ_REQUEST",
  GET_WEB_FAQ_SUCCESS: "GET_WEB_FAQ_SUCCESS",
  GET_WEB_FAQ_FAILED: "GET_WEB_FAQ_FAILED",

  POST_WEB_FAQ_REQUEST: "POST_WEB_FAQ_REQUEST",
  POST_WEB_FAQ_SUCCESS: "POST_WEB_FAQ_SUCCESS",
  POST_WEB_FAQ_FAILED: "POST_WEB_FAQ_FAILED",

  PUT_WEB_FAQ_REQUEST: "PUT_WEB_FAQ_REQUEST",
  PUT_WEB_FAQ_SUCCESS: "PUT_WEB_FAQ_SUCCESS",
  PUT_WEB_FAQ_FAILED: "PUT_WEB_FAQ_FAILED",

  DELETE_WEB_FAQ_REQUEST: "DELETE_WEB_FAQ_REQUEST",
  DELETE_WEB_FAQ_SUCCESS: "DELETE_WEB_FAQ_SUCCESS",
  DELETE_WEB_FAQ_FAILED: "DELETE_WEB_FAQ_FAILED",

  GET_WEB_POPUP_PROMO_REQUEST: "GET_WEB_POPUP_PROMO_REQUEST",
  GET_WEB_POPUP_PROMO_SUCCESS: "GET_WEB_POPUP_PROMO_SUCCESS",
  GET_WEB_POPUP_PROMO_FAILED: "GET_WEB_POPUP_PROMO_FAILED",

  POST_WEB_POPUP_PROMO_REQUEST: "POST_WEB_POPUP_PROMO_REQUEST",
  POST_WEB_POPUP_PROMO_SUCCESS: "POST_WEB_POPUP_PROMO_SUCCESS",
  POST_WEB_POPUP_PROMO_FAILED: "POST_WEB_POPUP_PROMO_FAILED",

  PUT_WEB_POPUP_PROMO_REQUEST: "PUT_WEB_POPUP_PROMO_REQUEST",
  PUT_WEB_POPUP_PROMO_SUCCESS: "PUT_WEB_POPUP_PROMO_SUCCESS",
  PUT_WEB_POPUP_PROMO_FAILED: "PUT_WEB_POPUP_PROMO_FAILED",

  DELETE_WEB_POPUP_PROMO_REQUEST: "DELETE_WEB_POPUP_PROMO_REQUEST",
  DELETE_WEB_POPUP_PROMO_SUCCESS: "DELETE_WEB_POPUP_PROMO_SUCCESS",
  DELETE_WEB_POPUP_PROMO_FAILED: "DELETE_WEB_POPUP_PROMO_FAILED",

  GET_WEB_TESTIMONIALS_REQUEST: "GET_WEB_TESTIMONIALS_REQUEST",
  GET_WEB_TESTIMONIALS_SUCCESS: "GET_WEB_TESTIMONIALS_SUCCESS",
  GET_WEB_TESTIMONIALS_FAILED: "GET_WEB_TESTIMONIALS_FAILED",

  POST_WEB_TESTIMONIALS_REQUEST: "POST_WEB_TESTIMONIALS_REQUEST",
  POST_WEB_TESTIMONIALS_SUCCESS: "POST_WEB_TESTIMONIALS_SUCCESS",
  POST_WEB_TESTIMONIALS_FAILED: "POST_WEB_TESTIMONIALS_FAILED",

  PUT_WEB_TESTIMONIALS_REQUEST: "PUT_WEB_TESTIMONIALS_REQUEST",
  PUT_WEB_TESTIMONIALS_SUCCESS: "PUT_WEB_TESTIMONIALS_SUCCESS",
  PUT_WEB_TESTIMONIALS_FAILED: "PUT_WEB_TESTIMONIALS_FAILED",

  DELETE_WEB_TESTIMONIALS_REQUEST: "DELETE_WEB_TESTIMONIALS_REQUEST",
  DELETE_WEB_TESTIMONIALS_SUCCESS: "DELETE_WEB_TESTIMONIALS_SUCCESS",
  DELETE_WEB_TESTIMONIALS_FAILED: "DELETE_WEB_TESTIMONIALS_FAILED",

  GET_WEB_NEWS_REQUEST: "GET_WEB_NEWS_REQUEST",
  GET_WEB_NEWS_SUCCESS: "GET_WEB_NEWS_SUCCESS",
  GET_WEB_NEWS_FAILED: "GET_WEB_NEWS_FAILED",

  POST_WEB_NEWS_REQUEST: "POST_WEB_NEWS_REQUEST",
  POST_WEB_NEWS_SUCCESS: "POST_WEB_NEWS_SUCCESS",
  POST_WEB_NEWS_FAILED: "POST_WEB_NEWS_FAILED",

  PUT_WEB_NEWS_REQUEST: "PUT_WEB_NEWS_REQUEST",
  PUT_WEB_NEWS_SUCCESS: "PUT_WEB_NEWS_SUCCESS",
  PUT_WEB_NEWS_FAILED: "PUT_WEB_NEWS_FAILED",

  DELETE_WEB_NEWS_REQUEST: "DELETE_WEB_NEWS_REQUEST",
  DELETE_WEB_NEWS_SUCCESS: "DELETE_WEB_NEWS_SUCCESS",
  DELETE_WEB_NEWS_FAILED: "DELETE_WEB_NEWS_FAILED",

  GET_WEB_CAREER_REQUEST: "GET_WEB_CAREER_REQUEST",
  GET_WEB_CAREER_SUCCESS: "GET_WEB_CAREER_SUCCESS",
  GET_WEB_CAREER_FAILED: "GET_WEB_CAREER_FAILED",

  POST_WEB_CAREER_REQUEST: "POST_WEB_CAREER_REQUEST",
  POST_WEB_CAREER_SUCCESS: "POST_WEB_CAREER_SUCCESS",
  POST_WEB_CAREER_FAILED: "POST_WEB_CAREER_FAILED",

  PUT_WEB_CAREER_REQUEST: "PUT_WEB_CAREER_REQUEST",
  PUT_WEB_CAREER_SUCCESS: "PUT_WEB_CAREER_SUCCESS",
  PUT_WEB_CAREER_FAILED: "PUT_WEB_CAREER_FAILED",

  DELETE_WEB_CAREER_REQUEST: "DELETE_WEB_CAREER_REQUEST",
  DELETE_WEB_CAREER_SUCCESS: "DELETE_WEB_CAREER_SUCCESS",
  DELETE_WEB_CAREER_FAILED: "DELETE_WEB_CAREER_FAILED",

  DELETE_PROPERTY_INQUIRY_REQUEST: "DELETE_PROPERTY_INQUIRY_REQUEST",
  DELETE_PROPERTY_INQUIRY_SUCCESS: "DELETE_PROPERTY_INQUIRY_SUCCESS",
  DELETE_PROPERTY_INQUIRY_FAILED: "DELETE_PROPERTY_INQUIRY_FAILED",

  DELETE_APPOINTMENT_INQUIRY_REQUEST: "DELETE_APPOINTMENT_INQUIRY_REQUEST",
  DELETE_APPOINTMENT_INQUIRY_SUCCESS: "DELETE_APPOINTMENT_INQUIRY_SUCCESS",
  DELETE_APPOINTMENT_INQUIRY_FAILED: "DELETE_APPOINTMENT_INQUIRY_FAILED",

  GET_WEB_CAREER_APPLICATION_REQUEST: "GET_WEB_CAREER_APPLICATION_REQUEST",
  GET_WEB_CAREER_APPLICATION_SUCCESS: "GET_WEB_CAREER_APPLICATION_SUCCESS",
  GET_WEB_CAREER_APPLICATION_FAILED: "GET_WEB_CAREER_APPLICATION_FAILED",

  POST_WEB_CAREER_APPLICATION_REQUEST: "POST_WEB_CAREER_APPLICATION_REQUEST",
  POST_WEB_CAREER_APPLICATION_SUCCESS: "POST_WEB_CAREER_APPLICATION_SUCCESS",
  POST_WEB_CAREER_APPLICATION_FAILED: "POST_WEB_CAREER_APPLICATION_FAILED",

  PUT_WEB_CAREER_APPLICATION_REQUEST: "PUT_WEB_CAREER_APPLICATION_REQUEST",
  PUT_WEB_CAREER_APPLICATION_SUCCESS: "PUT_WEB_CAREER_APPLICATION_SUCCESS",
  PUT_WEB_CAREER_APPLICATION_FAILED: "PUT_WEB_CAREER_APPLICATION_FAILED",

  DELETE_WEB_CAREER_APPLICATION_REQUEST:
    "DELETE_WEB_CAREER_APPLICATION_REQUEST",
  DELETE_WEB_CAREER_APPLICATION_SUCCESS:
    "DELETE_WEB_CAREER_APPLICATION_SUCCESS",
  DELETE_WEB_CAREER_APPLICATION_FAILED: "DELETE_WEB_CAREER_APPLICATION_FAILED",

  POST_WEB_RECIPIENTS_REQUEST: "POST_WEB_RECIPIENTS_REQUEST",
  POST_WEB_RECIPIENTS_SUCCESS: "POST_WEB_RECIPIENTS_SUCCESS",
  POST_WEB_RECIPIENTS_FAILED: "POST_WEB_RECIPIENTS_FAILED",

  GET_WEB_RECIPIENTS_REQUEST: "GET_WEB_RECIPIENTS_REQUEST",
  GET_WEB_RECIPIENTS_SUCCESS: "GET_WEB_RECIPIENTS_SUCCESS",
  GET_WEB_RECIPIENTS_FAILED: "GET_WEB_RECIPIENTS_FAILED",

  GET_WEB_CAREERS_RECIPIENTS_REQUEST: "GET_WEB_CAREERS_RECIPIENTS_REQUEST",
  GET_WEB_CAREERS_RECIPIENTS_SUCCESS: "GET_WEB_CAREERS_RECIPIENTS_SUCCESS",
  GET_WEB_CAREERS_RECIPIENTS_FAILED: "GET_WEB_CAREERS_RECIPIENTS_FAILED",

  GET_WEB_PROPERTY_INQUIRIES_REQUEST: "GET_WEB_PROPERTY_INQUIRIES_REQUEST",
  GET_WEB_PROPERTY_INQUIRIES_SUCCESS: "GET_WEB_PROPERTY_INQUIRIES_SUCCESS",
  GET_WEB_PROPERTY_INQUIRIES_FAILED: "GET_WEB_PROPERTY_INQUIRIES_FAILED",

  GET_WEB_APPOINTMENT_INQUIRIES_REQUEST:
    "GET_WEB_APPOINTMENT_INQUIRIES_REQUEST",
  GET_WEB_APPOINTMENT_INQUIRIES_SUCCESS:
    "GET_WEB_APPOINTMENT_INQUIRIES_SUCCESS",
  GET_WEB_APPOINTMENT_INQUIRIES_FAILED: "GET_WEB_APPOINTMENT_INQUIRIES_FAILED",

  GET_SOFT_DELETED_WEB_APPOINTMENT_INQUIRIES_REQUEST:
    "GET_SOFT_DELETED_WEB_APPOINTMENT_INQUIRIES_REQUEST",
  GET_SOFT_DELETED_WEB_APPOINTMENT_INQUIRIES_SUCCESS:
    "GET_SOFT_DELETED_WEB_APPOINTMENT_INQUIRIES_SUCCESS",
  GET_SOFT_DELETED_WEB_APPOINTMENT_INQUIRIES_FAILED:
    "GET_SOFT_DELETED_WEB_APPOINTMENT_INQUIRIES_FAILED",

  GET_SOFT_DELETED_WEB_CAREER_APPLICATION_REQUEST:
    "GET_SOFT_DELETED_WEB_CAREER_APPLICATION_REQUEST",
  GET_SOFT_DELETED_WEB_CAREER_APPLICATION_SUCCESS:
    "GET_SOFT_DELETED_WEB_CAREER_APPLICATION_SUCCESS",
  GET_SOFT_DELETED_WEB_CAREER_APPLICATION_FAILED:
    "GET_SOFT_DELETED_WEB_CAREER_APPLICATION_FAILED",

  GET_SOFT_DELETED_WEB_PROPERTY_INQUIRIES_REQUEST:
    "GET_SOFT_DELETED_WEB_PROPERTY_INQUIRIES_REQUEST",
  GET_SOFT_DELETED_WEB_PROPERTY_INQUIRIES_SUCCESS:
    "GET_SOFT_DELETED_WEB_PROPERTY_INQUIRIES_SUCCESS",
  GET_SOFT_DELETED_WEB_PROPERTY_INQUIRIES_FAILED:
    "GET_SOFT_DELETED_WEB_PROPERTY_INQUIRIES_FAILED",

  POST_RESTORE_WEB_PROPERTY_INQUIRIES_REQUEST:
    "POST_RESTORE_WEB_PROPERTY_INQUIRIES_REQUEST",
  POST_RESTORE_WEB_PROPERTY_INQUIRIES_SUCCESS:
    "POST_RESTORE_WEB_PROPERTY_INQUIRIES_SUCCESS",
  POST_RESTORE_WEB_PROPERTY_INQUIRIES_FAILED:
    "POST_RESTORE_WEB_PROPERTY_INQUIRIES_FAILED",

  POST_EXPORT_WEB_PROPERTY_INQUIRIES_REQUEST:
    "POST_EXPORT_WEB_PROPERTY_INQUIRIES_REQUEST",
  POST_EXPORT_WEB_PROPERTY_INQUIRIES_SUCCESS:
    "POST_EXPORT_WEB_PROPERTY_INQUIRIES_SUCCESS",
  POST_EXPORT_WEB_PROPERTY_INQUIRIES_FAILED:
    "POST_EXPORT_WEB_PROPERTY_INQUIRIES_FAILED",

  POST_RESTORE_WEB_APPOINTMENT_INQUIRIES_REQUEST:
    "POST_RESTORE_WEB_APPOINTMENT_INQUIRIES_REQUEST",
  POST_RESTORE_WEB_APPOINTMENT_INQUIRIES_SUCCESS:
    "POST_RESTORE_WEB_APPOINTMENT_INQUIRIES_SUCCESS",
  POST_RESTORE_WEB_APPOINTMENT_INQUIRIES_FAILED:
    "POST_RESTORE_WEB_APPOINTMENT_INQUIRIES_FAILED",

  POST_EXPORT_WEB_APPOINTMENT_INQUIRIES_REQUEST:
    "POST_EXPORT_WEB_APPOINTMENT_INQUIRIES_REQUEST",
  POST_EXPORT_WEB_APPOINTMENT_INQUIRIES_SUCCESS:
    "POST_EXPORT_WEB_APPOINTMENT_INQUIRIES_SUCCESS",
  POST_EXPORT_WEB_APPOINTMENT_INQUIRIES_FAILED:
    "POST_EXPORT_WEB_APPOINTMENT_INQUIRIES_FAILED",

  POST_RESTORE_WEB_CAREER_APPLICATION_REQUEST:
    "POST_RESTORE_WEB_CAREER_APPLICATION_REQUEST",
  POST_RESTORE_WEB_CAREER_APPLICATION_SUCCESS:
    "POST_RESTORE_WEB_CAREER_APPLICATION_SUCCESS",
  POST_RESTORE_WEB_CAREER_APPLICATION_FAILED:
    "POST_RESTORE_WEB_CAREER_APPLICATION_FAILED",

  POST_EXPORT_WEB_CAREER_APPLICATION_REQUEST:
    "POST_EXPORT_WEB_CAREER_APPLICATION_REQUEST",
  POST_EXPORT_WEB_CAREER_APPLICATION_SUCCESS:
    "POST_EXPORT_WEB_CAREER_APPLICATION_SUCCESS",
  POST_EXPORT_WEB_CAREER_APPLICATION_FAILED:
    "POST_EXPORT_WEB_CAREER_APPLICATION_FAILED",

  GET_WEB_INQUIRY_RECIPIENTS_REQUEST: "GET_WEB_INQUIRY_RECIPIENTS_REQUEST",
  GET_WEB_INQUIRY_RECIPIENTS_SUCCESS: "GET_WEB_INQUIRY_RECIPIENTS_SUCCESS",
  GET_WEB_INQUIRY_RECIPIENTS_FAILED: "GET_WEB_INQUIRY_RECIPIENTS_FAILED",

  //web locations
  GET_WEB_LOCATIONS: "GET_WEB_LOCATIONS",
  GET_WEB_LOCATIONS_SUCCESS: "GET_WEB_LOCATIONS_SUCCESS",
  GET_WEB_LOCATIONS_FAILED: "GET_WEB_LOCATIONS_FAILED",

  POST_WEB_LOCATIONS: "POST_WEB_LOCATIONS",
  POST_WEB_LOCATIONS_SUCCESS: "POST_WEB_LOCATIONS_SUCCESS",
  POST_WEB_LOCATIONS_FAILED: "POST_WEB_LOCATIONS_FAILED",

  DELETE_WEB_LOCATIONS: "DELETE_WEB_LOCATIONS",
  DELETE_WEB_LOCATIONS_SUCCESS: "DELETE_WEB_LOCATIONS_SUCCESS",
  DELETE_WEB_LOCATIONS_FAILED: "DELETE_WEB_LOCATIONS_FAILED",

  EDIT_WEB_LOCATIONS: "EDIT_WEB_LOCATIONS",
  EDIT_WEB_LOCATIONS_SUCCESS: "EDIT_WEB_LOCATIONS_SUCCESS",
  EDIT_WEB_LOCATIONS_FAILED: "EDIT_WEB_LOCATIONS_FAILED",

  //web awards
  GET_WEB_AWARDS: "GET_WEB_AWARDS",
  GET_WEB_AWARDS_SUCCESS: "GET_WEB_AWARDS_SUCCESS",
  GET_WEB_AWARDS_FAILED: "GET_WEB_AWARDS_FAILED",

  POST_WEB_AWARDS: "POST_WEB_AWARDS",
  POST_WEB_AWARDS_SUCCESS: "POST_WEB_AWARDS_SUCCESS",
  POST_WEB_AWARDS_FAILED: "POST_WEB_AWARDS_FAILED",

  DELETE_WEB_AWARDS: "DELETE_WEB_AWARDS",
  DELETE_WEB_AWARDS_SUCCESS: "DELETE_WEB_AWARDS_SUCCESS",
  DELETE_WEB_AWARDS_FAILED: "DELETE_WEB_AWARDS_FAILED",

  EDIT_WEB_AWARDS: "EDIT_WEB_AWARDS",
  EDIT_WEB_AWARDS_SUCCESS: "EDIT_WEB_AWARDS_SUCCESS",
  EDIT_WEB_AWARDS_FAILED: "EDIT_WEB_AWARDS_FAILED",

  //web award years
  GET_WEB_AWARDS_YEARS: "GET_WEB_AWARDS_YEARS",
  GET_WEB_AWARDS_YEARS_SUCCESS: "GET_WEB_AWARDS_YEARS_SUCCESS",
  GET_WEB_AWARDS_YEARS_FAILED: "GET_WEB_AWARDS_YEARS_FAILED",

  POST_WEB_AWARDS_YEARS: "POST_WEB_AWARDS_YEARS",
  POST_WEB_AWARDS_YEARS_SUCCESS: "POST_WEB_AWARDS_YEARS_SUCCESS",
  POST_WEB_AWARDS_YEARS_FAILED: "POST_WEB_AWARDS_YEARS_FAILED",

  DELETE_WEB_AWARDS_YEARS: "DELETE_WEB_AWARDS_YEARS",
  DELETE_WEB_AWARDS_YEARS_SUCCESS: "DELETE_WEB_AWARDS_YEARS_SUCCESS",
  DELETE_WEB_AWARDS_YEARS_FAILED: "DELETE_WEB_AWARDS_YEARS_FAILED",

  EDIT_WEB_AWARDS_YEARS: "EDIT_WEB_AWARDS_YEARS",
  EDIT_WEB_AWARDS_YEARS_SUCCESS: "EDIT_WEB_AWARDS_YEARS_SUCCESS",
  EDIT_WEB_AWARDS_YEARS_FAILED: "EDIT_WEB_AWARDS_YEARS_FAILED",
};

// Action creators
export const actionCreators = {
  ...newsletterSubscriptionsActionCreators,
  //web locations
  getWebLocations: (params) => ({
    type: actionTypes.GET_WEB_LOCATIONS,
    params,
  }),
  postWebLocations: (params) => ({
    type: actionTypes.POST_WEB_LOCATIONS,
    params,
  }),
  deleteWebLocations: (params) => ({
    type: actionTypes.DELETE_WEB_LOCATIONS,
    params,
  }),
  editWebLocations: (params) => ({
    type: actionTypes.EDIT_WEB_LOCATIONS,
    params,
  }),
  //awards
  getWebAwards: (params) => ({
    type: actionTypes.GET_WEB_AWARDS,
    params,
  }),
  postWebAwards: (params) => ({
    type: actionTypes.POST_WEB_AWARDS,
    params,
  }),
  deleteWebAwards: (params) => ({
    type: actionTypes.DELETE_WEB_AWARDS,
    params,
  }),
  editWebAwards: (params) => ({
    type: actionTypes.EDIT_WEB_AWARDS,
    params,
  }),
  //awards years
  getWebAwardsYears: (params) => ({
    type: actionTypes.GET_WEB_AWARDS_YEARS,
    params,
  }),
  postWebAwardsYears: (params) => ({
    type: actionTypes.POST_WEB_AWARDS_YEARS,
    params,
  }),
  deleteWebAwardsYears: (params) => ({
    type: actionTypes.DELETE_WEB_AWARDS_YEARS,
    params,
  }),
  editWebAwardsYears: (params) => ({
    type: actionTypes.EDIT_WEB_AWARDS_YEARS,
    params,
  }),
  //
  getAllProperties: (params) => ({
    type: actionTypes.GET_ALL_PROPERTIES,
    params,
  }),
  postExportWebCareerApplication: (params) => ({
    type: actionTypes.POST_EXPORT_WEB_CAREER_APPLICATION_REQUEST,
    params,
  }),
  postRestoreWebCareerApplication: (params) => ({
    type: actionTypes.POST_RESTORE_WEB_CAREER_APPLICATION_REQUEST,
    params,
  }),
  postExportWebAppointments: (params) => ({
    type: actionTypes.POST_EXPORT_WEB_APPOINTMENT_INQUIRIES_REQUEST,
    params,
  }),
  postRestoreWebAppointments: (params) => ({
    type: actionTypes.POST_RESTORE_WEB_APPOINTMENT_INQUIRIES_REQUEST,
    params,
  }),
  postExportWebPropertyInquiries: (params) => ({
    type: actionTypes.POST_EXPORT_WEB_PROPERTY_INQUIRIES_REQUEST,
    params,
  }),
  postRestoreWebPropertyInquiries: (params) => ({
    type: actionTypes.POST_RESTORE_WEB_PROPERTY_INQUIRIES_REQUEST,
    params,
  }),
  getSoftDeletedWebAppointments: (params) => ({
    type: actionTypes.GET_SOFT_DELETED_WEB_APPOINTMENT_INQUIRIES_REQUEST,
    params,
  }),
  getSoftDeletedWebCareerApplications: (params) => ({
    type: actionTypes.GET_SOFT_DELETED_WEB_CAREER_APPLICATION_REQUEST,
    params,
  }),
  getSoftDeletedWebPropertyInquiries: (params) => ({
    type: actionTypes.GET_SOFT_DELETED_WEB_PROPERTY_INQUIRIES_REQUEST,
    params,
  }),
  getWebContentData: (params) => ({
    type: actionTypes.CONTENT_WEB_DATA_REQUEST,
    params,
  }),
  postWebContent: (params) => ({
    type: actionTypes.POST_WEB_DATA_REQUEST,
    params,
  }),
  getWebFaqCategory: (params) => ({
    type: actionTypes.GET_WEB_FAQ_CATEGORY_REQUEST,
    params,
  }),
  postWebFaqCategory: (params) => ({
    type: actionTypes.POST_WEB_FAQ_CATEGORY_REQUEST,
    params,
  }),
  putWebFaqCategory: (params) => ({
    type: actionTypes.PUT_WEB_FAQ_CATEGORY_REQUEST,
    params,
  }),
  deleteWebFaqCategory: (params) => ({
    type: actionTypes.DELETE_WEB_FAQ_CATEGORY_REQUEST,
    params,
  }),
  getWebFaq: (params) => ({
    type: actionTypes.GET_WEB_FAQ_REQUEST,
    params,
  }),
  postWebFaq: (params) => ({
    type: actionTypes.POST_WEB_FAQ_REQUEST,
    params,
  }),
  putWebFaq: (params) => ({
    type: actionTypes.PUT_WEB_FAQ_REQUEST,
    params,
  }),
  deleteWebFaq: (params) => ({
    type: actionTypes.DELETE_WEB_FAQ_REQUEST,
    params,
  }),
  getWebPopupPromo: (params) => ({
    type: actionTypes.GET_WEB_POPUP_PROMO_REQUEST,
    params,
  }),
  postWebPopupPromo: (params) => ({
    type: actionTypes.POST_WEB_POPUP_PROMO_REQUEST,
    params,
  }),
  putWebPopupPromo: (params) => ({
    type: actionTypes.PUT_WEB_POPUP_PROMO_REQUEST,
    params,
  }),
  deleteWebPopupPromo: (params) => ({
    type: actionTypes.DELETE_WEB_POPUP_PROMO_REQUEST,
    params,
  }),
  getWebTestimonials: (params) => ({
    type: actionTypes.GET_WEB_TESTIMONIALS_REQUEST,
    params,
  }),
  postWebTestimonials: (params) => ({
    type: actionTypes.POST_WEB_TESTIMONIALS_REQUEST,
    params,
  }),
  putWebTestimonials: (params) => ({
    type: actionTypes.PUT_WEB_TESTIMONIALS_REQUEST,
    params,
  }),
  deleteWebTestimonials: (params) => ({
    type: actionTypes.DELETE_WEB_TESTIMONIALS_REQUEST,
    params,
  }),
  getWebNews: (params) => ({
    type: actionTypes.GET_WEB_NEWS_REQUEST,
    params,
  }),
  postWebNews: (params) => ({
    type: actionTypes.POST_WEB_NEWS_REQUEST,
    params,
  }),
  putWebNews: (params) => ({
    type: actionTypes.PUT_WEB_NEWS_REQUEST,
    params,
  }),
  deleteWebNews: (params) => ({
    type: actionTypes.DELETE_WEB_NEWS_REQUEST,
    params,
  }),
  getWebCareer: (params) => ({
    type: actionTypes.GET_WEB_CAREER_REQUEST,
    params,
  }),
  postWebCareer: (params) => ({
    type: actionTypes.POST_WEB_CAREER_REQUEST,
    params,
  }),
  putWebCareer: (params) => ({
    type: actionTypes.PUT_WEB_CAREER_REQUEST,
    params,
  }),
  deleteWebCareer: (params) => ({
    type: actionTypes.DELETE_WEB_CAREER_REQUEST,
    params,
  }),
  getWebCareerApplication: (params) => ({
    type: actionTypes.GET_WEB_CAREER_APPLICATION_REQUEST,
    params,
  }),
  postWebCareerApplication: (params) => ({
    type: actionTypes.POST_WEB_CAREER_APPLICATION_REQUEST,
    params,
  }),
  putWebCareerApplication: (params) => ({
    type: actionTypes.PUT_WEB_CAREER_APPLICATION_REQUEST,
    params,
  }),
  deleteWebCareerApplication: (params) => ({
    type: actionTypes.DELETE_WEB_CAREER_APPLICATION_REQUEST,
    params,
  }),
  deletePropertyInquiry: (params) => ({
    type: actionTypes.DELETE_PROPERTY_INQUIRY_REQUEST,
    params,
  }),
  deleteAppointmentInquiry: (params) => ({
    type: actionTypes.DELETE_APPOINTMENT_INQUIRY_REQUEST,
    params,
  }),
  postWebRecipients: (params) => ({
    type: actionTypes.POST_WEB_RECIPIENTS_REQUEST,
    params,
  }),
  getWebRecipients: (params) => ({
    type: actionTypes.GET_WEB_RECIPIENTS_REQUEST,
    params,
  }),
  getWebCareersRecipients: (params) => ({
    type: actionTypes.GET_WEB_CAREERS_RECIPIENTS_REQUEST,
    params,
  }),
  getWebInquiryRecipients: (params) => ({
    type: actionTypes.GET_WEB_INQUIRY_RECIPIENTS_REQUEST,
    params,
  }),
  getWebPropertyInquiries: (params) => ({
    type: actionTypes.GET_WEB_PROPERTY_INQUIRIES_REQUEST,
    params,
  }),
  getWebAppointmentInquiries: (params) => ({
    type: actionTypes.GET_WEB_APPOINTMENT_INQUIRIES_REQUEST,
    params,
  }),
  postWebLocations: (params) => ({
    type: actionTypes.POST_WEB_LOCATIONS,
    params,
  }),
  deleteWebLocations: (params) => ({
    type: actionTypes.DELETE_WEB_LOCATIONS,
    params,
  }),
  editWebLocations: (params) => ({
    type: actionTypes.EDIT_WEB_LOCATIONS,
    params,
  }),
};

// Reducer
const initialState = {
  ...newsletterSubscriptionsInitialState,
  properties: [],
  isLoadingProperties: false,
  isSuccessPost: false,
  isLoading: false,
  errors: null,
  data: {},
  faq: null,
  faqCategory: null,
  popupPromo: null,
  testimonials: null,
  news: null,
  career: null,
  careerApplication: null,
  webRecipients: [],
  webCareerRecipients: [],
  webInquiries: [],
  webAppointments: [],
  webInquiryRecipients: [],
  webLocations: [],
  isGettingWebLocations: false,
  webAwards: [],
  isGettingWebAwards: false,
  webAwardsYears: [],
  isGettingWebAwardsYears: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    //web locations
    case actionTypes.GET_WEB_LOCATIONS:
      return Object.assign({}, state, {
        webLocations: [],
        errors: null,
        isGettingWebLocations: true,
      });

    case actionTypes.GET_WEB_LOCATIONS_SUCCESS:
      return Object.assign({}, state, {
        webLocations: action.payload,
        isGettingWebLocations: false,
        errors: null,
      });

    case actionTypes.GET_WEB_LOCATIONS_FAILED:
      return Object.assign({}, state, {
        webLocations: [],
        errors: action.error,
        isGettingWebLocations: false,
      });
    // awards
    case actionTypes.GET_WEB_AWARDS:
      return Object.assign({}, state, {
        webAwards: [],
        errors: null,
        isGettingWebAwards: true,
      });

    case actionTypes.GET_WEB_AWARDS_SUCCESS:
      return Object.assign({}, state, {
        webAwards: action.payload,
        isGettingWebAwards: false,
        errors: null,
      });

    case actionTypes.GET_WEB_AWARDS_FAILED:
      return Object.assign({}, state, {
        webAwards: [],
        errors: action.error,
        isGettingWebAwards: false,
      });

    case actionTypes.POST_WEB_AWARDS:
      return Object.assign({}, state, {
        webAwards: [],
        errors: null,
        isGettingWebAwards: true,
      });
    case actionTypes.POST_WEB_AWARDS_SUCCESS:
      return Object.assign({}, state, {
        webAwards: action.payload,
        isGettingWebAwards: false,
        errors: null,
      });

    case actionTypes.POST_WEB_AWARDS_FAILED:
      return Object.assign({}, state, {
        webAwards: [],
        errors: action.error,
        isGettingWebAwards: false,
      });

    case actionTypes.DELETE_WEB_AWARDS:
      return Object.assign({}, state, {
        webAwards: [],
        errors: null,
        isGettingWebAwards: true,
      });

    case actionTypes.DELETE_WEB_AWARDS_SUCCESS:
      return Object.assign({}, state, {
        webAwards: action.payload,
        isGettingWebAwards: false,
        errors: null,
      });

    case actionTypes.DELETE_WEB_AWARDS_FAILED:
      return Object.assign({}, state, {
        webAwards: [],
        errors: action.error,
        isGettingWebAwards: false,
      });

    case actionTypes.EDIT_WEB_AWARDS:
      return Object.assign({}, state, {
        webAwards: [],
        errors: null,
        isGettingWebAwards: true,
      });

    case actionTypes.EDIT_WEB_AWARDS_SUCCESS:
      return Object.assign({}, state, {
        webAwards: action.payload,
        isGettingWebAwards: false,
        errors: null,
      });

    case actionTypes.EDIT_WEB_AWARDS_FAILED:
      return Object.assign({}, state, {
        webAwards: [],
        errors: action.error,
        isGettingWebAwards: false,
      });
    //  SDADASDA
    // awards
    case actionTypes.GET_WEB_AWARDS_YEARS:
      return Object.assign({}, state, {
        webAwardsYears: [],
        errors: null,
        isGettingWebAwardsYears: true,
      });

    case actionTypes.GET_WEB_AWARDS_YEARS_SUCCESS:
      return Object.assign({}, state, {
        webAwardsYears: action.payload,
        isGettingWebAwardsYears: false,
        errors: null,
      });

    case actionTypes.GET_WEB_AWARDS_YEARS_FAILED:
      return Object.assign({}, state, {
        webAwardsYears: [],
        errors: action.error,
        isGettingWebAwardsYears: false,
      });

    case actionTypes.POST_WEB_AWARDS_YEARS:
      return Object.assign({}, state, {
        webAwardsYears: [],
        errors: null,
        isGettingWebAwardsYears: true,
      });
    case actionTypes.POST_WEB_AWARDS_YEARS_SUCCESS:
      return Object.assign({}, state, {
        webAwardsYears: action.payload,
        isGettingWebAwardsYears: false,
        errors: null,
      });

    case actionTypes.POST_WEB_AWARDS_YEARS_FAILED:
      return Object.assign({}, state, {
        webAwardsYears: [],
        errors: action.error,
        isGettingWebAwardsYears: false,
      });

    case actionTypes.DELETE_WEB_AWARDS_YEARS:
      return Object.assign({}, state, {
        webAwardsYears: [],
        errors: null,
        isGettingWebAwardsYears: true,
      });

    case actionTypes.DELETE_WEB_AWARDS_YEARS_SUCCESS:
      return Object.assign({}, state, {
        webAwardsYears: action.payload,
        isGettingWebAwardsYears: false,
        errors: null,
      });

    case actionTypes.DELETE_WEB_AWARDS_YEARS_FAILED:
      return Object.assign({}, state, {
        webAwardsYears: [],
        errors: action.error,
        isGettingWebAwardsYears: false,
      });

    case actionTypes.EDIT_WEB_AWARDS_YEARS:
      return Object.assign({}, state, {
        webAwardsYears: [],
        errors: null,
        isGettingWebAwardsYears: true,
      });

    case actionTypes.EDIT_WEB_AWARDS_YEARS_SUCCESS:
      return Object.assign({}, state, {
        webAwardsYears: action.payload,
        isGettingWebAwardsYears: false,
        errors: null,
      });

    case actionTypes.EDIT_WEB_AWARDS_YEARS_FAILED:
      return Object.assign({}, state, {
        webAwardsYears: [],
        errors: action.error,
        isGettingWebAwardsYears: false,
      });
    // here

    case actionTypes.POST_WEB_LOCATIONS:
      return Object.assign({}, state, {
        webLocations: [],
        errors: null,
        isGettingWebLocations: true,
      });
    case actionTypes.POST_WEB_LOCATIONS_SUCCESS:
      return Object.assign({}, state, {
        webLocations: action.payload,
        isGettingWebLocations: false,
        errors: null,
      });

    case actionTypes.POST_WEB_LOCATIONS_FAILED:
      return Object.assign({}, state, {
        webLocations: [],
        errors: action.error,
        isGettingWebLocations: false,
      });
    //delete
    case actionTypes.DELETE_WEB_LOCATIONS:
      return Object.assign({}, state, {
        webLocations: [],
        errors: null,
        isGettingWebLocations: true,
      });

    case actionTypes.DELETE_WEB_LOCATIONS_SUCCESS:
      return Object.assign({}, state, {
        webLocations: action.payload,
        isGettingWebLocations: false,
        errors: null,
      });

    case actionTypes.DELETE_WEB_LOCATIONS_FAILED:
      return Object.assign({}, state, {
        webLocations: [],
        errors: action.error,
        isGettingWebLocations: false,
      });
    //edit
    case actionTypes.EDIT_WEB_LOCATIONS:
      return Object.assign({}, state, {
        webLocations: [],
        errors: null,
        isGettingWebLocations: true,
      });

    case actionTypes.EDIT_WEB_LOCATIONS_SUCCESS:
      return Object.assign({}, state, {
        webLocations: action.payload,
        isGettingWebLocations: false,
        errors: null,
      });

    case actionTypes.EDIT_WEB_LOCATIONS_FAILED:
      return Object.assign({}, state, {
        webLocations: [],
        errors: action.error,
        isGettingWebLocations: false,
      });
    //all properties
    case actionTypes.GET_ALL_PROPERTIES:
      return Object.assign({}, state, {
        properties: [],
        errors: null,
        isLoadingProperties: true,
      });

    case actionTypes.GET_ALL_PROPERTIES_SUCCESS:
      return Object.assign({}, state, {
        properties: action.payload,
        isLoadingProperties: false,
        errors: null,
      });

    case actionTypes.GET_ALL_PROPERTIES_FAILED:
      return Object.assign({}, state, {
        properties: [],
        errors: action.error,
        isLoadingProperties: false,
      });

    case actionTypes.POST_EXPORT_WEB_APPOINTMENT_INQUIRIES_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.POST_EXPORT_WEB_APPOINTMENT_INQUIRIES_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
      });

    case actionTypes.POST_EXPORT_WEB_APPOINTMENT_INQUIRIES_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.POST_RESTORE_WEB_APPOINTMENT_INQUIRIES_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.POST_RESTORE_WEB_APPOINTMENT_INQUIRIES_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        webAppointments: action.payload,
      });

    case actionTypes.POST_RESTORE_WEB_APPOINTMENT_INQUIRIES_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
        webAppointments: null,
      });

    case actionTypes.POST_EXPORT_WEB_PROPERTY_INQUIRIES_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.POST_EXPORT_WEB_PROPERTY_INQUIRIES_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
      });

    case actionTypes.POST_EXPORT_WEB_PROPERTY_INQUIRIES_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.POST_RESTORE_WEB_PROPERTY_INQUIRIES_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.POST_RESTORE_WEB_PROPERTY_INQUIRIES_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        webInquiries: action.payload,
      });

    case actionTypes.POST_RESTORE_WEB_PROPERTY_INQUIRIES_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
        webInquiries: null,
      });

    case actionTypes.GET_SOFT_DELETED_WEB_APPOINTMENT_INQUIRIES_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
        webAppointments: null,
      });

    case actionTypes.GET_SOFT_DELETED_WEB_APPOINTMENT_INQUIRIES_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        webAppointments: action.payload,
      });

    case actionTypes.GET_SOFT_DELETED_WEB_APPOINTMENT_INQUIRIES_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
        webAppointments: null,
      });

    case actionTypes.GET_SOFT_DELETED_WEB_PROPERTY_INQUIRIES_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
        webInquiries: null,
      });

    case actionTypes.GET_SOFT_DELETED_WEB_PROPERTY_INQUIRIES_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        webInquiries: action.payload,
      });

    case actionTypes.GET_SOFT_DELETED_WEB_PROPERTY_INQUIRIES_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
        webInquiries: null,
      });

    case actionTypes.GET_SOFT_DELETED_WEB_CAREER_APPLICATION_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
        careerApplication: null,
      });

    case actionTypes.GET_SOFT_DELETED_WEB_CAREER_APPLICATION_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        careerApplication: action.payload,
      });

    case actionTypes.GET_SOFT_DELETED_WEB_CAREER_APPLICATION_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.GET_WEB_APPOINTMENT_INQUIRIES_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
        webAppointments: null,
      });

    case actionTypes.GET_WEB_APPOINTMENT_INQUIRIES_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        webAppointments: action.payload,
      });

    case actionTypes.GET_WEB_APPOINTMENT_INQUIRIES_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
        webAppointments: null,
      });
    case actionTypes.GET_WEB_PROPERTY_INQUIRIES_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
        webInquiries: null,
      });

    case actionTypes.GET_WEB_PROPERTY_INQUIRIES_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        webInquiries: action.payload,
      });

    case actionTypes.GET_WEB_PROPERTY_INQUIRIES_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
        webInquiries: null,
      });

    case actionTypes.CONTENT_WEB_DATA_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
        data: null,
      });

    case actionTypes.CONTENT_WEB_DATA_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        data: action.payload,
      });

    case actionTypes.CONTENT_WEB_DATA_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
        data: null,
      });

    case actionTypes.POST_WEB_DATA_REQUEST:
      return Object.assign({}, state, {
        isSuccessPost: false,
        isLoading: true,
        errors: null,
      });

    case actionTypes.POST_WEB_DATA_SUCCESS:
      return Object.assign({}, state, {
        isSuccessPost: true,
        isLoading: false,
        errors: null,
        data: action.payload,
      });

    case actionTypes.POST_WEB_DATA_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        isSuccessPost: false,
        errors: action.error,
      });

    case actionTypes.GET_WEB_FAQ_CATEGORY_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.GET_WEB_FAQ_CATEGORY_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        faqCategory: action.payload,
      });

    case actionTypes.GET_WEB_FAQ_CATEGORY_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.POST_WEB_FAQ_CATEGORY_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.POST_WEB_FAQ_CATEGORY_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        faqCategory: action.payload,
      });

    case actionTypes.POST_WEB_FAQ_CATEGORY_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.PUT_WEB_FAQ_CATEGORY_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.PUT_WEB_FAQ_CATEGORY_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        faqCategory: action.payload,
      });

    case actionTypes.PUT_WEB_FAQ_CATEGORY_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.DELETE_WEB_FAQ_CATEGORY_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.DELETE_WEB_FAQ_CATEGORY_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        faqCategory: action.payload,
      });

    case actionTypes.DELETE_WEB_FAQ_CATEGORY_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.GET_WEB_FAQ_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.GET_WEB_FAQ_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        faq: action.payload,
      });

    case actionTypes.GET_WEB_FAQ_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.POST_WEB_FAQ_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.POST_WEB_FAQ_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        faq: action.payload,
      });

    case actionTypes.POST_WEB_FAQ_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.PUT_WEB_FAQ_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.PUT_WEB_FAQ_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        faq: action.payload,
      });

    case actionTypes.PUT_WEB_FAQ_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.DELETE_WEB_FAQ_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.DELETE_WEB_FAQ_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        faq: action.payload,
      });

    case actionTypes.DELETE_WEB_FAQ_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.GET_WEB_POPUP_PROMO_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.GET_WEB_POPUP_PROMO_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        popupPromo: action.payload,
      });

    case actionTypes.GET_WEB_POPUP_PROMO_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.POST_WEB_POPUP_PROMO_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.POST_WEB_POPUP_PROMO_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        popupPromo: action.payload,
      });

    case actionTypes.POST_WEB_POPUP_PROMO_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.PUT_WEB_POPUP_PROMO_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.PUT_WEB_POPUP_PROMO_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        popupPromo: action.payload,
      });

    case actionTypes.PUT_WEB_POPUP_PROMO_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.DELETE_WEB_POPUP_PROMO_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.DELETE_WEB_POPUP_PROMO_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        popupPromo: action.payload,
      });

    case actionTypes.DELETE_WEB_POPUP_PROMO_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.GET_WEB_TESTIMONIALS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.GET_WEB_TESTIMONIALS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        testimonials: action.payload,
      });

    case actionTypes.GET_WEB_TESTIMONIALS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.POST_WEB_TESTIMONIALS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.POST_WEB_TESTIMONIALS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        testimonials: action.payload,
      });

    case actionTypes.POST_WEB_TESTIMONIALS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.PUT_WEB_TESTIMONIALS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.PUT_WEB_TESTIMONIALS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        testimonials: action.payload,
      });

    case actionTypes.PUT_WEB_TESTIMONIALS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.DELETE_WEB_TESTIMONIALS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.DELETE_WEB_TESTIMONIALS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        testimonials: action.payload,
      });

    case actionTypes.DELETE_WEB_TESTIMONIALS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.GET_WEB_NEWS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.GET_WEB_NEWS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        news: action.payload,
      });

    case actionTypes.GET_WEB_NEWS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.POST_WEB_NEWS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.POST_WEB_NEWS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        news: action.payload,
      });

    case actionTypes.POST_WEB_NEWS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.PUT_WEB_NEWS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.PUT_WEB_NEWS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        news: action.payload,
      });

    case actionTypes.PUT_WEB_NEWS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.DELETE_WEB_NEWS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.DELETE_WEB_NEWS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        news: action.payload,
      });

    case actionTypes.DELETE_WEB_NEWS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.GET_WEB_CAREER_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.GET_WEB_CAREER_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        career: action.payload,
      });

    case actionTypes.GET_WEB_CAREER_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.POST_WEB_CAREER_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.POST_WEB_CAREER_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        career: action.payload,
      });

    case actionTypes.POST_WEB_CAREER_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.PUT_WEB_CAREER_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.PUT_WEB_CAREER_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        career: action.payload,
      });

    case actionTypes.PUT_WEB_CAREER_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.DELETE_WEB_CAREER_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.DELETE_WEB_CAREER_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        career: action.payload,
      });

    case actionTypes.DELETE_WEB_CAREER_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.GET_WEB_CAREER_APPLICATION_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
        careerApplication: null,
      });

    case actionTypes.GET_WEB_CAREER_APPLICATION_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        careerApplication: action.payload,
      });

    case actionTypes.GET_WEB_CAREER_APPLICATION_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });
    case actionTypes.POST_EXPORT_WEB_CAREER_APPLICATION_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.POST_EXPORT_WEB_CAREER_APPLICATION_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
      });

    case actionTypes.POST_EXPORT_WEB_CAREER_APPLICATION_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.POST_RESTORE_WEB_CAREER_APPLICATION_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
        careerApplication: null,
      });

    case actionTypes.POST_RESTORE_WEB_CAREER_APPLICATION_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        careerApplication: action.payload,
      });

    case actionTypes.POST_RESTORE_WEB_CAREER_APPLICATION_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.POST_WEB_CAREER_APPLICATION_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.POST_WEB_CAREER_APPLICATION_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        careerApplication: action.payload,
      });

    case actionTypes.POST_WEB_CAREER_APPLICATION_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.PUT_WEB_CAREER_APPLICATION_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.PUT_WEB_CAREER_APPLICATION_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        careerApplication: action.payload,
      });

    case actionTypes.PUT_WEB_CAREER_APPLICATION_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.DELETE_WEB_CAREER_APPLICATION_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.DELETE_WEB_CAREER_APPLICATION_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        careerApplication: action.payload,
      });

    case actionTypes.DELETE_WEB_CAREER_APPLICATION_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.DELETE_PROPERTY_INQUIRY_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.DELETE_PROPERTY_INQUIRY_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        webInquiries: action.payload,
      });

    case actionTypes.DELETE_PROPERTY_INQUIRY_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.DELETE_APPOINTMENT_INQUIRY_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.DELETE_APPOINTMENT_INQUIRY_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        webAppointments: action.payload,
      });

    case actionTypes.DELETE_APPOINTMENT_INQUIRY_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.POST_WEB_RECIPIENTS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.POST_WEB_RECIPIENTS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        webRecipients:
          action.payload === undefined
            ? []
            : JSON.parse(action.payload[0].payload),
        webCareerRecipients:
          action.payload === undefined
            ? []
            : JSON.parse(action.payload[0].careers),
        webInquiryRecipients:
          action.payload === undefined
            ? []
            : JSON.parse(action.payload[0].property_inquiry),
      });

    case actionTypes.POST_WEB_RECIPIENTS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.GET_WEB_RECIPIENTS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.GET_WEB_RECIPIENTS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        webRecipients: JSON.parse(action.payload) || [],
      });

    case actionTypes.GET_WEB_RECIPIENTS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.GET_WEB_CAREERS_RECIPIENTS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.GET_WEB_CAREERS_RECIPIENTS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        webCareerRecipients: JSON.parse(action.payload) || [],
      });

    case actionTypes.GET_WEB_CAREERS_RECIPIENTS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    case actionTypes.GET_WEB_INQUIRY_RECIPIENTS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.GET_WEB_INQUIRY_RECIPIENTS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        webInquiryRecipients: JSON.parse(action.payload) || [],
      });

    case actionTypes.GET_WEB_INQUIRY_RECIPIENTS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    // GET_WEB_NEWSLETTER_SUBSCRIPTIONS

    case actionTypes.GET_WEB_NEWSLETTER_SUBSCRIPTIONS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
        newsletterSubscriptions: [],
      });

    case actionTypes.GET_WEB_NEWSLETTER_SUBSCRIPTIONS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        newsletterSubscriptions: action.payload,
      });

    case actionTypes.GET_WEB_NEWSLETTER_SUBSCRIPTIONS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    // DELETE_WEB_NEWSLETTER_SUBSCRIPTIONS

    case actionTypes.DELETE_WEB_NEWSLETTER_SUBSCRIPTIONS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.DELETE_WEB_NEWSLETTER_SUBSCRIPTIONS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        newsletterSubscriptions: action.payload,
      });

    case actionTypes.DELETE_WEB_NEWSLETTER_SUBSCRIPTIONS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    // POST_EXPORT_WEB_NEWSLETTER_SUBSCRIPTIONS

    case actionTypes.POST_EXPORT_WEB_NEWSLETTER_SUBSCRIPTIONS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
      });

    case actionTypes.POST_EXPORT_WEB_NEWSLETTER_SUBSCRIPTIONS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
      });

    case actionTypes.POST_EXPORT_WEB_NEWSLETTER_SUBSCRIPTIONS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    // POST_RESTORE_WEB_NEWSLETTER_SUBSCRIPTIONS

    case actionTypes.POST_RESTORE_WEB_NEWSLETTER_SUBSCRIPTIONS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
        newsletterSubscriptions: [],
      });

    case actionTypes.POST_RESTORE_WEB_NEWSLETTER_SUBSCRIPTIONS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        newsletterSubscriptions: action.payload,
      });

    case actionTypes.POST_RESTORE_WEB_NEWSLETTER_SUBSCRIPTIONS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
      });

    // GET_SOFT_DELETED_WEB_NEWSLETTER_SUBSCRIPTIONS

    case actionTypes.GET_SOFT_DELETED_WEB_NEWSLETTER_SUBSCRIPTIONS_REQUEST:
      return Object.assign({}, state, {
        isLoading: true,
        errors: null,
        newsletterSubscriptions: [],
      });

    case actionTypes.GET_SOFT_DELETED_WEB_NEWSLETTER_SUBSCRIPTIONS_SUCCESS:
      return Object.assign({}, state, {
        isLoading: false,
        errors: null,
        newsletterSubscriptions: action.payload,
      });

    case actionTypes.GET_SOFT_DELETED_WEB_NEWSLETTER_SUBSCRIPTIONS_FAILED:
      return Object.assign({}, state, {
        isLoading: false,
        errors: action.error,
        newsletterSubscriptions: [],
      });

    default:
      return state;
  }
};
