import Loadable from "react-loadable";
import LoadingComponent from "../../commons/loader";

export default [
  {
    path: "/project-info/",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages"),
      loading: LoadingComponent
    })
  },
  {
    path: "/project-info/:propertyName/basic-info",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/basic-info"),
      loading: LoadingComponent
    })
  },
  {
    path: "/project-info/:propertyName/project-overview",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/project-overview"),
      loading: LoadingComponent
    })
  },
  {
    path: "/project-info/:propertyName/locations",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/locations"),
      loading: LoadingComponent
    })
  },
  {
    path: "/project-info/:propertyName/building-details",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/building-details"),
      loading: LoadingComponent
    })
  },
  {
    path: "/project-info/:propertyName/amenities",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/amenities"),
      loading: LoadingComponent
    })
  },
  {
    path: "/project-info/:propertyName/floor-plans",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/floor-plans"),
      loading: LoadingComponent
    })
  },
  {
    path: "/project-info/:propertyName/unit-details",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/unit-details"),
      loading: LoadingComponent
    })
  },
  // {
  //   path: "/project-info/:propertyName/website-data",
  //   exact: true,
  //   authL: true,
  //   component: Loadable({
  //     loader: () => import("./pages/website-data"),
  //     loading: LoadingComponent
  //   })
  // },
  {
    path: "/project-info/:propertyName/availability/unit-pages",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/unit-pages"),
      loading: LoadingComponent
    })
  }
];
