import Loadable from "react-loadable";
import LoadingComponent from "../../commons/loader";

export default [
  {
    path: "/buyers-portal",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/Page"),
      loading: LoadingComponent
    })
  },
  {
    path: "/buyers-portal/login",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/content-management/login"),
      loading: LoadingComponent
    })
  },
  {
    path: "/buyers-portal/dashboard",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/content-management/dashboard"),
      loading: LoadingComponent
    })
  },
  {
    path: "/buyers-portal/remittance-slip",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/content-management/remittance-slip"),
      loading: LoadingComponent
    })
  },
  {
    path: "/buyers-portal/project-updates",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/content-management/project-updates"),
      loading: LoadingComponent
    })
  },
  {
    path: "/buyers-portal/request-and-inquiry",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/content-management/request-and-inquiry"),
      loading: LoadingComponent
    })
  },
  {
    path: "/buyers-portal/statement-of-accounts",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/content-management/statement-of-accounts"),
      loading: LoadingComponent
    })
  },
  {
    path: "/buyers-portal/official-receipt",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/content-management/official-receipt"),
      loading: LoadingComponent
    })
  },
  {
    path: "/buyers-portal/post-dated-checks",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/content-management/post-dated-checks"),
      loading: LoadingComponent
    })
  },
  {
    path: "/buyers-portal/automatic-debit-arrangement",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () =>
        import("./pages/content-management/automatic-debit-arrangement"),
      loading: LoadingComponent
    })
  },
  {
    path: "/buyers-portal/document-list",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/content-management/document-list"),
      loading: LoadingComponent
    })
  },
  {
    path: "/buyers-portal/my-account",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/content-management/my-account"),
      loading: LoadingComponent
    })
  },
  {
    path: "/buyers-portal/faq",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/content-management/faq"),
      loading: LoadingComponent
    })
  },
  {
    path: "/buyers-portal/faq/:faqId",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/content-management/faq/form"),
      loading: LoadingComponent
    })
  },
  {
    path: "/buyers-portal/terms-and-condition",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/content-management/terms-and-condition"),
      loading: LoadingComponent
    })
  },
  {
    path: "/buyers-portal/privacy-policy",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/content-management/privacy-policy"),
      loading: LoadingComponent
    })
  },
  {
    path: "/buyers-portal/pay-now-transaction",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/data-management/pay-now-transaction"),
      loading: LoadingComponent
    })
  },
  {
    path: "/buyers-portal/remittance-slip-list",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/data-management/remittance-slip-list"),
      loading: LoadingComponent
    })
  },
  {
    path: "/buyers-portal/inquiry-list",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/data-management/inquiry-list"),
      loading: LoadingComponent
    })
  },
  {
    path: "/buyers-portal/audit-trail",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/audit-trail"),
      loading: LoadingComponent
    })
  },
  {
    path: "/buyers-portal/project-newsletter",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/data-management/project-newsletter"),
      loading: LoadingComponent
    })
  },
  {
    path: "/buyers-portal/email-content",
    exact: true,
    authL: true,
    component: Loadable({
      loader: () => import("./pages/content-management/email-content"),
      loading: LoadingComponent
    })
  }
];
