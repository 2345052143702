// Action Types
export const actionTypes = {
  SELECTED_PROPERTY: "SELECTED_PROPERTY"
};

// Action creators
export const actionCreators = {
  selectProperty: property => ({
    type: actionTypes.SELECTED_PROPERTY,
    property
  })
};

// Reducer
const initialState = {
  selectedProperty: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SELECTED_PROPERTY:
      return Object.assign({}, state, {
        selectedProperty: action.property
      });

    default:
      return state;
  }
};
