// Action Types
export const actionTypes = {};

// Action creators
export const actionCreators = {};

// Reducer
const initialState = {
  isLoading: false,
  errors: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};
