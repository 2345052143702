import { Button, Col, Divider, Icon, Layout, Menu, message, Modal, Radio, Row, Select, Spin } from "antd";
// import localforage from 'localforage';
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { bindActionCreators } from "redux";
import { purgeStoredState } from "redux-persist";
import storage from "redux-persist/lib/storage";
import LoadingComponent from "./commons/loader";
import { actionCreators as themeActionCreators } from "./commons/ThemeManager/reducer";
import { actionCreators as projectInfoActionCreators } from "./modules/project-info/reducer";
import { adminMenu, buyerMenu, projectInfoMenu, sellerMenu, websiteBrands, websiteMenu } from "./utils/menu";
import { deleteToken } from "./utils/token";


const { Content, Footer, Header, Sider } = Layout;
const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;
const { Option, OptGroup } = Select;

class App extends Component {
  state = {
    logoutVisible: false,
    properties: []
  };

  static propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
    // user: PropTypes.object.isRequired,
    children: PropTypes.node.isRequired
  };

  onCollapse = collapsed => {
    this.setState({ collapsed });
  };

  showModal = () => {
    this.setState({
      logoutVisible: true
    });
  };

  onOk = () => {
    const persistConfig = {
      key: "rlsp",
      storage
    };

    const authPersistConfig = {
      key: "rlspauth",
      storage
    };

    deleteToken();
    purgeStoredState(persistConfig);
    purgeStoredState(authPersistConfig);
    window.location.href = "/";
  };

  hideModal = () => {
    this.setState({
      logoutVisible: false
    });
  };

  slugify = name => {
    return name.replace(/ /g, "-").toLowerCase();
  };

  handlePropertyChange = e => {
    const { properties } = this.props;
    if (properties.length === 0) {
      return message.warning(`Please wait while we're getting your data.`);
    }
    const selectedProperty = properties ? properties.find(item => item.id === e) : [];

    const path = this.props.children.props.match.url.split(/\//g);
    let feature = path[path.length - 1];
    if (feature === "project-info") {
      feature = "basic-info";
    }

    let location = selectedProperty ? `/project-info/${this.slugify(
      selectedProperty.property_name
    )}/${feature}` : '';

    if (path.length === 2) {
      location = selectedProperty ? `/project-info/${this.slugify(
        selectedProperty.property_name
      )}/basic-info` : '';
    }

    this.props.children.props.history.push(location);


    // setTimeout(() => {
    //   window.location.href = location;
    // }, 1000);
  };

  handleBrandChange = e => {
    let selectedBrand = websiteBrands().find(item => item.id === e);
    const path = this.props.children.props.match.url.split(/\//g);
    const feature = path[path.length - 1];

    let location = `/website/${this.slugify(selectedBrand.name)}/${feature}`;

    if (path.length === 2) {
      location = `/website/${this.slugify(selectedBrand.name)}/header`;
    }

    this.props.children.props.history.push(location);
  };

  componentDidMount() {
    const { properties, getManageContent } = this.props;
    if (this.props.isAuthenticated) {
      if (this.props.selectedTheme !== "website") {
        if (properties.length === 0) {
          this.props.getBrands();
          this.props.getProperties();
        }
      }
      if (this.props.selectedTheme === "admin") {
        getManageContent();
      }
    }
  }

  componentDidUpdate(prevProps) {
    const { updatePaymentMethod } = this.props;

    if (prevProps.updatePaymentMethod !== updatePaymentMethod) {
      message.success("Success Saved!");
    }
  }
  getAllowedBrands = (brands, permission) => {
    let availableBrands = [];
    let availableProperties = [];

    brands.map(brand => {
      if (permission && permission.brands.indexOf(brand.code) > -1) {
        availableBrands.push(brand);
        brand.properties.map(property => {
          if (property.status === "active") {
            availableProperties.push(property);
          }
        });
      }
    });

    return {
      availableBrands: availableBrands,
      availableProperties: availableProperties
    };
  };

  checkBrandCode = (brandCode, allow) => {
    return allow.indexOf(brandCode) > -1 ? true : false;
  };
  handleBank = ({ target }, portal) => {
    let value = {
      portal,
      bank: target.value
    };
    // console.log(this.props);
    this.props.updateManageContent(value);
  };
  render() {
    const {
      isAuthenticated,
      isLoading,
      selectedTheme,
      selectedSubMenu,
      selectedMenu,
      selectMenu,
      openSubMenu,
      profile,
      brands,
      properties,
      manageContent,
      updatePaymentMethod,
      isUpdating
    } = this.props;

    const sellerContent = updatePaymentMethod
      ? updatePaymentMethod.length !== 0
        ? updatePaymentMethod.find(value => value.portal === "seller")
        : manageContent.find(value => value.portal === "seller")
      : undefined;
    /* const buyerContent = updatePaymentMethod
      ? updatePaymentMethod.find(value => value.portal === "buyer")
      : manageContent.find(value => value.portal === "buyer");
 */
    const permission =
      profile && profile.user_role ? profile.user_role.access : null;
    const { availableBrands, availableProperties } = this.getAllowedBrands(
      brands,
      permission
    );

    let theDefaultSlug;
    if (permission) {
      theDefaultSlug = permission.brands[0];
    }

    let defaultSelected = websiteBrands().find(
      item => item.code === theDefaultSlug
    );

    const defaultKey = this.props.children.props.match.url;
    const selectedKey = this.props.children.props.match.url;
    let propertySlug = this.props.children.props.match.params.propertyName;
    let brandSlug = this.props.children.props.match.params.brandSlug
      ? this.props.children.props.match.params.brandSlug
      : defaultSelected
        ? this.slugify(defaultSelected.name)
        : null;
    // const userManagementAccess =
    //   profile && profile.is_admin ? profile.is_admin : null;

    const defaultProperty = availableProperties ? availableProperties[0] : null;
    if (availableProperties[0]) {
      propertySlug = propertySlug
        ? propertySlug
        : this.slugify(availableProperties[0].property_name);
    }
    const selectedProperty = availableProperties.find(
      row => this.slugify(row.property_name) === propertySlug
    );

    const defaultBrand = availableBrands ? availableBrands[0] : null;
    if (availableBrands[0]) {
      brandSlug = brandSlug ? brandSlug : this.slugify(availableBrands[0].name);
    }
    const selectedBrand = availableBrands.find(
      row => this.slugify(row.name) === brandSlug
    );
    const brandCode = selectedBrand ? selectedBrand.code : null;

    if (isAuthenticated) {
      // if (window.location.pathname !== "/") {
      if (isLoading) {
        return <LoadingComponent isLoading={isLoading} />;
      }

      return (
        <Layout className="dashboard" style={{ minHeight: "100vh" }}>
          <Header
            style={{
              background: "#fff",
              padding: 0,
              borderBottom: "1px solid #e8e8e8"
            }}
          >
            {selectedTheme !== "dashboard" ? (
              <div style={{ position: "absolute", top: "0px", left: "10px" }}>
                <Button
                  href="/dashboard"
                  ghost
                  style={{ borderColor: "#183E7C", color: "#183E7C" }}
                >
                  Back
                </Button>
              </div>
            ) : (
                ""
              )}
            <div className="logo">
              <img src="/assets/images/rlc-logo.png" alt="logo" />
            </div>
            <Menu
              mode="horizontal"
              style={{ lineHeight: "64px", float: "right" }}
            >
              <SubMenu
                title={
                  <span className="submenu-title-wrapper">
                    {profile.Firstname} {profile.Lastname}
                  </span>
                }
              >
                {/* {userManagementAccess ? ( */}
                <MenuItemGroup key="admin" title="Admin">
                  {adminMenu().map(row => {
                    const feature = row.link.replace(/-/g, "_");
                    if (
                      permission &&
                      permission.admin[feature] &&
                      !permission.admin[feature].view
                    ) {
                      return null;
                    }

                    return (
                      <Menu.Item key={`/admin/${row.link}`}>
                        <NavLink to={`/admin/${row.link}`}>{row.label}</NavLink>
                      </Menu.Item>
                    );
                  })}
                  <Divider />
                </MenuItemGroup>
                {/* ) : null} */}

                {/* <Menu.Item key="2-1">
                  <NavLink to={`/account-settings`}>Account Settings</NavLink>
                </Menu.Item> */}

                <Menu.Item key="2-2" onClick={this.showModal}>
                  <div className="menu-logout">
                    <Icon type="logout" />
                    <span>Signout</span>
                  </div>
                </Menu.Item>
              </SubMenu>
            </Menu>
          </Header>

          <Layout>
            {selectedTheme === "dashboard" ? (
              <Menu
                mode="horizontal"
                className="menu-horizontal"
                // defaultSelectedKeys={[defaultKey]}
                // selectedKeys={[selectedKey]}
                style={{ lineHeight: "64px" }}
              >
                <Menu.Item style={{ color: "white", fontSize: ".9rem" }}>
                  Welcome to your Content Management System. Please choose what
                  you want to manage to get it started.
                </Menu.Item>
              </Menu>
            ) : (
                <Menu
                  mode="horizontal"
                  className="menu-horizontal"
                  defaultSelectedKeys={[defaultKey]}
                  selectedkeys={[selectedKey]}
                  style={{ lineHeight: "64px" }}
                >
                  <Menu.Item key="/buyers-portal">
                    <NavLink to="/buyers-portal" className="nav-text">
                      BUYERS PORTAL
                  </NavLink>
                  </Menu.Item>
                  <Menu.Item key="/sellers-portal/">
                    <NavLink to="/sellers-portal/" className="nav-text">
                      SELLERS PORTAL
                  </NavLink>
                  </Menu.Item>
                  <Menu.Item
                    key={
                      defaultProperty
                        ? `/project-info/${this.slugify(
                          defaultProperty.property_name
                        )}/basic-info`
                        : ""
                    }
                  >
                    <NavLink
                      to={
                        defaultProperty
                          ? `/project-info/${this.slugify(
                            defaultProperty.property_name
                          )}/basic-info`
                          : ""
                      }
                      className="nav-text"
                    >
                      PROJECT INFO
                  </NavLink>
                  </Menu.Item>

                  <Menu.Item
                    key={
                      defaultBrand
                        ? `/website/robinsons-communities/header` ///${this.slugify(defaultBrand.name)}/header
                        : ""
                    }
                  >
                    <NavLink
                      to={
                        defaultBrand
                          ? `/website/robinsons-communities/header` ///${this.slugify(defaultBrand.name)}/header
                          : ""
                      }
                      className="nav-text"
                    >
                      WEBSITES
                  </NavLink>
                  </Menu.Item>
                </Menu>
              )}
            <Layout>
              {selectedTheme === "buyers-portal" ? (
                <Sider width={300} className="layout-sider">
                  <h1 className="sidebar-header">Buyer's Portal</h1>
                  <Menu
                    theme="light"
                    mode="inline"
                    defaultSelectedKeys={[defaultKey]}
                    selectedkeys={[selectedKey]}
                    openKeys={selectedSubMenu}
                    onSelect={selectMenu}
                    onOpenChange={openSubMenu}
                  >
                    {buyerMenu().map(menu => {
                      if (menu.sub) {
                        return (
                          <SubMenu
                            key={menu.link}
                            title={
                              <span>
                                <span>{menu.label}</span>
                              </span>
                            }
                          >
                            {menu.sub.map((sub, subIndex) => {
                              const module = selectedTheme.replace(/-/g, "_");
                              const feature = sub.link.replace(/-/g, "_");
                              if (
                                permission &&
                                !permission[module] &&
                                !permission[module][feature] &&
                                !permission[module][feature].view
                              ) {
                                return null;
                              }

                              return (
                                <Menu.Item key={`/buyers-portal/${sub.link}`}>
                                  <NavLink
                                    to={`/buyers-portal/${sub.link}`}
                                    className="nav-text"
                                  >
                                    <span>{sub.label}</span>
                                  </NavLink>
                                </Menu.Item>
                              );
                            })}
                          </SubMenu>
                        );
                      }

                      const module = selectedTheme.replace(/-/g, "_");
                      const feature = menu.link.replace(/-/g, "_");
                      if (permission && !permission[module][feature].view) {
                        return null;
                      }

                      return (
                        <Menu.Item key={`/buyers-portal/${menu.link}`}>
                          <NavLink
                            to={`/buyers-portal/${menu.link}`}
                            className="nav-text"
                          >
                            <span>{menu.label}</span>
                          </NavLink>
                        </Menu.Item>
                      );
                    })}
                  </Menu>
                </Sider>
              ) : null}

              {selectedTheme === "sellers-portal" ? (
                <Sider
                  width={300}
                  className="layout-sider"
                  breakpoint="lg"
                  collapsedWidth="0"
                >
                  <h1 className="sidebar-header">Seller's Portal</h1>
                  <Menu
                    theme="light"
                    mode="inline"
                    defaultSelectedKeys={[defaultKey]}
                    selectedkeys={[selectedKey]}
                    openKeys={selectedSubMenu}
                    onSelect={selectMenu}
                    onOpenChange={openSubMenu}
                  >
                    {sellerMenu().map(menu => {
                      if (menu.sub) {
                        return (
                          <SubMenu
                            key={menu.link}
                            title={
                              <span>
                                <span>{menu.label}</span>
                              </span>
                            }
                          >
                            {menu.sub.map((sub, subIndex) => {
                              const module = selectedTheme.replace(/-/g, "_");
                              const feature = sub.link.replace(/-/g, "_");
                              if (
                                permission &&
                                permission[module] &&
                                permission[module][feature] &&
                                !permission[module][feature].view
                              ) {
                                return null;
                              }

                              return (
                                <Menu.Item key={`/sellers-portal/${sub.link}`}>
                                  <NavLink
                                    to={`/sellers-portal/${sub.link}`}
                                    className="nav-text"
                                  >
                                    <span>{sub.label}</span>
                                  </NavLink>
                                </Menu.Item>
                              );
                            })}
                            {menu.subMenu.map((row, index) => {
                              return (
                                <SubMenu key={index} title={row.label}>
                                  {row.sub.map((sub, subIndex) => {
                                    const module = selectedTheme.replace(
                                      /-/g,
                                      "_"
                                    );
                                    const feature = sub.link.replace(/-/g, "_");
                                    if (
                                      permission &&
                                      permission[module] &&
                                      permission[module][feature] &&
                                      !permission[module][feature].view
                                    ) {
                                      return null;
                                    }

                                    return (
                                      <Menu.Item
                                        key={`/sellers-portal/${sub.link}`}
                                      >
                                        <NavLink
                                          to={`/sellers-portal/${sub.link}`}
                                          className="nav-text"
                                        >
                                          <span>{sub.label}</span>
                                        </NavLink>
                                      </Menu.Item>
                                    );
                                  })}
                                </SubMenu>
                              );
                            })}
                          </SubMenu>
                        );
                      }

                      const module = selectedTheme.replace(/-/g, "_");
                      const feature = menu.link.replace(/-/g, "_");
                      if (
                        permission &&
                        permission[module] &&
                        permission[module][feature] &&
                        !permission[module][feature].view
                      ) {
                        return null;
                      }

                      return (
                        <Menu.Item key={`/sellers-portal/${menu.link}`}>
                          <NavLink
                            to={`/sellers-portal/${menu.link}`}
                            className="nav-text"
                          >
                            <span>{menu.label}</span>
                          </NavLink>
                        </Menu.Item>
                      );
                    })}
                  </Menu>
                </Sider>
              ) : null}

              {selectedTheme === "project-info" ? (
                selectedProperty ? (
                  <Sider width={300} className="layout-sider">
                    <div>
                      <Select
                        defaultValue={selectedProperty.id}
                        className="property-selection"
                        dropdownClassName="property-selection__dropdown"
                        onChange={e => this.handlePropertyChange(e)}
                        style={{ width: "8em", height: "2.7em" }}
                      >
                        {availableBrands.map((brand, brandIndex) => {
                          return (
                            <OptGroup label={brand.name} key={brandIndex}>
                              {brand.properties.map((row, i) => {
                                if (row.status === "inactive") {
                                  return false;
                                }

                                return (
                                  <Option key={i} value={row.id}>
                                    <div
                                      style={{ textTransform: "capitalize" }}
                                    >
                                      {row.property_name.toLowerCase()}
                                    </div>
                                  </Option>
                                );
                              })}
                            </OptGroup>
                          );
                        })}
                      </Select>
                    </div>
                    <div className="selected-brand">
                      {selectedProperty ? selectedProperty.brand.name : ""}
                    </div>

                    <Divider />

                    <Menu
                      theme="light"
                      mode="inline"
                      defaultSelectedKeys={[defaultKey]}
                      selectedkeys={[selectedKey]}
                      openKeys={selectedSubMenu}
                      onSelect={selectMenu}
                      onOpenChange={openSubMenu}
                    >
                      {projectInfoMenu().map(menu => {
                        if (menu.sub) {
                          return (
                            <SubMenu
                              key={menu.link}
                              title={
                                <span>
                                  <span>{menu.label}</span>
                                </span>
                              }
                            >
                              {menu.sub.map((sub, subIndex) => {
                                const module = selectedTheme.replace(/-/g, "_");
                                const feature = sub.link.replace(/-/g, "_");
                                if (
                                  permission &&
                                  !permission[module][feature].view
                                ) {
                                  return null;
                                }

                                return (
                                  <Menu.Item
                                    key={`/project-info/${propertySlug}/${sub.link}`}
                                  >
                                    <NavLink
                                      to={`/project-info/${propertySlug}/${sub.link}`}
                                      className="nav-text"
                                    >
                                      <span>{sub.label}</span>
                                    </NavLink>
                                  </Menu.Item>
                                );
                              })}
                            </SubMenu>
                          );
                        }

                        const module = selectedTheme.replace(/-/g, "_");
                        const feature = menu.link.replace(/-/g, "_");
                        if (permission && !permission[module][feature].view) {
                          return null;
                        }

                        return (
                          <Menu.Item
                            key={`/project-info/${propertySlug}/${menu.link}`}
                          >
                            <NavLink
                              to={`/project-info/${propertySlug}/${menu.link}`}
                              className="nav-text"
                            >
                              <span>{menu.label}</span>
                            </NavLink>
                          </Menu.Item>
                        );
                      })}
                    </Menu>
                  </Sider>
                ) : (
                    <div>
                      NO SELECTED PROPERTY BECAUSE THIS USER HAS NO PERMISSION TO
                      ANY BRANDS
                    </div>
                  )
              ) : null}

              {selectedTheme === "website" ? (
                <Sider
                  width={300}
                  className="layout-sider"
                  style={{
                    // height: "calc(100vh - 128px)",
                    // height: "100%",
                    height: "100vh",
                    overflowY: "scroll",
                    position: "sticky",
                    top: 0
                  }}
                >
                  <div>
                    <Select
                      showSearch
                      defaultValue={
                        selectedBrand
                          ? selectedBrand.name === "Robinsons Communities"
                            ? "RLC Residences"
                            : selectedBrand.name
                          : "Robinsons Omnibus"
                      } //{selectedBrand ? selectedBrand.id : 0}
                      value={
                        selectedBrand
                          ? selectedBrand.name === "Robinsons Communities"
                            ? "RLC Residences"
                            : selectedBrand.name
                          : "Robinsons Omnibus"
                      }
                      className="property-selection"
                      dropdownClassName="property-selection__dropdown"
                      onChange={e => this.handleBrandChange(e)}
                    >
                      {websiteBrands().map(brand => {
                        if (
                          permission &&
                          permission.brands.indexOf(brand.code) === -1
                        ) {
                          return null;
                        }
                        return (
                          <Option key={brand.id} value={brand.id}>
                            {brand.name === "Robinsons Communities"
                              ? "RLC Residences"
                              : brand.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </div>

                  <div>
                    <Menu
                      theme="light"
                      mode="inline"
                      defaultSelectedKeys={[defaultKey]}
                      selectedkeys={[selectedKey]}
                      openKeys={selectedSubMenu}
                      onSelect={selectMenu}
                      onOpenChange={openSubMenu}
                    >
                      {websiteMenu().map((menu, index) => {
                        if (menu.divider) {
                          return <Menu.Divider key={index} />;
                        }

                        const module = selectedTheme.replace(/-/g, "_");
                        const feature = menu.link.replace(/-/g, "_");

                        if (
                          permission &&
                          permission[module][feature] && // BALIKAN MO AKO
                          !permission[module][feature].view
                        ) {
                          return null;
                        }

                        if (
                          menu.allow &&
                          !this.checkBrandCode(brandCode, menu.allow)
                        ) {
                          return null;
                        }

                        if (menu.sub) {
                          return (
                            <SubMenu
                              key={index}
                              title={
                                <span>
                                  <span>{menu.label}</span>
                                </span>
                              }
                            >
                              {menu.sub.map(sub => {
                                const module = selectedTheme.replace(/-/g, "_");
                                const feature = sub.link.replace(/-/g, "_");
                                if (
                                  permission &&
                                  permission[module][feature] && // BALIKAN MO AKO
                                  !permission[module][feature].view
                                ) {
                                  return null;
                                }

                                if (
                                  sub.allow &&
                                  !this.checkBrandCode(brandCode, sub.allow)
                                ) {
                                  return null;
                                }

                                return (
                                  <Menu.Item
                                    key={`/website/${brandSlug}/${sub.link}`}
                                  >
                                    <NavLink
                                      to={`/website/${brandSlug}/${sub.link}`}
                                      className="nav-text"
                                    >
                                      <span>{sub.label}</span>
                                    </NavLink>
                                  </Menu.Item>
                                );
                              })}
                            </SubMenu>
                          );
                        }

                        return (
                          <Menu.Item key={`/website/${brandSlug}/${menu.link}`}>
                            <NavLink
                              to={`/website/${brandSlug}/${menu.link}`}
                              className="nav-text"
                            >
                              <span>{menu.label}</span>
                            </NavLink>
                          </Menu.Item>
                        );
                      })}
                    </Menu>
                  </div>
                </Sider>
              ) : null}

              {selectedTheme === "admin" ? (
                <Sider width={300} className="layout-sider">
                  <h1 className="sidebar-header">Admin</h1>
                  <Menu
                    theme="light"
                    mode="inline"
                    defaultSelectedKeys={[defaultKey]}
                    selectedkeys={[selectedKey]}
                    openKeys={selectedSubMenu}
                    onSelect={selectMenu}
                    onOpenChange={openSubMenu}
                  >
                    {adminMenu().map(menu => {
                      if (menu.sub) {
                        return (
                          <SubMenu
                            key={menu.link}
                            title={
                              <span>
                                <span>{menu.label}</span>
                              </span>
                            }
                          >
                            {menu.sub.map((sub, subIndex) => {
                              const module = selectedTheme.replace(/-/g, "_");
                              const feature = sub.link.replace(/-/g, "_");
                              if (
                                permission &&
                                !permission[module][feature].view
                              ) {
                                return null;
                              }

                              return (
                                <Menu.Item key={`/admin/${sub.link}`}>
                                  <NavLink
                                    to={`/admin/${sub.link}`}
                                    className="nav-text"
                                  >
                                    <span>{sub.label}</span>
                                  </NavLink>
                                </Menu.Item>
                              );
                            })}
                          </SubMenu>
                        );
                      }

                      const module = selectedTheme.replace(/-/g, "_");
                      const feature = menu.link.replace(/-/g, "_");
                      if (
                        permission &&
                        permission[module][feature] &&
                        !permission[module][feature].view
                      ) {
                        return null;
                      }

                      return (
                        <Menu.Item key={`/admin/${menu.link}`}>
                          <NavLink
                            to={`/admin/${menu.link}`}
                            className="nav-text"
                          >
                            <span>{menu.label}</span>
                          </NavLink>
                        </Menu.Item>
                      );
                    })}
                    <Spin spinning={isUpdating}>
                      {/* <Row style={{ marginLeft: "1.5em" }}>
                        <b>Payment Method Buyer's</b>
                      </Row>
                      <Row style={{ marginLeft: "1.5em", marginTop: "1em" }}>
                        <Radio.Group
                          loading={isUpdating}
                          defaultValue={
                            buyerContent !== undefined
                              ? buyerContent.contents.payment_method.RBank
                                ? "rbank"
                                : "globalpay"
                              : null
                          }
                          onChange={value => this.handleBank(value, "buyer")}
                        >
                          <Radio value={"rbank"}>RBank</Radio>
                          <Radio value={"globalpay"}>Global Pay</Radio>
                        </Radio.Group>
                      </Row>
                       */}
                      <Row style={{ marginLeft: "1.5em", marginTop: "1em" }}>
                        <b>Payment Method Seller's</b>
                      </Row>
                      <Row style={{ marginLeft: "1.5em", marginTop: "1em" }}>
                        <Radio.Group
                          defaultValue={
                            sellerContent !== undefined
                              ? sellerContent.contents.payment_method.RBank
                                ? "rbank"
                                : "globalpay"
                              : null
                          }
                          onChange={value => this.handleBank(value, "seller")}
                        >
                          <Radio value={"rbank"}>RBank</Radio>
                          <Radio value={"globalpay"}>Global Pay</Radio>
                        </Radio.Group>
                      </Row>
                    </Spin>
                  </Menu>
                </Sider>
              ) : null}

              <Content style={{ padding: "0 16px 20px" }}>
                {React.cloneElement(this.props.children, { ...this.props })}
              </Content>
            </Layout>
          </Layout>

          <Modal
            title="Signout"
            visible={this.state.logoutVisible}
            onOk={this.onOk}
            onCancel={this.hideModal}
            okText="Ok"
            cancelText="Cancel"
          >
            <p>Are you sure you want to signout?</p>
          </Modal>
        </Layout>
      );
    }

    return (
      <div className="app">
        <main className="main">
          <Layout id="auth">
            <Content>
              <div>{this.props.children}</div>
            </Content>
            <Footer className="hide">
              <Row className="text-center auth-footer">
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 8 }}
                >
                  <img src="/assets/images/logo.png" alt="logo" />
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 8 }}
                >
                  <p style={{ margin: "10px 0 6px" }}>
                    {/* <Link to="/">PRIVACY POLICY</Link> /{" "}
                    <Link to="/">TERMS & CONDITIONS</Link> /{" "}
                    <Link to="/">CONTACT US</Link> */}
                  </p>
                </Col>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 8 }}
                  className="footer-socials"
                >
                  <Link to="/">
                    <Icon
                      type="facebook"
                      theme="outlined"
                      style={{ color: "#1E417B", fontSize: "2em" }}
                    />
                  </Link>

                  <Link to="/">
                    <Icon
                      type="twitter"
                      theme="outlined"
                      style={{ color: "#1E417B", fontSize: "2em" }}
                    />
                  </Link>

                  <Link to="/">
                    <Icon
                      type="youtube"
                      theme="outlined"
                      style={{ color: "#1E417B", fontSize: "2em" }}
                    />
                  </Link>
                </Col>
              </Row>
            </Footer>
          </Layout>
        </main>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(themeActionCreators, dispatch),
  ...bindActionCreators(projectInfoActionCreators, dispatch)
});

const mapStateToProps = (state, ownState) => ({
  isAuthenticated: state.auth.isAuthenticated,
  selectedTheme: state.theme.selectedTheme,
  selectedMenu: state.theme.selectedMenu,
  selectedSubMenu: state.theme.submenu,
  selectedProperty: state.cmsHoc.selectedProperty,
  profile: state.profile.profile,
  properties: state.projectInfo.data,
  brands: state.projectInfo.brands,
  state: state,
  manageContent: state.projectInfo.manageContent,
  updatePaymentMethod: state.projectInfo.updatePaymentMethod,
  isUpdating: state.projectInfo.isUpdating
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
