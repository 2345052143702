export const defaultWebContent = () => {
  return {
    id: 5,
    created_at: "2019-05-24 11:42:57",
    updated_at: "2019-07-01 05:26:20",
    brand_id: 0,
    contents: {
      appointment: {
        show_headline: true,
        headline: "Set an appointment",
        show_sub_headline: true,
        sub_headline:
          "Send a request for an appointment for your free site tour",
        featured_properties: [],
        seo_description: "seo appointment description",
        seo_keyword: "seo, appointment, keyword",
        time_slot: [
          {
            id: "14504f05-7fc3-155d-c39b-b1996eb429d0",
            start_time: "09:00",
            end_time: "10:30"
          },
          {
            id: "a1c52765-deb1-078a-06fb-e269f825ad8a",
            start_time: "10:30",
            end_time: "12:00"
          },
          {
            id: "cde2296a-b72c-8980-5d63-42fea9fed7e0",
            start_time: "13:30",
            end_time: "15:00"
          },
          {
            id: "5b99f7e6-6485-f42a-679a-4eaf0757843b",
            start_time: "15:00",
            end_time: "16:30"
          },
          {
            id: "3135bb67-a066-888f-1d85-5072b5f500e5",
            start_time: "16:30",
            end_time: "18:00"
          },
          {
            id: "bac2ecc5-160b-1960-7534-fbb62a9b98ed",
            start_time: "18:00",
            end_time: "19:30"
          }
        ],
        image: "website/robinsons-omnibus/contact-page/5cf90a0583c5f.png",
        mobile_image: "website/robinsons-omnibus/contact-page/5d0334248612f.png"
      },
      fonts: {
        header: "",
        body: "",
        button: ""
      },
      article: {
        banner: null,
        headline: "Listening and taking inspiration from its customers.",
        show_headline: true,
        sub_headline:
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        show_sub_headline: true,
        submit_button_text: "Submit"
      },
      header: {
        logo: "website/robinsons-omnibus/5cf7b94f49838.png",
        logo_alternate: "website/robinsons-omnibus/5d0dd53bcf934.png"
      },
      footer: {
        logo_link: "https://www.robinsonsland.com/",
        second_logo_link: "https://www.jgsummit.com.ph/",
        logo: "website/robinsons-omnibus/5d14a42ea4e97.png",
        second_logo: "website/robinsons-omnibus/5d14a42f82248.png"
      },
      home: {
        banner: {
          show_headline: true,
          headline: "Come home to your dreams. Come home to Robinsons Land.",
          show_sub_headline: true,
          sub_headline:
            "We are committed to making the lives of Filipinos more meaningful. It develops vibrant, delightful and lasting live-work-play environment that serves as perfect setting for new opportunities and a better life.",
          show_property_button: true,
          property_button_text: "See Properties",
          show_watch_button: true,
          watch_button_text: "Watch Video",
          side_headline:
            '<a href="mailto:imservices@robinsonsland.com">Join us</a> and become a Marketing Partner!',
          side_sub_headline:
            '<a href="mailto:imservices@robinsonsland.com">Click here</a> to send us an email',
          seo_description: "seo banner description",
          seo_keyword: "seo, keyword, separated, by, comma",
          image: "website/robinsons-omnibus/landing-page/5d0dcb82b2133.png",
          mobile_image:
            "website/robinsons-omnibus/landing-page/5d0dcb9fab5ac.png"
        },
        featured_properties: {
          card_link_text: "Explore Projects",
          featured_brands: [
            {
              show: true,
              name: "ROBINSONS LUXURIA",
              code: "ZRLU",
              properties: [40033, 40021]
            },
            {
              show: true,
              name: "ROBINSONS RESIDENCES",
              code: "ZRRE",
              properties: [40014, 40019]
            },
            {
              show: true,
              name: "ROBINSONS COMMUNITIES",
              code: "ZRCO",
              properties: [40025, 40027]
            }
          ]
        },
        project_location: {
          headline: null,
          show_headline: false,
          sub_headline: null,
          show_sub_headline: false,
          show_location: false
        },
        news_and_promo: {
          headline: "Awards or Promos",
          show_headline: true,
          sub_headline: "Welcoming Your home",
          show_sub_headline: true,
          buttons: [
            {
              label: "View More",
              type: "link",
              link: "/news-and-promo",
              show: true
            }
          ]
        },
        latest_news: {
          headline: "Latest News",
          show_headline: true,
          sub_headline: "Everything that’s new.",
          show_sub_headline: true,
          link_button_text: "View More",
          show_link_button: true
        },
        bulletin: {
          headline: null,
          show_headline: false,
          sub_headline: null,
          show_sub_headline: false,
          link_button_text: null,
          show_link_button: false
        },
        map: {
          headline: null,
          show_headline: false,
          address: null,
          show_address: false,
          landline: null,
          show_landline: false,
          mobile: null,
          show_mobile: false,
          email: null,
          show_email: false
        },
        abc_living: {
          headline: null,
          show_headline: false,
          sub_headline: null,
          show_sub_headline: false,
          cards: [
            {
              image: null,
              title: null,
              description: null
            }
          ],
          remarks: null,
          show_remarks: false
        },
        headline: {
          show_headline: true,
          headline: "Latest News",
          show_sub_headline: true,
          sub_headline: "Everything that's new.",
          show_link_button: true,
          link_button_text: "View More"
        }
      },
      properties: {
        show_headline: true,
        headline: "Our Brands",
        show_sub_headline: true,
        sub_headline:
          "Whether you're looking for a luxurious condo with an iconic address, a spacious townhome just outside the metro, or a sprawling house in the suburbs, the possibilities are endless with Robinsons Land Corporation",
        show_property_button: true,
        property_button_text: "View Property",
        seo_description: "seo properties description",
        seo_keyword: "seo, properties, keyword",
        mobile_image:
          "website/robinsons-omnibus/property-page/5d156705b6934.png",
        properties: []
      },
      property_details: {
        show_project_brief: true,
        project_brief: "Project Overview.",
        show_location: true,
        location: "Location",
        show_gallery: true,
        gallery: "Gallery",
        show_contact: true,
        contact_headline:
          "Listening and taking inspiration from its customers.",
        contact_sub_headline:
          "Feel free to contact us about any of our properties through the form below, and we shall respond within 3 working days.",
        contact_button_text: "Send Form",
        seo_description: "seo properties description",
        seo_keyword: "seo, properties, keyword"
      },
      news_and_articles: {
        show_headline: true,
        headline: "We got everything you want to know",
        show_sub_headline: true,
        sub_headline:
          "See the latest press releases, promotions and updates from the \n<br>Residential Division of Robinsons Land Corporation.",
        show_subscribe_headline: true,
        subscribe_headline:
          "Subscribe to the newsletter of Robinsons Properties today!",
        show_subscribe_sub_headline: true,
        subscribe_sub_headline:
          "Keep yourself up to date with the latest press releases, promotions and updates from the Residential Division of Robinsons Land Corporation by subscribing to our newsletter.",
        subscribe_button_text: "Subscribe",
        featured: [
          {
            id: "8b2107e9-89bc-e452-1819-745ae7094c7a",
            news_id: 57
          },
          {
            id: "8cf94073-22d8-fa25-d07c-c6a2abd2ac9b",
            news_id: 27
          },
          {
            id: "d05e1706-c735-eac7-ff31-6b3a2c17b201",
            news_id: 59
          }
        ],
        seo_description: "seo properties description",
        seo_keyword: "seo, properties, keyword",
        category: [],
        image: "website/robinsons-omnibus/news-page/5cf76a5e475cf.png",
        mobile_image: "website/robinsons-omnibus/news-page/5d033401dc0cd.png"
      },
      calculator: {
        show_headline: true,
        headline: "Investment Calculator",
        show_sub_headline: true,
        sub_headline:
          "Check the investment calculator below to see what Robinsons Land property fits your budget.",
        first_note:
          "If you have a $annual-income$ annual income. You can comfortably afford a condominium unit priced approximately at $unit-price$. To pay for the balance of your unit you can avail of a loan that costs you $monthly-amortization$ a month.",
        second_note:
          "For the chosen unit priced approximately at $unit-price$ you would roughly need to have an annual net income of $annual-income$ or approximately a monthly net income of $monthly-income$. At this rate you would be comfortable in spending around $monthly-amortization$ a month to pay for the balance of your unit.",
        minimum_price: "2000000",
        maximum_price: "20000000",
        interval_price: "250000",
        minimum_loan_term: "1",
        maximum_loan_term: "30",
        interval_loan_term: "1",
        minimum_interest_rate: "0.045",
        maximum_interest_rate: ".115",
        interval_interest_rate: ".03",
        seo_description: "seo calculator description",
        seo_keyword: "seo, calculator, keyword",
        image: "website/robinsons-omnibus/calculator-page/5cf7c6c6bda94.png",
        mobile_image:
          "website/robinsons-omnibus/calculator-page/5d03343b90e33.png"
      },
      contact_us: {
        show_headline: true,
        headline: "Listening and taking inspiration from its customers.",
        show_sub_headline: true,
        sub_headline:
          "Feel free to contact us about any of our properties through the form below",
        submit_button_text: "Submit",
        show_firstname: true,
        show_lastname: true,
        show_mobile: true,
        show_email: true,
        show_source_awareness: true,
        show_message: true,
        seo_description: "seo properties description",
        seo_keyword: "seo, properties, keyword",
        source_awareness: [
          {
            id: "ed334b32-ba72-f9c3-5958-7dcea298ec87",
            item: "OOH (Lamp Post Banner, Billboard, Fence Ads)"
          },
          {
            id: "46e4b6ab-f318-a204-31a8-e6a3b4394b64",
            item:
              "Social Media/Digital Marketing Platforms (Facebook, Twitter, Instagram)"
          },
          {
            id: "eae1319c-d2df-0daf-1af6-2cd004348feb",
            item: "Sales Booths"
          },
          {
            id: "1aa5008b-7c98-57b1-aae2-80facbfe6a69",
            item: "Model Units/Showroom"
          },
          {
            id: "3aa33e8a-428c-5fe3-ec91-ebe38f4fde6e",
            item: "Printed Materials (Flyer, Brochure)"
          },
          {
            id: "7094239c-0a5c-578a-97fe-7decbefee624",
            item: "Internet/Website"
          },
          {
            id: "ad43265a-0d8e-f9ab-fb2d-73ba72611b81",
            item: "Print Ads/Press Releases"
          },
          {
            id: "2fce4374-a299-80b0-0dfd-f1c92cc2aa57",
            item: "Events"
          },
          {
            id: "f42b2d01-3175-1761-f764-f7b26fa0b935",
            item: "Cinema Ad"
          },
          {
            id: "051f1f90-c3cc-cc0d-3377-5d14fa34ab4a",
            item: "Direct Mail"
          },
          {
            id: "80ad7a8e-f782-c71c-4575-4f1e9b775134",
            item: "Text Blast"
          },
          {
            id: "f3686172-fb3a-a478-3917-a6ebdb971107",
            item: "Referral"
          },
          {
            id: "c8017b5b-3b23-f160-f576-38d407b2705b",
            item: "Word-of-mouth"
          },
          {
            id: "4d9a971b-edc1-4b1a-c71c-0bd460726415",
            item: "Television"
          },
          {
            id: "ca191b91-9a73-6ee6-efa0-002138972f04",
            item: "Radio"
          }
        ],
        image: "website/robinsons-communities/contact-page/5ce7bdd8bdc50.png",
        mobile_image:
          "website/robinsons-communities/contact-page/5d099117ac04e.png"
      },
      faq: {
        show_headline: true,
        headline: "Questions? You're in the right place!",
        show_sub_headline: true,
        sub_headline:
          "Whether you're looking for a luxurious condo with an iconic address, a spacious townhome just outside; we got the information for you.",
        seo_description: "seo faq description",
        seo_keyword: "seo, faq, keyword",
        image: "website/robinsons-omnibus/property-page/5d0320fd11cbd.png",
        mobile_image:
          "website/robinsons-omnibus/property-page/5d0320fd11cbd.png"
      },
      inner_properties: {
        property_button_text: "View Property",
        brands: [
          {
            id: "3ad9cded-fbca-f2c4-f80b-010fc64d3e8d",
            image:
              "website/robinsons-omnibus/inner-properties-page/5d06ff201bf96.png",
            mobile_image:
              "website/robinsons-omnibus/inner-properties-page/5d06ff220156b.png",
            brand_code: "ZRRE"
          },
          {
            id: "60a45035-01e2-3577-6975-17b8d482026f",
            image:
              "website/robinsons-omnibus/inner-properties-page/5d06ff23e8d92.png",
            mobile_image:
              "website/robinsons-omnibus/inner-properties-page/5d06ff25665a6.png",
            brand_code: "ZRCO"
          },
          {
            id: "a039e584-6b87-cd76-64c7-f1e59b8ba8e4",
            image:
              "website/robinsons-omnibus/inner-properties-page/5d06ff26e16a5.png",
            mobile_image:
              "website/robinsons-omnibus/inner-properties-page/5d06ff28debb9.png",
            brand_code: "ZRLU"
          },
          {
            id: "ee10ab71-3ebc-758f-7b4c-a84f28cedaa2",
            image:
              "website/robinsons-omnibus/inner-properties-page/5d06ff2a9d3a5.png",
            mobile_image:
              "website/robinsons-omnibus/inner-properties-page/5d06ff2c99a40.png",
            brand_code: "HOMES"
          }
        ]
      },
      careers: {
        show_headline: true,
        headline: "Careers",
        show_sub_headline: true,
        sub_headline:
          "This is an opportunity for you to step in our world and grow with us. Come and join us",
        seo_description: "seo careers description",
        seo_keyword: "seo, careers, keyword",
        image: "website/robinsons-omnibus/careers-page/5cf768a3ba116.png",
        mobile_image: "website/robinsons-omnibus/careers-page/5d03345df1085.png"
      },
      compare: {
        show_headline: true,
        headline: "Compare Properties",
        show_sub_headline: true,
        sub_headline:
          "Want to make sure you're getting the real deal with our units? Compare them and see their differences.",
        compare_button_text: "Add Properties Here...",
        show_price_range: true,
        show_floor_area: false,
        show_unit_type: true,
        show_location: true,
        show_amenities: true,
        show_description: true,
        seo_description: "seo compare properties description",
        seo_keyword: "seo, compare, properties, keyword",
        image: "website/robinsons-omnibus/career-page/5cf2762dab49f.png",
        mobile_image: "website/robinsons-omnibus/career-page/5cf2762dab49f.png"
      },
      testimonials: {
        show_headline: true,
        headline: "Real stories",
        show_sub_headline: true,
        sub_headline:
          "Exciting updates about our properties and latest promos for our you!\tThis includes press releases, events and promos, and construction updates.",
        seo_description: "seo testimonials description",
        seo_keyword: "seo, testimonials, keyword",
        image: "website/robinsons-omnibus/testimonial-page/5cf7e171463be.png",
        mobile_image:
          "website/robinsons-omnibus/testimonial-page/5d0334cb26699.png"
      },
      search_result: {
        show_headline: true,
        headline: "Search Results",
        show_sub_headline: true,
        sub_headline:
          "The following properties are the search results based on your preferences",
        seo_description: "seo search description",
        seo_keyword: "seo, search, result, keyword",
        image: "website/robinsons-omnibus/search-page/5cf7787bc6fbb.png",
        mobile_image: "website/robinsons-omnibus/search-page/5d0333e954368.png"
      },
      contact_information: {
        unit: "Lower Level East Lane",
        street: "Robinsons Galleria, EDSA cor Ortigas",
        city: "Quezon City",
        landline: "+1-404-806-7482",
        mobile: "+1-404-806-7482",
        email: "imservices@robinsonsland.com",
        sns: [
          {
            id: "0965da28-f668-7bb5-896b-fb049f61f9f4",
            type: "facebook",
            label: "@InternationalRLC",
            link: "https://www.facebook.com/InternationalRLC"
          }
        ]
      },
      email_content: {
        inquiry: "<p><br></p>",
        appointment: null,
        subscription: null,
        careers: null
      }
    }
  };
};
