import { takeEvery, all } from "redux-saga/effects";
import { actionTypes as contentActionTypes } from "./reducer";
import newsletterSubscriptionsSaga from "./pages/newsletter-subscriptions/saga";
import {
  getProperties as getPropertiesService,
  getWebContent as getWebContentService,
  postWebContent as postWebContentService,
  getWebFaqCategory as getWebFaqCategoryService,
  postWebFaqCategory as postWebFaqCategoryService,
  putWebFaqCategory as putWebFaqCategoryService,
  deleteWebFaqCategory as deleteWebFaqCategoryService,
  getWebFaq as getWebFaqService,
  postWebFaq as postWebFaqService,
  putWebFaq as putWebFaqService,
  deleteWebFaq as deleteWebFaqService,
  getWebPopupPromo as getWebPopupPromoService,
  postWebPopupPromo as postWebPopupPromoService,
  putWebPopupPromo as putWebPopupPromoService,
  deleteWebPopupPromo as deleteWebPopupPromoService,
  getWebTestimonials as getWebTestimonialsService,
  postWebTestimonials as postWebTestimonialsService,
  putWebTestimonials as putWebTestimonialsService,
  deleteWebTestimonials as deleteWebTestimonialsService,
  getWebNews as getWebNewsService,
  postWebNews as postWebNewsService,
  putWebNews as putWebNewsService,
  deleteWebNews as deleteWebNewsService,
  getWebCareer as getWebCareerService,
  postWebCareer as postWebCareerService,
  putWebCareer as putWebCareerService,
  deleteWebCareer as deleteWebCareerService,
  getWebCareerApplication as getWebCareerApplicationService,
  postWebCareerApplication as postWebCareerApplicationService,
  putWebCareerApplication as putWebCareerApplicationService,
  deleteWebCareerApplication as deleteWebCareerApplicationService,
  postWebRecipients as postWebRecipientService,
  getWebRecipients as getWebRecipientService,
  getWebCareersRecipients as getWebCareersRecipientsService,
  getWebPropertyInquiries as getWebPropertyInquiriesService,
  getWebAppointmentInquiries as getWebAppointmentInquiriesService,
  deletePropertyInquiry as deletePropertyInquiryService,
  deleteAppointmentInquiry as deleteAppointmentInquiryService,
  getSoftDeletedWebAppointments as getSoftDeletedWebAppointmentsService,
  getSoftDeletedWebCareerApplication as getSoftDeletedWebCareerApplicationService,
  getSoftDeletedWebPropertyInquiries as getSoftDeletedWebPropertyInquiriesService,
  postRestoreWebPropertyInquiries as postRestoreWebPropertyInquiriesService,
  postExportWebPropertyInquiries as postExportWebPropertyInquiriesService,
  postRestoreWebAppointments as postRestoreWebAppointmentsService,
  postExportWebAppointments as postExportWebAppointmentsService,
  postRestoreWebCareerApplication as postRestoreWebCareerApplicationService,
  postExportWebCareerApplication as postExportWebCareerApplicationService,
  getWebInquiryRecipients as getWebInquiryRecipientsService,
  getWebLocations as getWebLocationsService,
  postWebLocations as postWebLocationsService,
  deleteWebLocations as deleteWebLocationsService,
  editWebLocations as editWebLocationsService,
  //awards
  getWebAwards as getWebAwardsService,
  postWebAwards as postWebAwardsService,
  deleteWebAwards as deleteWebAwardsService,
  editWebAwards as editWebAwardsService,

  //awards years
  getWebAwardsYears as getWebAwardsYearsService,
  postWebAwardsYears as postWebAwardsYearsService,
  deleteWebAwardsYears as deleteWebAwardsYearsService,
  editWebAwardsYears as editWebAwardsYearsService,
} from "./service";
import processAction from "../../utils/processAction";

// WEB CONTENT
function* getProperties(action) {
  yield processAction({
    ...action,
    service: getPropertiesService,
    success: contentActionTypes.GET_ALL_PROPERTIES_SUCCESS,
    failed: contentActionTypes.GET_ALL_PROPERTIES_FAILED,
  });
}

function* getWebInquiryRecipients(action) {
  yield processAction({
    ...action,
    service: getWebInquiryRecipientsService,
    success: contentActionTypes.GET_WEB_INQUIRY_RECIPIENTS_SUCCESS,
    failed: contentActionTypes.GET_WEB_INQUIRY_RECIPIENTS_SUCCESS,
  });
}

function* postExportWebCareerApplication(action) {
  yield processAction({
    ...action,
    service: postExportWebCareerApplicationService,
    success: contentActionTypes.POST_EXPORT_WEB_CAREER_APPLICATION_SUCCESS,
    failed: contentActionTypes.POST_EXPORT_WEB_CAREER_APPLICATION_FAILED,
  });
}

function* postRestoreWebCareerApplication(action) {
  yield processAction({
    ...action,
    service: postRestoreWebCareerApplicationService,
    success: contentActionTypes.POST_RESTORE_WEB_CAREER_APPLICATION_SUCCESS,
    failed: contentActionTypes.POST_RESTORE_WEB_CAREER_APPLICATION_FAILED,
  });
}

function* postExportWebAppointments(action) {
  yield processAction({
    ...action,
    service: postExportWebAppointmentsService,
    success: contentActionTypes.POST_EXPORT_WEB_APPOINTMENT_INQUIRIES_SUCCESS,
    failed: contentActionTypes.POST_EXPORT_WEB_APPOINTMENT_INQUIRIES_FAILED,
  });
}

function* postRestoreWebAppointments(action) {
  yield processAction({
    ...action,
    service: postRestoreWebAppointmentsService,
    success: contentActionTypes.POST_RESTORE_WEB_APPOINTMENT_INQUIRIES_SUCCESS,
    failed: contentActionTypes.POST_RESTORE_WEB_APPOINTMENT_INQUIRIES_FAILED,
  });
}

function* postExportWebPropertyInquiries(action) {
  yield processAction({
    ...action,
    service: postExportWebPropertyInquiriesService,
    success: contentActionTypes.POST_EXPORT_WEB_PROPERTY_INQUIRIES_SUCCESS,
    failed: contentActionTypes.POST_EXPORT_WEB_PROPERTY_INQUIRIES_FAILED,
  });
}

function* postRestoreWebPropertyInquiries(action) {
  yield processAction({
    ...action,
    service: postRestoreWebPropertyInquiriesService,
    success: contentActionTypes.POST_RESTORE_WEB_PROPERTY_INQUIRIES_SUCCESS,
    failed: contentActionTypes.POST_RESTORE_WEB_PROPERTY_INQUIRIES_FAILED,
  });
}

function* getSoftDeletedWebAppointments(action) {
  yield processAction({
    ...action,
    service: getSoftDeletedWebAppointmentsService,
    success:
      contentActionTypes.GET_SOFT_DELETED_WEB_APPOINTMENT_INQUIRIES_SUCCESS,
    failed:
      contentActionTypes.GET_SOFT_DELETED_WEB_APPOINTMENT_INQUIRIES_FAILED,
  });
}

function* getSoftDeletedWebPropertyInquiries(action) {
  yield processAction({
    ...action,
    service: getSoftDeletedWebPropertyInquiriesService,
    success: contentActionTypes.GET_SOFT_DELETED_WEB_PROPERTY_INQUIRIES_SUCCESS,
    failed: contentActionTypes.GET_SOFT_DELETED_WEB_PROPERTY_INQUIRIES_FAILED,
  });
}

function* getSoftDeletedWebCareerApplication(action) {
  yield processAction({
    ...action,
    service: getSoftDeletedWebCareerApplicationService,
    success: contentActionTypes.GET_SOFT_DELETED_WEB_CAREER_APPLICATION_SUCCESS,
    failed: contentActionTypes.GET_SOFT_DELETED_WEB_CAREER_APPLICATION_FAILED,
  });
}

function* getWebContentData(action) {
  yield processAction({
    ...action,
    service: getWebContentService,
    success: contentActionTypes.CONTENT_WEB_DATA_SUCCESS,
    failed: contentActionTypes.CONTENT_WEB_DATA_FAILED,
  });
}

function* getWebPropertyInquiries(action) {
  yield processAction({
    ...action,
    service: getWebPropertyInquiriesService,
    success: contentActionTypes.GET_WEB_PROPERTY_INQUIRIES_SUCCESS,
    failed: contentActionTypes.GET_WEB_PROPERTY_INQUIRIES_FAILED,
  });
}

function* getWebAppointmentInquiries(action) {
  yield processAction({
    ...action,
    service: getWebAppointmentInquiriesService,
    success: contentActionTypes.GET_WEB_APPOINTMENT_INQUIRIES_SUCCESS,
    failed: contentActionTypes.GET_WEB_APPOINTMENT_INQUIRIES_FAILED,
  });
}

function* postWebContent(action) {
  yield processAction({
    ...action,
    service: postWebContentService,
    success: contentActionTypes.POST_WEB_DATA_SUCCESS,
    failed: contentActionTypes.POST_WEB_DATA_FAILED,
  });
}

// FAQ CATEGORY
function* getWebFaqCategory(action) {
  yield processAction({
    ...action,
    service: getWebFaqCategoryService,
    success: contentActionTypes.GET_WEB_FAQ_CATEGORY_SUCCESS,
    failed: contentActionTypes.GET_WEB_FAQ_CATEGORY_FAILED,
  });
}

function* postWebFaqCategory(action) {
  yield processAction({
    ...action,
    service: postWebFaqCategoryService,
    success: contentActionTypes.POST_WEB_FAQ_CATEGORY_SUCCESS,
    failed: contentActionTypes.POST_WEB_FAQ_CATEGORY_FAILED,
  });
}

function* putWebFaqCategory(action) {
  yield processAction({
    ...action,
    service: putWebFaqCategoryService,
    success: contentActionTypes.PUT_WEB_FAQ_CATEGORY_SUCCESS,
    failed: contentActionTypes.PUT_WEB_FAQ_CATEGORY_FAILED,
  });
}

function* deleteWebFaqCategory(action) {
  yield processAction({
    ...action,
    service: deleteWebFaqCategoryService,
    success: contentActionTypes.DELETE_WEB_FAQ_CATEGORY_SUCCESS,
    failed: contentActionTypes.DELETE_WEB_FAQ_CATEGORY_FAILED,
  });
}

// FAQ
function* getWebFaq(action) {
  yield processAction({
    ...action,
    service: getWebFaqService,
    success: contentActionTypes.GET_WEB_FAQ_SUCCESS,
    failed: contentActionTypes.GET_WEB_FAQ_FAILED,
  });
}

function* postWebFaq(action) {
  yield processAction({
    ...action,
    service: postWebFaqService,
    success: contentActionTypes.POST_WEB_FAQ_SUCCESS,
    failed: contentActionTypes.POST_WEB_FAQ_FAILED,
  });
}

function* putWebFaq(action) {
  yield processAction({
    ...action,
    service: putWebFaqService,
    success: contentActionTypes.PUT_WEB_FAQ_SUCCESS,
    failed: contentActionTypes.PUT_WEB_FAQ_FAILED,
  });
}

function* deleteWebFaq(action) {
  yield processAction({
    ...action,
    service: deleteWebFaqService,
    success: contentActionTypes.DELETE_WEB_FAQ_SUCCESS,
    failed: contentActionTypes.DELETE_WEB_FAQ_FAILED,
  });
}

// POPUP PROMO
function* getWebPopupPromo(action) {
  yield processAction({
    ...action,
    service: getWebPopupPromoService,
    success: contentActionTypes.GET_WEB_POPUP_PROMO_SUCCESS,
    failed: contentActionTypes.GET_WEB_POPUP_PROMO_FAILED,
  });
}

function* postWebPopupPromo(action) {
  yield processAction({
    ...action,
    service: postWebPopupPromoService,
    success: contentActionTypes.POST_WEB_POPUP_PROMO_SUCCESS,
    failed: contentActionTypes.POST_WEB_POPUP_PROMO_FAILED,
  });
}

function* putWebPopupPromo(action) {
  yield processAction({
    ...action,
    service: putWebPopupPromoService,
    success: contentActionTypes.PUT_WEB_POPUP_PROMO_SUCCESS,
    failed: contentActionTypes.PUT_WEB_POPUP_PROMO_FAILED,
  });
}

function* deleteWebPopupPromo(action) {
  yield processAction({
    ...action,
    service: deleteWebPopupPromoService,
    success: contentActionTypes.DELETE_WEB_POPUP_PROMO_SUCCESS,
    failed: contentActionTypes.DELETE_WEB_POPUP_PROMO_FAILED,
  });
}

// TESTIMONIALS
function* getWebTestimonials(action) {
  yield processAction({
    ...action,
    service: getWebTestimonialsService,
    success: contentActionTypes.GET_WEB_TESTIMONIALS_SUCCESS,
    failed: contentActionTypes.GET_WEB_TESTIMONIALS_FAILED,
  });
}

function* postWebTestimonials(action) {
  yield processAction({
    ...action,
    service: postWebTestimonialsService,
    success: contentActionTypes.POST_WEB_TESTIMONIALS_SUCCESS,
    failed: contentActionTypes.POST_WEB_TESTIMONIALS_FAILED,
  });
}

function* putWebTestimonials(action) {
  yield processAction({
    ...action,
    service: putWebTestimonialsService,
    success: contentActionTypes.PUT_WEB_TESTIMONIALS_SUCCESS,
    failed: contentActionTypes.PUT_WEB_TESTIMONIALS_FAILED,
  });
}

function* deleteWebTestimonials(action) {
  yield processAction({
    ...action,
    service: deleteWebTestimonialsService,
    success: contentActionTypes.DELETE_WEB_TESTIMONIALS_SUCCESS,
    failed: contentActionTypes.DELETE_WEB_TESTIMONIALS_FAILED,
  });
}

// NEWS
function* getWebNews(action) {
  yield processAction({
    ...action,
    service: getWebNewsService,
    success: contentActionTypes.GET_WEB_NEWS_SUCCESS,
    failed: contentActionTypes.GET_WEB_NEWS_FAILED,
  });
}

function* postWebNews(action) {
  yield processAction({
    ...action,
    service: postWebNewsService,
    success: contentActionTypes.POST_WEB_NEWS_SUCCESS,
    failed: contentActionTypes.POST_WEB_NEWS_FAILED,
  });
}

function* putWebNews(action) {
  yield processAction({
    ...action,
    service: putWebNewsService,
    success: contentActionTypes.PUT_WEB_NEWS_SUCCESS,
    failed: contentActionTypes.PUT_WEB_NEWS_FAILED,
  });
}

function* deleteWebNews(action) {
  yield processAction({
    ...action,
    service: deleteWebNewsService,
    success: contentActionTypes.DELETE_WEB_NEWS_SUCCESS,
    failed: contentActionTypes.DELETE_WEB_NEWS_FAILED,
  });
}

// CAREER
function* getWebCareer(action) {
  yield processAction({
    ...action,
    service: getWebCareerService,
    success: contentActionTypes.GET_WEB_CAREER_SUCCESS,
    failed: contentActionTypes.GET_WEB_CAREER_FAILED,
  });
}

function* postWebCareer(action) {
  yield processAction({
    ...action,
    service: postWebCareerService,
    success: contentActionTypes.POST_WEB_CAREER_SUCCESS,
    failed: contentActionTypes.POST_WEB_CAREER_FAILED,
  });
}

function* putWebCareer(action) {
  yield processAction({
    ...action,
    service: putWebCareerService,
    success: contentActionTypes.PUT_WEB_CAREER_SUCCESS,
    failed: contentActionTypes.PUT_WEB_CAREER_FAILED,
  });
}

function* deleteWebCareer(action) {
  yield processAction({
    ...action,
    service: deleteWebCareerService,
    success: contentActionTypes.DELETE_WEB_CAREER_SUCCESS,
    failed: contentActionTypes.DELETE_WEB_CAREER_FAILED,
  });
}

// CAREER APPLICATION
function* getWebCareerApplication(action) {
  yield processAction({
    ...action,
    service: getWebCareerApplicationService,
    success: contentActionTypes.GET_WEB_CAREER_APPLICATION_SUCCESS,
    failed: contentActionTypes.GET_WEB_CAREER_APPLICATION_FAILED,
  });
}

function* postWebCareerApplication(action) {
  yield processAction({
    ...action,
    service: postWebCareerApplicationService,
    success: contentActionTypes.POST_WEB_CAREER_APPLICATION_SUCCESS,
    failed: contentActionTypes.POST_WEB_CAREER_APPLICATION_FAILED,
  });
}

function* putWebCareerApplication(action) {
  yield processAction({
    ...action,
    service: putWebCareerApplicationService,
    success: contentActionTypes.PUT_WEB_CAREER_APPLICATION_SUCCESS,
    failed: contentActionTypes.PUT_WEB_CAREER_APPLICATION_FAILED,
  });
}

function* deleteWebCareerApplication(action) {
  yield processAction({
    ...action,
    service: deleteWebCareerApplicationService,
    success: contentActionTypes.DELETE_WEB_CAREER_APPLICATION_SUCCESS,
    failed: contentActionTypes.DELETE_WEB_CAREER_APPLICATION_FAILED,
  });
}

function* deletePropertyInquiry(action) {
  yield processAction({
    ...action,
    service: deletePropertyInquiryService,
    success: contentActionTypes.DELETE_PROPERTY_INQUIRY_SUCCESS,
    failed: contentActionTypes.DELETE_PROPERTY_INQUIRY_FAILED,
  });
}

function* deleteAppointmentInquiry(action) {
  yield processAction({
    ...action,
    service: deleteAppointmentInquiryService,
    success: contentActionTypes.DELETE_APPOINTMENT_INQUIRY_SUCCESS,
    failed: contentActionTypes.DELETE_APPOINTMENT_INQUIRY_FAILED,
  });
}

function* postWebRecipients(action) {
  yield processAction({
    ...action,
    service: postWebRecipientService,
    success: contentActionTypes.POST_WEB_RECIPIENTS_SUCCESS,
    failed: contentActionTypes.POST_WEB_RECIPIENTS_FAILED,
  });
}

function* getWebRecipients(action) {
  yield processAction({
    ...action,
    service: getWebRecipientService,
    success: contentActionTypes.GET_WEB_RECIPIENTS_SUCCESS,
    failed: contentActionTypes.GET_WEB_RECIPIENTS_FAILED,
  });
}

function* getWebCareersRecipients(action) {
  yield processAction({
    ...action,
    service: getWebCareersRecipientsService,
    success: contentActionTypes.GET_WEB_CAREERS_RECIPIENTS_SUCCESS,
    failed: contentActionTypes.GET_WEB_CAREERS_RECIPIENTS_FAILED,
  });
}
//web locations
function* getWebLocations(action) {
  yield processAction({
    ...action,
    service: getWebLocationsService,
    success: contentActionTypes.GET_WEB_LOCATIONS_SUCCESS,
    failed: contentActionTypes.GET_WEB_LOCATIONS_FAILED,
  });
}
function* postWebLocations(action) {
  yield processAction({
    ...action,
    service: postWebLocationsService,
    success: contentActionTypes.POST_WEB_LOCATIONS_SUCCESS,
    failed: contentActionTypes.POST_WEB_LOCATIONS_FAILED,
  });
}
function* deleteWebLocations(action) {
  yield processAction({
    ...action,
    service: deleteWebLocationsService,
    success: contentActionTypes.DELETE_WEB_LOCATIONS_SUCCESS,
    failed: contentActionTypes.DELETE_WEB_LOCATIONS_FAILED,
  });
}
function* editWebLocations(action) {
  yield processAction({
    ...action,
    service: editWebLocationsService,
    success: contentActionTypes.EDIT_WEB_LOCATIONS_SUCCESS,
    failed: contentActionTypes.EDIT_WEB_LOCATIONS_FAILED,
  });
}
//web awards
function* getWebAwards(action) {
  yield processAction({
    ...action,
    service: getWebAwardsService,
    success: contentActionTypes.GET_WEB_AWARDS_SUCCESS,
    failed: contentActionTypes.GET_WEB_AWARDS_FAILED,
  });
}
function* postWebAwards(action) {
  yield processAction({
    ...action,
    service: postWebAwardsService,
    success: contentActionTypes.POST_WEB_AWARDS_SUCCESS,
    failed: contentActionTypes.POST_WEB_AWARDS_FAILED,
  });
}
function* deleteWebAwards(action) {
  yield processAction({
    ...action,
    service: deleteWebAwardsService,
    success: contentActionTypes.DELETE_WEB_AWARDS_SUCCESS,
    failed: contentActionTypes.DELETE_WEB_AWARDS_FAILED,
  });
}
function* editWebAwards(action) {
  yield processAction({
    ...action,
    service: editWebAwardsService,
    success: contentActionTypes.EDIT_WEB_AWARDS_SUCCESS,
    failed: contentActionTypes.EDIT_WEB_AWARDS_FAILED,
  });
}
//web awards years
function* getWebAwardsYears(action) {
  yield processAction({
    ...action,
    service: getWebAwardsYearsService,
    success: contentActionTypes.GET_WEB_AWARDS_YEARS_SUCCESS,
    failed: contentActionTypes.GET_WEB_AWARDS_YEARS_FAILED,
  });
}
function* postWebAwardsYears(action) {
  yield processAction({
    ...action,
    service: postWebAwardsYearsService,
    success: contentActionTypes.POST_WEB_AWARDS_YEARS_SUCCESS,
    failed: contentActionTypes.POST_WEB_AWARDS_YEARS_FAILED,
  });
}
function* deleteWebAwardsYears(action) {
  yield processAction({
    ...action,
    service: deleteWebAwardsYearsService,
    success: contentActionTypes.DELETE_WEB_AWARDS_YEARS_SUCCESS,
    failed: contentActionTypes.DELETE_WEB_AWARDS_YEARS_FAILED,
  });
}
function* editWebAwardsYears(action) {
  yield processAction({
    ...action,
    service: editWebAwardsYearsService,
    success: contentActionTypes.EDIT_WEB_AWARDS_YEARS_SUCCESS,
    failed: contentActionTypes.EDIT_WEB_AWARDS_YEARS_FAILED,
  });
}

function* watchContentData() {
  yield all([
    //web awards years
    yield takeEvery(
      contentActionTypes.EDIT_WEB_AWARDS_YEARS,
      editWebAwardsYears
    ),
    yield takeEvery(
      contentActionTypes.DELETE_WEB_AWARDS_YEARS,
      deleteWebAwardsYears
    ),
    yield takeEvery(
      contentActionTypes.POST_WEB_AWARDS_YEARS,
      postWebAwardsYears
    ),
    yield takeEvery(contentActionTypes.GET_WEB_AWARDS_YEARS, getWebAwardsYears),
    //web awards
    yield takeEvery(contentActionTypes.EDIT_WEB_AWARDS, editWebAwards),
    yield takeEvery(contentActionTypes.DELETE_WEB_AWARDS, deleteWebAwards),
    yield takeEvery(contentActionTypes.POST_WEB_AWARDS, postWebAwards),
    yield takeEvery(contentActionTypes.GET_WEB_AWARDS, getWebAwards),
    //web locations
    yield takeEvery(contentActionTypes.EDIT_WEB_LOCATIONS, editWebLocations),
    yield takeEvery(
      contentActionTypes.DELETE_WEB_LOCATIONS,
      deleteWebLocations
    ),
    yield takeEvery(contentActionTypes.POST_WEB_LOCATIONS, postWebLocations),
    yield takeEvery(contentActionTypes.GET_WEB_LOCATIONS, getWebLocations),
    yield takeEvery(contentActionTypes.GET_ALL_PROPERTIES, getProperties),
    yield takeEvery(
      contentActionTypes.GET_WEB_PROPERTY_INQUIRIES_REQUEST,
      getWebPropertyInquiries
    ),
    yield takeEvery(
      contentActionTypes.GET_WEB_APPOINTMENT_INQUIRIES_REQUEST,
      getWebAppointmentInquiries
    ),
    yield takeEvery(
      contentActionTypes.CONTENT_WEB_DATA_REQUEST,
      getWebContentData
    ),
    yield takeEvery(contentActionTypes.POST_WEB_DATA_REQUEST, postWebContent),
    yield takeEvery(
      contentActionTypes.GET_WEB_FAQ_CATEGORY_REQUEST,
      getWebFaqCategory
    ),
    yield takeEvery(
      contentActionTypes.POST_WEB_FAQ_CATEGORY_REQUEST,
      postWebFaqCategory
    ),
    yield takeEvery(
      contentActionTypes.PUT_WEB_FAQ_CATEGORY_REQUEST,
      putWebFaqCategory
    ),
    yield takeEvery(
      contentActionTypes.DELETE_WEB_FAQ_CATEGORY_REQUEST,
      deleteWebFaqCategory
    ),
    yield takeEvery(contentActionTypes.GET_WEB_FAQ_REQUEST, getWebFaq),
    yield takeEvery(contentActionTypes.POST_WEB_FAQ_REQUEST, postWebFaq),
    yield takeEvery(contentActionTypes.PUT_WEB_FAQ_REQUEST, putWebFaq),
    yield takeEvery(contentActionTypes.DELETE_WEB_FAQ_REQUEST, deleteWebFaq),
    yield takeEvery(
      contentActionTypes.GET_WEB_POPUP_PROMO_REQUEST,
      getWebPopupPromo
    ),
    yield takeEvery(
      contentActionTypes.POST_WEB_POPUP_PROMO_REQUEST,
      postWebPopupPromo
    ),
    yield takeEvery(
      contentActionTypes.PUT_WEB_POPUP_PROMO_REQUEST,
      putWebPopupPromo
    ),
    yield takeEvery(
      contentActionTypes.DELETE_WEB_POPUP_PROMO_REQUEST,
      deleteWebPopupPromo
    ),
    yield takeEvery(
      contentActionTypes.DELETE_PROPERTY_INQUIRY_REQUEST,
      deletePropertyInquiry
    ),
    yield takeEvery(
      contentActionTypes.DELETE_APPOINTMENT_INQUIRY_REQUEST,
      deleteAppointmentInquiry
    ),
    yield takeEvery(
      contentActionTypes.GET_WEB_TESTIMONIALS_REQUEST,
      getWebTestimonials
    ),
    yield takeEvery(
      contentActionTypes.POST_WEB_TESTIMONIALS_REQUEST,
      postWebTestimonials
    ),
    yield takeEvery(
      contentActionTypes.PUT_WEB_TESTIMONIALS_REQUEST,
      putWebTestimonials
    ),
    yield takeEvery(
      contentActionTypes.DELETE_WEB_TESTIMONIALS_REQUEST,
      deleteWebTestimonials
    ),
    yield takeEvery(
      contentActionTypes.GET_SOFT_DELETED_WEB_APPOINTMENT_INQUIRIES_REQUEST,
      getSoftDeletedWebAppointments
    ),
    yield takeEvery(
      contentActionTypes.GET_SOFT_DELETED_WEB_CAREER_APPLICATION_REQUEST,
      getSoftDeletedWebCareerApplication
    ),
    yield takeEvery(
      contentActionTypes.GET_SOFT_DELETED_WEB_PROPERTY_INQUIRIES_REQUEST,
      getSoftDeletedWebPropertyInquiries
    ),
    yield takeEvery(
      contentActionTypes.POST_RESTORE_WEB_PROPERTY_INQUIRIES_REQUEST,
      postRestoreWebPropertyInquiries
    ),
    yield takeEvery(
      contentActionTypes.POST_EXPORT_WEB_PROPERTY_INQUIRIES_REQUEST,
      postExportWebPropertyInquiries
    ),
    yield takeEvery(
      contentActionTypes.POST_RESTORE_WEB_APPOINTMENT_INQUIRIES_REQUEST,
      postRestoreWebAppointments
    ),
    yield takeEvery(
      contentActionTypes.POST_EXPORT_WEB_APPOINTMENT_INQUIRIES_REQUEST,
      postExportWebAppointments
    ),
    yield takeEvery(
      contentActionTypes.POST_RESTORE_WEB_CAREER_APPLICATION_REQUEST,
      postRestoreWebCareerApplication
    ),
    yield takeEvery(
      contentActionTypes.POST_EXPORT_WEB_CAREER_APPLICATION_REQUEST,
      postExportWebCareerApplication
    ),
    yield takeEvery(contentActionTypes.GET_WEB_NEWS_REQUEST, getWebNews),
    yield takeEvery(contentActionTypes.POST_WEB_NEWS_REQUEST, postWebNews),
    yield takeEvery(contentActionTypes.PUT_WEB_NEWS_REQUEST, putWebNews),
    yield takeEvery(contentActionTypes.DELETE_WEB_NEWS_REQUEST, deleteWebNews),
    yield takeEvery(contentActionTypes.GET_WEB_CAREER_REQUEST, getWebCareer),
    yield takeEvery(contentActionTypes.POST_WEB_CAREER_REQUEST, postWebCareer),
    yield takeEvery(contentActionTypes.PUT_WEB_CAREER_REQUEST, putWebCareer),
    yield takeEvery(
      contentActionTypes.DELETE_WEB_CAREER_REQUEST,
      deleteWebCareer
    ),
    yield takeEvery(
      contentActionTypes.GET_WEB_CAREER_APPLICATION_REQUEST,
      getWebCareerApplication
    ),
    yield takeEvery(
      contentActionTypes.POST_WEB_CAREER_APPLICATION_REQUEST,
      postWebCareerApplication
    ),
    yield takeEvery(
      contentActionTypes.PUT_WEB_CAREER_APPLICATION_REQUEST,
      putWebCareerApplication
    ),
    yield takeEvery(
      contentActionTypes.DELETE_WEB_CAREER_APPLICATION_REQUEST,
      deleteWebCareerApplication
    ),
    yield takeEvery(
      contentActionTypes.POST_WEB_RECIPIENTS_REQUEST,
      postWebRecipients
    ),
    yield takeEvery(
      contentActionTypes.GET_WEB_RECIPIENTS_REQUEST,
      getWebRecipients
    ),
    yield takeEvery(
      contentActionTypes.GET_WEB_CAREERS_RECIPIENTS_REQUEST,
      getWebCareersRecipients
    ),
    yield takeEvery(
      contentActionTypes.GET_WEB_INQUIRY_RECIPIENTS_REQUEST,
      getWebInquiryRecipients
    ),
  ]);
}

export default [...newsletterSubscriptionsSaga, watchContentData()];
