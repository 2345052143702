import { takeEvery, all } from "redux-saga/effects";
import { actionTypes as contentActionTypes } from "./reducer";
import {
  getContent as getContentService,
  postContent as postContentService,
  getAgreement as getAgreementService,
  postAgreement as postAgreementService,
  getAuditTrail as getAuditTrailService,
  postAuditTrail as postAuditTrailService,
  getRemittanceList as getRemittanceListService,
  getPayNowTransactionList as getPayNowTransactionService,
  getFaqCategory as getFaqCategoryService,
  getFaq as getFaqService,
  postFaq as postFaqService,
  putFaq as putFaqService,
  getInquiryAudit as getInquiryAuditService,
  deleteFaq as deleteFaqService,
  // getProperties as getPropertiesService,
  postProjectNews as postProjectNewsService,
  getProjectNews as getProjectNewsService,
  deleteProjectNews as deleteProjectNewsService,
  getEmailContent as getEmailContentService,
  postEmailContent as postEmailContentService,
  searchInquiryList as searchInquiryListService,
  searchDependentInquiryList as searchDependentInquiryListService,
  searchInquiryListByBrand as searchInquiryListByBrandService,
  searchInquiryListByDate as searchInquiryListByDateService,
  searchRemittnceSlip as searchRemittnceSlipService,
  searchRemittnceByDate as searchRemittnceByDateService,
  searchRemittnceByBrand as searchRemittnceByBrandService,
  searchDependentRemittance as searchDependentRemittanceService,
  searchProjectNewsLetterByProperty as searchProjectNewsLetterByPropertyService,
  searchProjectNewsLetterByBrand as searchProjectNewsLetterByBrandService,
  searchDependentProjectNewsLetter as searchDependentProjectNewsLetterService,
  searchFaq as searchFaqService,
  searchPaynowContractNo as searchPaynowContractNoService,
  searchPaynowByBrand as searchPaynowByBrandService,
  searchDependentPaynow as searchDependentPaynowService,
} from "./service";
import processAction from "../../utils/processAction";

function* getContent(action) {
  yield processAction({
    ...action,
    service: getContentService,
    success: contentActionTypes.CONTENT_DATA_REQUEST_SUCCESS,
    failed: contentActionTypes.CONTENT_DATA_REQUEST_FAILED,
  });
}

function* getAllEmailContent(action) {
  yield processAction({
    ...action,
    service: getEmailContentService,
    success: contentActionTypes.GET_BUYER_EMAIL_CONTENT_SUCCESS,
    failed: contentActionTypes.GET_BUYER_EMAIL_CONTENT_FAILED,
  });
}

function* postEmailContent(action) {
  yield processAction({
    ...action,
    service: postEmailContentService,
    success: contentActionTypes.POST_BUYER_EMAIL_CONTENT_SUCCESS,
    failed: contentActionTypes.POST_BUYER_EMAIL_CONTENT_FAILED,
  });
}

function* postContent(action) {
  yield processAction({
    ...action,
    service: postContentService,
    success: contentActionTypes.POST_DATA_REQUEST_SUCCESS,
    failed: contentActionTypes.POST_DATA_REQUEST_FAILED,
  });
}

function* getAgreement(action) {
  yield processAction({
    ...action,
    service: getAgreementService,
    success: contentActionTypes.AGREEMENT_DATA_REQUEST_SUCCESS,
    failed: contentActionTypes.AGREEMENT_DATA_REQUEST_FAILED,
  });
}

function* postAgreement(action) {
  yield processAction({
    ...action,
    service: postAgreementService,
    success: contentActionTypes.POST_AGREEMENT_REQUEST_SUCCESS,
    failed: contentActionTypes.POST_AGREEMENT_REQUEST_FAILED,
  });
}

function* getAuditTrail(action) {
  yield processAction({
    ...action,
    service: getAuditTrailService,
    success: contentActionTypes.GET_AUDIT_TRAIL_REQUEST_SUCCESS,
    failed: contentActionTypes.GET_AUDIT_TRAIL_REQUEST_FAILED,
  });
}

function* postAuditTrail(action) {
  yield processAction({
    ...action,
    service: postAuditTrailService,
    success: contentActionTypes.POST_AUDIT_TRAIL_REQUEST_SUCCESS,
    failed: contentActionTypes.POST_AUDIT_TRAIL_REQUEST_FAILED,
  });
}

function* getRemittanceList(action) {
  yield processAction({
    ...action,
    service: getRemittanceListService,
    success: contentActionTypes.GET_REMITTANCE_REQUEST_SUCCESS,
    failed: contentActionTypes.GET_REMITTANCE_REQUEST_FAILED,
  });
}

function* getPayNowTransaction(action) {
  yield processAction({
    ...action,
    service: getPayNowTransactionService,
    success: contentActionTypes.GET_PAY_NOW_TRANSACTION_LIST_SUCCESS,
    failed: contentActionTypes.GET_PAY_NOW_TRANSACTION_LIST_FAILED,
  });
}

function* getFaqCategory(action) {
  yield processAction({
    ...action,
    service: getFaqCategoryService,
    success: contentActionTypes.GET_FAQ_CATEGORY_SUCCESS,
    failed: contentActionTypes.GET_FAQ_CATEGORY_FAILED,
  });
}

function* getFaq(action) {
  yield processAction({
    ...action,
    service: getFaqService,
    success: contentActionTypes.GET_FAQ_SUCCESS,
    failed: contentActionTypes.GET_FAQ_FAILED,
  });
}

function* postFaq(action) {
  yield processAction({
    ...action,
    service: postFaqService,
    success: contentActionTypes.POST_FAQ_SUCCESS,
    failed: contentActionTypes.POST_FAQ_FAILED,
  });
}

function* putFaq(action) {
  yield processAction({
    ...action,
    service: putFaqService,
    success: contentActionTypes.PUT_FAQ_SUCCESS,
    failed: contentActionTypes.PUT_FAQ_FAILED,
  });
}

function* getInquiryAudit(action) {
  yield processAction({
    ...action,
    service: getInquiryAuditService,
    success: contentActionTypes.GET_REQUEST_AND_INQUIRY_SUCCESS,
    failed: contentActionTypes.GET_REQUEST_AND_INQUIRY_FAILED,
  });
}

function* removeFaq(action) {
  yield processAction({
    ...action,
    service: deleteFaqService,
    success: contentActionTypes.DELETE_FAQ_SUCCESS,
    failed: contentActionTypes.DELETE_FAQ_FAILED,
  });
}

// function* getProperties(action) {
//   yield processAction({
//     ...action,
//     service: getPropertiesService,
//     success: contentActionTypes.GET_PROPERTIES_SUCCESS,
//     failed: contentActionTypes.GET_PROPERTIES_FAILED
//   });
// }

function* postProjectNews(action) {
  yield processAction({
    ...action,
    service: postProjectNewsService,
    success: contentActionTypes.POST_PROJECT_UPDATE_SUCCESS,
    failed: contentActionTypes.POST_PROJECT_UPDATE_FAILED,
  });
}

function* getProjectNews(action) {
  yield processAction({
    ...action,
    service: getProjectNewsService,
    success: contentActionTypes.GET_PROJECT_UPDATE_SUCCESS,
    failed: contentActionTypes.GET_PROJECT_UPDATE_FAILED,
  });
}

function* deleteProjectNews(action) {
  yield processAction({
    ...action,
    service: deleteProjectNewsService,
    success: contentActionTypes.DELETE_PROJECT_UPDATE_SUCCESS,
    failed: contentActionTypes.DELETE_PROJECT_UPDATE_FAILED,
  });
}

function* searchInquiryList(action) {
  yield processAction({
    ...action,
    service: searchInquiryListService,
    success: contentActionTypes.GET_SEARCH_INQUIRY_LIST_SUCCESS,
    failed: contentActionTypes.GET_SEARCH_INQUIRY_LIST_FAILED,
  });
}

function* searchDependentInquiryList(action) {
  yield processAction({
    ...action,
    service: searchDependentInquiryListService,
    success: contentActionTypes.GET_SEARCH_DEPENDENT_INQUIRY_LIST_SUCCESS,
    failed: contentActionTypes.GET_SEARCH_DEPENDENT_INQUIRY_LIST_FAILED,
  });
}

function* searchInquiryListByBrand(action) {
  yield processAction({
    ...action,
    service: searchInquiryListByBrandService,
    success: contentActionTypes.GET_SEARCH_INQUIRY_LIST_BY_BRAND_SUCCESS,
    failed: contentActionTypes.GET_SEARCH_INQUIRY_LIST_BY_BRAND_FAILED,
  });
}

function* searchInquiryListByDate(action) {
  yield processAction({
    ...action,
    service: searchInquiryListByDateService,
    success: contentActionTypes.GET_SEARCH_INQUIRY_LIST_BY_DATE_SUCCESS,
    failed: contentActionTypes.GET_SEARCH_INQUIRY_LIST_BY_DATE_FAILED,
  });
}

function* searchRemittnceSlip(action) {
  yield processAction({
    ...action,
    service: searchRemittnceSlipService,
    success: contentActionTypes.GET_SEARCH_REMITTANCE_SLIP_SUCCESS,
    failed: contentActionTypes.GET_SEARCH_REMITTANCE_SLIP_FAILED,
  });
}

function* searchRemittnceByDate(action) {
  yield processAction({
    ...action,
    service: searchRemittnceByDateService,
    success: contentActionTypes.GET_SEARCH_REMITTANCE_BY_DATE_SUCCESS,
    failed: contentActionTypes.GET_SEARCH_REMITTANCE_BY_DATE_FAILED,
  });
}

function* searchRemittnceByBrand(action) {
  yield processAction({
    ...action,
    service: searchRemittnceByBrandService,
    success: contentActionTypes.GET_SEARCH_REMITTANCE_BY_BRAND_SUCCESS,
    failed: contentActionTypes.GET_SEARCH_REMITTANCE_BY_BRAND_FAILED,
  });
}

function* searchDependentRemittance(action) {
  yield processAction({
    ...action,
    service: searchDependentRemittanceService,
    success: contentActionTypes.GET_SEARCH_DEPENDENT_REMITTANCE_SUCCESS,
    failed: contentActionTypes.GET_SEARCH_DEPENDENT_REMITTANCE_FAILED,
  });
}

function* searchProjectNewsLetterByProperty(action) {
  yield processAction({
    ...action,
    service: searchProjectNewsLetterByPropertyService,
    success:
      contentActionTypes.GET_SEARCH_PROJECT_NEWS_LETTER_BY_PROPERTY_SUCCESS,
    failed:
      contentActionTypes.GET_SEARCH_PROJECT_NEWS_LETTER_BY_PROPERTY_FAILED,
  });
}

function* searchProjectNewsLetterByBrand(action) {
  yield processAction({
    ...action,
    service: searchProjectNewsLetterByBrandService,
    success: contentActionTypes.GET_SEARCH_PROJECT_NEWS_LETTER_BY_BRAND_SUCCESS,
    failed: contentActionTypes.GET_SEARCH_PROJECT_NEWS_LETTER_BY_BRAND_FAILED,
  });
}

function* searchDependentProjectNewsLetter(action) {
  yield processAction({
    ...action,
    service: searchDependentProjectNewsLetterService,
    success:
      contentActionTypes.GET_SEARCH_DEPENDENT_PROJECT_NEWS_LETTER_SUCCESS,
    failed: contentActionTypes.GET_SEARCH_DEPENDENT_PROJECT_NEWS_LETTER_FAILED,
  });
}

function* searchFaq(action) {
  yield processAction({
    ...action,
    service: searchFaqService,
    success: contentActionTypes.GET_SEARCH_FAQ_SUCCESS,
    failed: contentActionTypes.GET_SEARCH_FAQ_FAILED,
  });
}

function* searchPaynowContractNo(action) {
  yield processAction({
    ...action,
    service: searchPaynowContractNoService,
    success: contentActionTypes.GET_SEARCH_PAYNOW_CONTRACT_NO_SUCCESS,
    failed: contentActionTypes.GET_SEARCH_PAYNOW_CONTRACT_NO_FAILED,
  });
}

function* searchPaynowByBrand(action) {
  yield processAction({
    ...action,
    service: searchPaynowByBrandService,
    success: contentActionTypes.GET_SEARCH_PAYNOW_BY_BRAND_SUCCESS,
    failed: contentActionTypes.GET_SEARCH_PAYNOW_BY_BRAND_FAILED,
  });
}

function* searchDependentPaynow(action) {
  yield processAction({
    ...action,
    service: searchDependentPaynowService,
    success: contentActionTypes.GET_SEARCH_DEPENDENT_PAYNOW_SUCCESS,
    failed: contentActionTypes.GET_SEARCH_DEPENDENT_PAYNOW_FAILED,
  });
}

function* watchContentData() {
  yield all([
    yield takeEvery(contentActionTypes.CONTENT_DATA_REQUEST, getContent),
    yield takeEvery(contentActionTypes.POST_DATA_REQUEST, postContent),
    yield takeEvery(contentActionTypes.AGREEMENT_DATA_REQUEST, getAgreement),
    yield takeEvery(contentActionTypes.POST_AGREEMENT_REQUEST, postAgreement),
    yield takeEvery(contentActionTypes.GET_AUDIT_TRAIL_REQUEST, getAuditTrail),
    yield takeEvery(
      contentActionTypes.POST_AUDIT_TRAIL_REQUEST,
      postAuditTrail
    ),
    yield takeEvery(
      contentActionTypes.GET_REMITTANCE_REQUEST,
      getRemittanceList
    ),
    yield takeEvery(
      contentActionTypes.GET_PAY_NOW_TRANSACTION_LIST,
      getPayNowTransaction
    ),
    yield takeEvery(
      contentActionTypes.GET_FAQ_CATEGORY_REQUEST,
      getFaqCategory
    ),
    yield takeEvery(contentActionTypes.GET_FAQ_REQUEST, getFaq),
    yield takeEvery(contentActionTypes.POST_FAQ_REQUEST, postFaq),
    yield takeEvery(contentActionTypes.PUT_FAQ_REQUEST, putFaq),
    yield takeEvery(
      contentActionTypes.GET_REQUEST_AND_INQUIRY_REQUEST,
      getInquiryAudit
    ),
    yield takeEvery(contentActionTypes.DELETE_FAQ_REQUEST, removeFaq),
    // yield takeEvery(contentActionTypes.GET_PROPERTIES, getProperties),
    yield takeEvery(contentActionTypes.POST_PROJECT_UPDATE, postProjectNews),
    yield takeEvery(contentActionTypes.GET_PROJECT_UPDATE, getProjectNews),
    yield takeEvery(
      contentActionTypes.DELETE_PROJECT_UPDATE,
      deleteProjectNews
    ),
    yield takeEvery(
      contentActionTypes.GET_BUYER_EMAIL_CONTENT,
      getAllEmailContent
    ),
    yield takeEvery(
      contentActionTypes.POST_BUYER_EMAIL_CONTENT,
      postEmailContent
    ),
    yield takeEvery(
      contentActionTypes.GET_SEARCH_INQUIRY_LIST,
      searchInquiryList
    ),
    yield takeEvery(
      contentActionTypes.GET_SEARCH_DEPENDENT_INQUIRY_LIST,
      searchDependentInquiryList
    ),
    yield takeEvery(
      contentActionTypes.GET_SEARCH_INQUIRY_LIST_BY_BRAND,
      searchInquiryListByBrand
    ),
    yield takeEvery(
      contentActionTypes.GET_SEARCH_INQUIRY_LIST_BY_DATE,
      searchInquiryListByDate
    ),
    yield takeEvery(
      contentActionTypes.GET_SEARCH_REMITTANCE_SLIP,
      searchRemittnceSlip
    ),
    yield takeEvery(
      contentActionTypes.GET_SEARCH_REMITTANCE_BY_DATE,
      searchRemittnceByDate
    ),
    yield takeEvery(
      contentActionTypes.GET_SEARCH_REMITTANCE_BY_BRAND,
      searchRemittnceByBrand
    ),
    yield takeEvery(
      contentActionTypes.GET_SEARCH_DEPENDENT_REMITTANCE,
      searchDependentRemittance
    ),
    yield takeEvery(
      contentActionTypes.GET_SEARCH_PROJECT_NEWS_LETTER_BY_PROPERTY,
      searchProjectNewsLetterByProperty
    ),
    yield takeEvery(
      contentActionTypes.GET_SEARCH_PROJECT_NEWS_LETTER_BY_BRAND,
      searchProjectNewsLetterByBrand
    ),
    yield takeEvery(
      contentActionTypes.GET_SEARCH_DEPENDENT_PROJECT_NEWS_LETTER,
      searchDependentProjectNewsLetter
    ),
    yield takeEvery(contentActionTypes.GET_SEARCH_FAQ, searchFaq),
    yield takeEvery(
      contentActionTypes.GET_SEARCH_PAYNOW_CONTRACT_NO,
      searchPaynowContractNo
    ),
    yield takeEvery(
      contentActionTypes.GET_SEARCH_PAYNOW_BY_BRAND,
      searchPaynowByBrand
    ),
    yield takeEvery(
      contentActionTypes.GET_SEARCH_DEPENDENT_PAYNOW,
      searchDependentPaynow
    ),
  ]);
}

export default [watchContentData()];
