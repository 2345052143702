import request from "../../utils/api";

export const getUserRole = payload => {
  return request(`v1/cms/user-role`, {
    payload
  });
};

export const getRoleAccess = payload => {
  return request(`v1/cms/role-access`, {
    payload
  });
};

export const postUserRole = payload => {
  return request(`v1/cms/user-role`, {
    method: "post",
    body: payload
  });
};

export const putUserRole = payload => {
  return request(`v1/cms/user-role/` + payload.id, {
    method: "put",
    body: payload
  });
};

export const getUser = payload => {
  return request(`v1/cms/admin-user`, {
    payload
  });
};

export const postUser = payload => {
  return request(`v1/cms/admin-user`, {
    method: "post",
    body: payload
  });
};

export const putUser = payload => {
  return request(`v1/cms/admin-user/` + payload.id, {
    method: "put",
    body: payload
  });
};

export const getAdminAuditTrail = payload => {
  return request(`v1/cms/user-trail`, {
    payload
  });
};

export const postAdminAuditTrail = payload => {
  return request(`v1/cms/user-trail`, {
    method: "post",
    body: payload
  });
};

export const getDeactivatedUser = payload => {
  return request(`v1/cms/user-trash`, {
    payload
  });
};

export const activateUser = payload => {
  return request(`v1/cms/user-restore`, {
    method: "post",
    body: payload
  });
};

export const getEmailSettings = payload => {
  return request(`v1/cms/email-settings`, {
    payload
  });
};

export const postEmailSettings = payload => {
  return request(`v1/cms/email-settings`, {
    method: "post",
    body: payload
  });
};

export const putEmailSettings = payload => {
  return request(`v1/cms/email-settings/` + payload.id, {
    method: "put",
    body: payload
  });
};

export const executeUserSeeding = payload => {
  return request(`v1/cms/command/seed/user`, {
    payload
  });
};

export const executePaymentSeeding = payload => {
  return request(`v1/cms/command/seed/payment`, {
    payload
  });
};

export const executeReceiptSeeding = payload => {
  return request(`v1/cms/command/seed/receipt`, {
    payload
  });
};

export const executePropertySeeding = payload => {
  return request(`v1/cms/command/seed/property`, {
    payload
  });
};

export const executeProductivitySeeding = payload => {
  return request(`v1/cms/command/seed/productivity`, {
    payload
  });
};

export const executeSalesGroupSeeding = payload => {
  return request(`v1/cms/command/seed/sales_group`, {
    payload
  });
};

export const executeFileExtraction = payload => {
  return request(`v1/cms/command/seed/files`, {
    payload
  });
};
