import auth from "../modules/auth/routes";
import dashboard from "../modules/dashboard/routes";
import user from "../modules/user/routes";
import seller from "../modules/seller/routes";
import buyer from "../modules/buyer/routes";
import admin from "../modules/admin/routes";
import website from "../modules/website/routes";
import projectInfo from "../modules/project-info/routes";

export default [
  ...auth,
  ...dashboard,
  ...user,
  ...seller,
  ...buyer,
  ...admin,
  ...website,
  ...projectInfo
];
